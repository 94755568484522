import React ,{useState, useEffect} from 'react'


import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CheckoutShoppingBagItems from '../../components/checkoutComponents/CheckoutShoppingBagItems';
import ShoppingBagSum from '../../components/shoppingBagComponents/ShoppingBagSum';
import useLocalStorage from '../../components/Products/useLocalStorage';

import { Link,useLocation, BrowserRouter as Router } from 'react-router-dom'

import "../../components/shippingComponents/shipping.css"
import {IoCloseOutline} from 'react-icons/io5'

import { useNavigate } from 'react-router-dom';
import {API,Amplify} from 'aws-amplify';
import Navbar from '../../components/Navbar';
import { HeaderHome } from '../../components/headerHome/HeaderHome';
import carpi from "./carpi.png"





export default function Shipping() {

  const location = useLocation();
  const [isUpdatingStock, setIsUpdatingStock] = useState(false);

  const [isMaxStock, setIsMaxStock] = useState(false);
  

  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);
  const [userEmail, setUserEmail] = useState(location.state.userEmail);
  const [userAddress, setUserAddress] = useState(location.state.userAddress);
  const [userFirstName, setUserFirstName] = useState(location.state.userFirstName);
  const [userLastName, setUserLastName] = useState(location.state.userLastName);
  const [userPhoneNumber, setUserPhoneNumber] = useState(location.state.userPhoneNumber);
  const [userCountry, setUserCountry] = useState(location.state.userCountry);
  const [userPostalCode, setUserPostalCode] = useState(location.state.userPostalCode);
  const [userCity, setUserCity] = useState(location.state.userCity);
  const [userApartmentSuite, setUserApartmentSuite] = useState(location.state.userApartmentSuite);

  const [emailInputEnabled, setEmailInputEnabled] = useState(false);
  const [addressInputEnabled, setAddressInputEnabled] = useState(false);

  const [emailInputBorderActive, setEmailInputBorderActive] = useState(false);
  const [addressInputBorderActive, setAddressInputBorderActive] = useState(false);

   const [token, setToken] = useLocalStorage("token", []);
   const [shippingPriceTRY, setShippingPriceTRY] = useLocalStorage("shippingPriceTry", []);
   const [shippingPriceEUR, setShippingPriceEUR] = useState("");
   const [isShippingMethodFree, setShippingMethodFree] = useState(false);
   const [shippingMethod, setShippingMethod] = useState("");





   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    scrollToTop();
    let sum =  cartItems.reduce(function(prev, current) {
      return(prev + +current.price);
    }, 0);

    if (sum >= 600) {
      setShippingMethodFree(true);
    } else {

      setShippingMethodFree(false);
    }

    if (cartItems.length === 0) {
      console.log("empty");
      localStorage.removeItem("discountCode");
    }
      
     }, [cartItems,isUpdatingStock,isMaxStock]);




  const navigate = useNavigate();

  const  navigatePayment = () =>{
    navigate("/payment",{state:{
      userEmail: userEmail,
      userAddress: userAddress,
      userFirstName: userFirstName,
      userLastName: userLastName,
      userPhoneNumber: userPhoneNumber,
      userCountry: userCountry,
      userCity: userCity,
      userPostalCode: userPostalCode,
      userApartmentSuite: userApartmentSuite}});
  };

  const  navigateCheckout = () =>{
    navigate("/checkout",{state:{id:"id"}});
  };

  const onRemoveCloseIcon = async (product) => {
    console.log(product.qty);

    if(!isUpdatingStock){
      setIsUpdatingStock(true);

      const exist = cartItems.find((x) => x._id === product._id);
      console.log(exist);
        if (exist) {

          try {
            // const result = await API.put(
            //   "almaAppApi",
            //   `/api/product-stock-update-increase-by-count/${exist._id}/${exist.qty}`,
            //   {}
            // );
            setCartItems(cartItems.filter((x) => x._id !== product._id));

          } catch (error) {
            console.log(error);
          }
        } else {
          // setCartItems(
          //   cartItems.map((x) =>
          //     x._id === product._id ? { ...exist, qty: 0 } : x
          //   )
          // );
        }
      setIsUpdatingStock(false);
    }
  }

  // when + icon clicked, number of products increase by one, stock decrease by one
  const onAdd = async (product) => {
    const exist = cartItems.find((x) => x._id === product._id);
    if (exist && !isUpdatingStock) {

      if (exist.stock == 1) {
        console.log("out of stock")
        setIsMaxStock(true);
      } else {
        setIsMaxStock(false);
      }
      setIsUpdatingStock(true);
      console.log("updatinggggg")

      try {
        // const result = await API.put(
        //   "almaAppApi",
        //   `/api/product-stock-update-decrease/${exist._id}`,
        //   {}
        // );
        setCartItems(
          cartItems.map((x) => 
          x._id === product._id ? {
            ...exist,
            qty: exist.qty + 1,
            stock: exist.stock - 1,
            price: exist.price + exist.originalPrice,
          } : x)
        );
      } catch (error) {
        console.log(error);
      }

      setIsUpdatingStock(false);

    } 
  };

    // when - icon clicked, number of products decreased by one, stock increase by one
    const onRemove = async (product) => {

      const exist = cartItems.find((x) => x._id === product._id);
      if(!isUpdatingStock) {
        setIsUpdatingStock(true);
        try {
          if (exist.qty === 1 ) {
            console.log("qty 11111");
            // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${exist._id}`, {});
            setCartItems(cartItems.filter((x) => x._id !== exist._id));

          } else {
            // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${product._id}`, {});
            setCartItems(
              cartItems.map((x) =>
                x._id === product._id ? { ...exist, qty: exist.qty - 1 , stock: exist.stock + 1,  price: exist.price - exist.originalPrice} : x
              )
            );
          }
        } catch (error) {
          
        }

        if (exist.stock > -1) {
          setIsMaxStock(false);
        } else {
          setIsMaxStock(true);
        }
        setIsUpdatingStock(false);
      }

    };

  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <Grid className='shipping-info-container' container xl={11.25} lg={11.25} md={11.25} sm={11.2} xs={11} >
        <Grid item xl={5.9} lg={5.9} md={5.9} sm={6.1} xs={12}>
          <div className='shipping-contact-and-ship-to-container'>
              <Grid container xl={12} lg={12} md={12} xs={12} rowGap={2} marginBottom={3}>
                <Grid item xl={2} lg={2} md= {2} xs={2.9}>CONTACT</Grid>
                <Grid item xl={8} lg={8} md={8} xs={6.9}>
                    <input  className={emailInputBorderActive ? 'shipping-changable-enabled-email-input' : 'shipping-changable-disabled-email-input'} 
                      value={userEmail} 
                      disabled={!emailInputEnabled} 
                      onChange={e => setUserEmail(e.target.value)}>
                    </input>
                </Grid>
                <Grid item xl={1} lg={1} md={1} xs={1}>
                  <button className="change-button" disabled={token !== null && token.length!==0} onClick={() => {
                    setEmailInputEnabled(true);
                    setEmailInputBorderActive(true);
                  }}>CHANGE</button>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid container xl={12} lg={12} marginTop={3}>
                <Grid item xl={2} lg={2} md={2} xs={3}>SHIP TO</Grid>
                <Grid item xl={8} lg={8} md={8} xs={6.8}>
                    <input className={addressInputBorderActive ? 'shipping-changable-enabled-address-input' : 'shipping-changable-disabled-address-input'} 
                      value={userAddress}
                      disabled={!addressInputEnabled}
                      onChange={e => setUserAddress(e.target.value)}>
                    </input>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <button   className="change-button" onClick={() => {
                    setAddressInputEnabled(true);
                    setAddressInputBorderActive(true);
                  }}>CHANGE</button>
                </Grid>
              </Grid>
          </div>
          <div className='spaceLarge'></div>

          <div>SHIPPING METHOD</div>
          <div className='shipping-contact-and-ship-to-container'>
              <Grid container xl={12} lg={12} xs={12} rowGap={2} >
                <Grid item xl={10.5} lg={10.5} md={10.5} sm={10.5} xs={10.5}>STANDARD</Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>{isShippingMethodFree ? "FREE" : shippingPriceTRY + " TRY"}</Grid>
              </Grid>
          </div>

          <Grid container  marginTop={3} marginBottom={10}>
              <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={12}>
                  <button className='shipping-grey-button' onClick={navigateCheckout} >RETURN TO INFORMATION</button>
                  <div className='spaceSmall'></div>
              </Grid>

              <Grid item xl={0.2} lg={0.2} md={.2} ></Grid>
              <Grid item xl={5.9} lg={5.9} md={5.9} sm={5.9} xs={12}>
                  <button className='shipping-black-continue-to-payment-button' onClick={navigatePayment}>CONTINUE TO PAYMENT</button>
              </Grid>
          </Grid>
          
        </Grid>

        <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.3} xs={0}></Grid>
        
        <Grid item container xl={4.6} lg={4.6} md={4.55} sm={4.6} xs={12} >
          <Grid item xl={12} lg={12} xs={12} md={12} sm={12}>
            {/* <CheckoutShoppingBagItems cartItems={cartItems} setCartItems={setCartItems}></CheckoutShoppingBagItems> */}
            <div className='shipping-shopping-bag-container'>
                {cartItems.map((item) => (
                  <div>
                    <Grid key={item._id} container xl={12} lg={12} xs={12} >
                      <Grid item xl={3} lg={3} md={3} sm={2.5} xs={2.5} display="flex" justifyContent="center" alignItems="flex-start">
                        <img className="shipping-basket-image" src={cartItems[cartItems.indexOf(item)].images[0][0]}></img>
                      </Grid>

                      <Grid item xl={7.5} lg={7.5} md={7.5} sm={7.4} xs={7.9} >
                        <div className='shipping-shopping-bag-item-details'>
                          <div>{item.name}</div>
                          <div>{item.product_type}</div>
                          <div className='spaceSmall'></div>
                          <div>One Size Fits All</div>
                          <div>Color: Black</div>
                          <div className='spaceSmall'></div>

                          <div className='spaceSmall'></div>
                          <button className="shipping-shopping-bag-remove" disabled={isUpdatingStock} onClick={() => onRemove(item)}>-</button>
                          <div className="shipping-shopping-bag-item-qty">{item.qty}</div>
                          <button className="shipping-shopping-bag-add" disabled={isUpdatingStock || isMaxStock || item.stock == 0} onClick={() => onAdd(item)}>+</button>
                        </div>
                      </Grid>

                      <Grid item container  xl={1.5} lg={1.5} md={1.5} sm={2} xs={1.6} direction="column" >

                          <Grid item container xl={1} lg={1} md={1} sm={1} xs={1}  display="flex" justifyContent="flex-end" >
                            <Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
                              <div className='spaceLarge'></div>
                            </Grid>
                            <Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
                              <img className='shipping-carpi' src={carpi} 
                        onClick={() => onRemoveCloseIcon(item)}></img>
                            </Grid>
                            {/* <IoCloseOutline fontSize="23px" style={{color:"grey", height:"100%", width:"100%"}} onClick={() => onRemoveCloseIcon(item)}>
                            </IoCloseOutline> */}
                          </Grid>

                          <Grid item xl={7} lg={7} md={7} sm={3} xs={6}>
                            <div className='spaceLarge'></div>
                          </Grid>

                          <Grid item xl={1} lg={1} md={1} sm={2} xs={2} display="flex" justifyContent="right" alignItems="center">
                            <div >{item.price}{item.currency}</div>
                          </Grid>

                        </Grid>
                      
                      <Grid item xl={12} xs={12}>
                        <div className='spaceSmall'></div>
                        <div className='spaceSmall'></div>
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Divider></Divider>
                      </Grid>

                      <Grid item xl={12} xs={12}>
                        <div className='spaceLarge'></div>
                          <div className='spaceSmall'></div>
                      </Grid>

                    </Grid>
                    
                  </div>
                  ))}
              </div>

          </Grid>
          <Grid item xl={12} lg={12} md={12} xs={12} >
            <ShoppingBagSum  cartItems={cartItems} setCartItems={setCartItems}></ShoppingBagSum>

          </Grid>

            
        </Grid>
    </Grid>
    </div>
  )
}
