import React from 'react'
import "./policies.css"
import Navbar from '../../components/Navbar'
import { HeaderHome } from '../../components/headerHome/HeaderHome'

export default function TermsOfService() {
  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <div className='policy-text'>
    KISISEL VERILERIN KORUNMASI POLITIKASI
        <br/>
        <br/>
 
 AMAÇ<br/>
  
 SENA KARAHASAN(Sahıs Sirketi) olarak; müsterilerimiz ve çalısanlarımız dahil Sirket ile iliskili gerçek kisilerin kisisel verilerinin Türkiye Cumhuriyeti Anayasası ve insan haklarına iliskin ülkemizin tarafı oldugu uluslararası sözlesmeler ile 6698 sayılı Kisisel Verilerin Korunması Kanunu (“KVKK”) basta olmak üzere ilgili mevzuata uygun olarak islenmesi ve verisi islenen ilgili kisilerin haklarını etkin sekilde kullanılmasının saglanması önceligimizdir.<br/>
 <br/>
 Bu nedenle, sayılanlarla sınırlı olmamak üzere; çalısanlarımızın, müsterilerimizin, tedarikçilerimizin, ziyaretçilerimizin, is baglantılarımızın, is ortaklarımızın, bayilerimizin, www.alma-rituals.com adresli internet sitemizi ziyaret eden kullanıcılarımızın, kısacası faaliyetlerimiz sırasında edindigimiz tüm kisisel verilere iliskin verinin islenmesi, saklanması, aktarılmasına iliskin islemleri Sirket’in Kisisel Verilerin Korunması ve Islenmesi Politikasına (“Politika”) göre gerçeklestirmekteyiz.
  
 Kisisel verilerin korunması ve kisisel verileri toplanan gerçek kisilerin temel hak ve hürriyetlerinin gözetilmesi kisisel verilerin islenmesine iliskin politikamızın temel prensibidir. Bu nedenle kisisel verinin islendigi tüm faaliyetlerimizi, özel hayatın gizliliginin korunması, haberlesmenin gizliligi, düsünce ve inanç özgürlügü, etkili kanun yollarını kullanma haklarını gözeterek sürdürmekteyiz.
  
 Kisisel verilerin korunması için mevzuat ve güncel teknolojiye uygun sekilde ilgili verinin niteliginin gerektirdigi tüm idari ve teknik koruma tedbirlerini almaktayız.
  
 Isbu Politika, ticari veya sosyal sorumluluk ve benzeri faaliyetlerimiz sırasında paylasılan kisisel verilerin KVKK’da anılan ilkeler çerçevesinde islenmesi, saklanması, aktarılması ve silinmesi ya da anonim hale getirilmesine dair izledigimiz yöntemleri açıklamaktadır.
 
 <br/>
 <br/>
 
 KAPSAM
 Sayılanlarla sınırlı olmamak üzere; çalısanlarımızın, müsterilerimizin, tedarikçilerimizin, ziyaretçilerimizin, is baglantılarımızın, is ortaklarımızın, bayilerimizin, internet sitemizi ziyaret eden kullanıcılarımızın, kısacası faaliyetlerimiz sırasında edindigimiz ve Sirket tarafından islenen tüm kisisel veriler bu Politika’nın kapsamındadır.
 Kisisel verilerin koruması sadece gerçek kisiler ile ilgili olup tüzel kisilere ait, içerisinde gerçek kisiye iliskin bilgi içermeyen bilgiler kisisel veri koruması dısında bırakılmıstır. Bu nedenle isbu Politika tüzel kisilere ait verilere uygulanmaz.
 Politikamız, Sirket tarafından yönetilen, tüm kisisel verilerin islenmesine yönelik faaliyetlerde uygulanmakta olup verilere iliskin ilgili diger mevzuat ve bu alandaki uluslararası standartlar gözetilerek ele alınmıs ve hazırlanmıstır.
 TANIM ve KISALTMALAR
  
 Bu bölümde Politika’da geçen özel terim ve deyimler, kavramlar, kısaltmalar vb. kısaca açıklanır.

 <br/>
 <br/>
  
 3.1. Açık Rıza: Belirli bir konuya iliskin, bilgilendirilmeye ve özgür iradeye dayanan, tereddüte yer bırakmayacak açıklıkta, sadece o islemle sınırlı olarak verilen onay.
 
 <br/>3.2. Anonim Hale Getirme: Kisisel verilerin, baska verilerle eslestirilerek dahi hiçbir surette kimligi belirli veya belirlenebilir bir gerçek kisiye iliskilendirilemeyecek hale getirilmesidir.
 <br/>3.3. Çalısan: Sirket Personelini ifade eder.
 <br/>3.4. Kisisel Veri Sahibi (Ilgili Kisi): Kisisel verisi islenen gerçek kisi.
 <br/>3.5. Kisisel Veri: Kimligi belirli veya belirlenebilir gerçek kisiye iliskin her türlü bilgi.
 <br/>3.6. Özel Nitelikli Kisisel Veri: Kisilerin, ırkı, etnik kökeni, siyasi düsüncesi, felsefi inancı, dini, mezhebi veya diger inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeligi, saglıgı, cinsel hayatı, ceza mahkumiyeti, ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri.
 <br/>3.7. Kisisel Verilerin Islenmesi: Kisisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, saklanması, degistirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçeklestirilen her türlü islem.
 <br/>3.9. Veri Isleyen: Veri sorumlusunun verdigi yetkiye dayanarak veri sorumlusu adına kisisel verileri isleyen gerçek veya tüzel kisi.
 <br/>3.10. Veri Sorumlusu: Kisisel verilerin isleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasında ve yönetilmesinden sorumlu gerçek veya tüzel kisi.
 <br/> 3.11. KVK Kurulu: Kisisel Verileri Koruma Kurulu.
 <br/>3.12. KVK Kurumu: Kisisel Verileri Koruma Kurumu.
 <br/>3.13. KVKK: 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazetede yayımlanan Kisisel Verilerin Korunması Kanunu.
 <br/>3.14. KEP: Kayıtlı Elektronik Posta adresidir. Her türlü ticari, hukuki yazısma ve belge paylasımlarını gönderilen biçimde koruyan, alıcının kim oldugunu kesin olarak tespit eden, içerigin kesinlikle degismemesini ve içerigi yasal geçerli ve güvenli, kesin delil haline getiren sistemdir.
 <br/>3.15. Politika: Sirket’in Kisisel Verilerin Korunması ve Islenmesi Politikası.
  
 <br/> <br/>HUKUKI YÜKÜMLÜLÜKLER
 <br/>
 KVKK uyarınca veri sorumlusu olarak kisisel verilerin korunması ve islenmesi kapsamında hukuki yükümlülükler asagıda sıralanmıstır:
  
 <br/> <br/>4.1. Aydınlatma yükümlülügümüz
 Veri sorumlusu olarak kisisel verileri toplarken;
 Kisisel verilerinizin hangi amaçla islenecegi,
 Kimligimiz, varsa temsilcimizin kimligine iliskin bilgiler,
 Islenen kisisel verilerinizin kimlere ve hangi amaçla aktarılabilecegi,
 Verileri toplama yöntemimiz ve hukuki sebebi,
 Kanundan dogan haklar, hususlarında Ilgili Kisi’yi aydınlatma yükümlülügümüz bulunmaktadır.
 Sirket olarak kamuoyuna açık olan isbu Politikamızın açık, anlasılır, kolay erisebilir olmasına özen göstermekteyiz.
 <br/> 
 4.2. Veri güvenligini saglama yükümlülügümüz
 Veri sorumlusu olarak uhdemizde bulunan kisisel verilerin güvenligini saglamak için mevzuatta öngörülen idari ve teknik tedbirleri almaktayız.
 <br/> <br/>
 KISISEL VERILERIN SINIFLANDIRILMASI

 <br/> <br/>
  
 5.1. Kisisel veriler
 Kisisel veriler; kimligi belirli veya belirlenebilir gerçek kisiye iliskin her türlü bilgilerdir.
 Kisisel verilerin koruması sadece gerçek kisiler ile ilgili olup tüzel kisilere ait, içerisinde gerçek kisiye iliskin bilgi içermeyen bilgiler kisisel veri koruması dısında bırakılmıstır. Bu nedenle isbu Politika tüzel kisilere ait verilere uygulanmaz.
 <br/>
 5.2. Özel nitelikli kisisel veriler
 Kisilerin, ırkı, etnik kökeni, siyasi düsüncesi, felsefi inancı, dini, mezhebi veya diger inançları ile kılık ve kıyafeti, dernek, vakıf ya da sendika üyelikleri, saglıgı, cinsel hayatı, ceza mahkumiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri özel nitelikli kisisel verilerdir.
 <br/> <br/>
 KISISEL VERILERIN ISLENMESI

 <br/> <br/>
  
 6.1. Kisisel verileri isleme ilkelerimiz
 Kisisel verileri asagıda yer alan ilkeler uyarınca islemekteyiz.
 <br/>
 6.1.1. Hukuka ve dürüstlük kurallarına uygun isleme
 Kisisel verileri dürüstlük kurallarına uygun, seffaf ve aydınlatma yükümlülügümüz çerçevesinde islemekteyiz.
 <br/>
 6.1.2. Kisisel verilerin dogru ve gerektiginde güncel olmasını saglama
 Islenen verilerin dogru ve güncel olmasını saglamak için veri isleme prosedürlerimizde gerekli tedbirleri alırız. Kisisel Veri Sahibi’ne de verilerini güncellemesi ve var ise islenen verilerindeki hataları düzeltmesi için bize basvurmasına olanak sunarız.
 <br/>
 6.1.3. Belirli, açık ve mesru amaçlarla isleme
 Sirket olarak kisisel verileri kapsamı ve içerigi açıkça belirlenmis, mevzuat ve ticari hayatın olagan akısı çerçevesinde faaliyetlerimizi sürdürmek için belirlenen mesru amaçlarımız dahilinde islemekteyiz.
 <br/>
 6.1.4. Kisisel verilerin islendikleri amaçla baglantılı, sınırlı ve ölçülü olması
 Kisisel verileri açık ve kesin olarak belirledigimiz amaçla baglantılı, sınırlı ve ölçülü olarak islemekteyiz.
 Ilgili olmayan veya islenmesine ihtiyaç duyulmayan kisisel verilerin islenmesinden kaçınmaktayız. Bu nedenle, yasal gereklilik olmadıgı sürece özel nitelikte kisisel verileri islememekte veya islememiz gerektiginde konuya iliskin açık rıza almaktayız.
 <br/>
 6.1.5. Kanuni düzenlemeler tarafından öngörülen ve ticari mesru menfaatlerimiz süresince kisisel verilerin saklanması
 Mevzuattaki birçok düzenleme kisisel verilerin belirli bir süre saklanmasını zorunlu kılmaktadır. Bu nedenle, isledigimiz kisisel verileri ilgili mevzuatta öngörülen veya kisisel verilerin islenme amaçları için gerekli olan süre kadar saklamaktayız.
 Mevzuatta öngörülen saklama süresinin sona ermesi veya isleme amacının ortadan kalması durumunda kisisel verileri silmekte, yok etmekte veya anonim hale getirmekteyiz.
 <br/>
 6.2. Kisisel verileri isleme amaçlarımız
 <br/> <br/>
 Sirket olarak kisisel verileri sayılanlarla sınırlı olmamak üzere asagıda sayılanlarla benzer amaçlar için islemekteyiz:
 Faaliyetlerimizin yürütülmesi,
 Sözlesme kapsamında ve hizmet standartları çerçevesinde müsterilere hizmet saglamak,
 Müsterilerimize verilecek hizmetler için ihtiyaçlarının tespit edilerek hizmetlerin bu kapsamda sekillendirilmesi, güncellenmesi,
 Yasal düzenlemelerin gerektirdigi veya zorunlu kıldıgı sekilde, hukuki yükümlülüklerimizi yerine getirilmesini saglamak,
 Pazar arastırmaları ve istatistiksel çalısmalar yapabilmek,
 Anket, promosyon ve sponsorluklar
 Is basvurularını degerlendirmek,
 Sirket ile is iliskisinde bulanan kisiler ile irtibat saglamak,
 Pazarlama,
 Satıcı / tedarikçi yönetimi,
 Reklam,
 Yasal raporlama,
 Faturalandırma.
 <br/>
 6.3. Özel nitelikli kisisel verilerin islenmesi
  
 Özel nitelikli kisisel veriler kanunlarda öngörülen ve KVK Kurulu’nun öngördügü idari ve teknik tedbirler alınarak ve açık rıza var ise veya mevzuatın zorunlu kıldıgı hallerde, tarafımızdan islenir.
 Saglık ve cinsel hayata iliskin özel nitelikli kisisel veriler, kamu saglıgının korunması, koruyucu hekimlik, tıbbi teshis, tedavi ve bakım hizmetlerinin yürütülmesi, saglık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülügü altında bulunan kisiler veya yetkili kurum ve kuruluslar tarafından islenebilmekte oldugundan, tarafımızdan çalısanlarımızın verisi dısında islenmez. Çalısanlarımıza ait bu tür veriler ise kanunlarda öngörülen kisilerce islenebilir.
 <br/>
 6.4. Kisisel verilerin islenmesinde açık rızanın aranmadıgı istisnai haller
 Asagıda sayılan ve kanundan dogan istisnai hallerde açık rıza alınmadan kisisel verileri isleyebiliriz:
 Kanunlarda açıkça öngörülmesi;
 Bir sözlesmenin kurulması veya ifasıyla dogrudan dogruya ilgili olması kaydıyla sözlesmenin taraflarına ait kisisel verilerin islenmesinin gerekli olması;
 Bir hakkın tesisi, kullanılması veya korunması için veri islemenin zorunlu olması;
 Temel hak ve özgürlüklere zarar vermemek kaydıyla, veri sorumlusu olarak mesru menfaatlerimiz için verilerinizi islememizin zorunlu olması.
 <br/> <br/>
 KISISEL VERILERIN AKTARILMASI
 <br/> <br/>
 7.1. Kisisel verilerin yurt içine aktarımı
 Sirket olarak, kisisel verilerin aktarılması konusunda KVKK’da öngörülen ve KVK Kurul’u tarafından alınan karar ve düzenlemelere uygun bir sekilde hareket etmekteyiz.
 Mevzuatta yer alan istisnai haller saklı kalmak kaydıyla, kisisel veriler ve özel nitelikli veriler Ilgili Kisi’nin açık rızası olmadan baska gerçek kisilere veya tüzel kisilere tarafımızdan aktarılmaz.
 KVKK ve sair mevzuatın öngördügü istisnai hallerde Ilgili Kisi’nin açık rızası olmadan da veriler mevzuatta öngörülen sekilde ve sınırlarla baglı olarak yetkili kılınan idari veya adli kurum veya kurulusa aktarılabilir.
 Ayrıca, mevzuatın öngördügü istisnai haller ile;
 Politika’nın 6.4. maddesinde açıklanan hallerde,
 Özel nitelikli kisisel veriler hususunda Politika’nın 6.3. maddesinde sayılan hallerde,
 KVK Kurul’unun ve ilgili mevzuatın öngördügü tedbirlerin alınması ile birlikte Ilgili Kisi’nin saglıgına ve cinsel hayatına iliskin özel nitelikli kisisel veriler ise ancak kamu saglıgının korunması, koruyucu hekimlik, tıbbi teshis, tedavi ve bakım hizmetlerinin yürütülmesi, saglık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülügü altında bulunan kisiler veya yetkili kurum ve kuruluslara, açık rıza aranmadan aktarılabilir.
 <br/>
 7.2. Kisisel verilerin yurt dısına aktarımı
 Kisisel veriler kural olarak Ilgili Kisi’nin açık rızası olmadan yurt dısına aktarılmaz. Ancak isbu Politika’nın 6.3. ve 6.4 maddelerinde yer alan istisnai hallerinin birinin var oldugu durumlarda yurt dısında bulunan üçüncü kisilerin ancak:
 KVK Kurulu’nun ilan ettigi yeterli korumanın oldugu ülkelerde bulunması;
 Yeterli korumanın olmadıgı ülkelerde yer alması halinde Türkiye’de ve söz konusu yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve KVK Kurulu’nun iznin bulunması; hallerinde açık rıza olmadan kisisel veriler yurt dısında aktarılabilir.
 <br/>
 7.3. Kisisel verilerin aktarıldıgı kurum ve kuruluslar
 Kisisel veriler sayılanlar sınırlı olmamak üzere;
 Tedarikçilerimize,
 Is ortaklarımız ve is baglantılarımıza,
 Hukuken yetkili kamu kurum ve kuruluslarına,
 Hukuken yetkili özel hukuk kisilerine,
 yukarıda açıklanan ilke ve kurallara göre aktarılabilir.
 <br/>
 7.4. Kisisel verilerin hukuka uygun olarak aktarılmasına iliskin aldıgımız tedbirler
 <br/>
 7.4.1. Teknik tedbirler
 Kisisel verileri korumak için sayılanlarla sınırlı olmamak üzere;
 Kisisel verilerin mevzuata uygun olarak islenmesi ve saklanması için sirket içi teknik organizasyonu yapmakta,
 Kisisel verilerinizin saklanacagı veri tabanlarının güvenligini saglamak için teknik altyapıyı olusturmakta,
 Olusturulan teknik alt yapının süreçlerini takip etmekte ve denetimlerini yapmakta,
 Aldıgımız teknik tedbirlerin ve denetim süreçlerinin raporlanmasına iliskin prosedürleri belirlemekte,
 Teknik tedbirleri periyodik olarak güncellemekte ve yenilemekte,
 Riskli durumlar yeniden incelenerek gerekli teknolojik çözümler üretilmekte,
 Virüs koruma sistemleri, güvenlik duvarı ve benzeri yazılımsal veya donanımsal güvenlik ürünleri kullanmakta ve teknolojik gelismelere uygun güvenlik sistemleri kurmaktayız,
 Teknik konularda uzman çalısanlar istihdam etmekteyiz.
 <br/>
 7.4.2. Idari tedbirler
 Kisisel verilerinizi korumak için sayılanlarla sınırlı olmamak üzere;
 Sirketimizde kisisel verilere erisim politika ve prosedürleri olusturmakta,
 Çalısanlarımızı kisisel verilerin hukuka uygun bir sekilde korunması ve islenmesine iliskin bilgilendirmekte ve egitmekte,
 Çalısanlarımız ile yaptıgımız sözlesmelerde ve/veya olusturdugumuz Politikalarda, sirket Çalısanlarımız tarafından kisisel verilerin hukuka aykırı olarak islenmesi durumlarında alınacak tedbirleri kayıt altına almakta,
 Birlikte çalıstıgımız veri isleyenler veya veri isleyenlerin ortaklarının kisisel verilerin islenmesi faaliyetlerini denetlemekteyiz.
 <br/> <br/>
 KISISEL VERILERIN SAKLANMASI <br/> <br/>
 8.1. Kisisel verilerin ilgili mevzuatta öngörülen veya islendikleri amaç için gerekli olan süre kadar saklanması
 Kisisel verileri mevzuatta öngörülen saklama süreleri saklı kalmak kaydıyla, kisisel verilerin isleme amacının gerektirdigi süre boyunca saklamaktayız.
 Kisisel verileri birden fazla amaç ile isledigimiz hallerde, verinin isleme amaçlarının ortadan kalkması veya Ilgili Kisi’nin talebi üzerine verilerin silinmesine mevzuatta bir engel olmaması durumunda veriler silinir, yok edilir veya anonimlestirilerek saklanır. Yok etme, silme veya anonimlestirme hususlarında mevzuat hükümleri ve KVK Kurul kararlarına uyulur.
 <br/>
 8.2. Kisisel verilerin saklanmasına iliskin aldıgımız tedbirler <br/>
 8.2.1. Teknik tedbirler <br/>
 Kisisel verilerin silinmesi, yok edilmesi ve anonimlestirilmesi için teknik alt yapılar ve bunlara iliskin denetim mekanizmaları olusturmakta,
 Kisisel verilerin güvenli sekilde saklanması için gerekli tedbirleri almakta,
 Teknik uzmanlıgı olan çalısanlar istihdam etmekte,
 Kisisel verilerin saklama alanlarına iliskin teknolojik gelismeler uyarınca güvenlik sistemleri kurmaktayız.
 8.2.2. Idari tedbirler <br/>
 Kisisel verilerin saklanması ile ilgili teknik ve idari riskler hakkında çalısanlarımızı bilgilendirerek farkındalık yaratmakta,
 Kisisel verilerin saklanması için üçüncü kisilerle isbirligi yapılması durumunda kisisel verilerin aktarıldıgı sirketler ile yapılan sözlesmelere; kisisel verilerin aktarıldıgı kisilerin, aktarılan kisisel verilerin korunması ve güvenli saklanması amacıyla gerekli güvenlik tedbirlerinin alınmasına iliskin hükümlere yer vermekteyiz.
 <br/> <br/>
 KISISEL VERILERIN GÜVENLIĞI <br/> <br/>
 9.1. Kisisel verilerin güvenligine iliskin yükümlülüklerimiz
 Kisisel verilerin;
 Hukuka aykırı islenmesini önlemek,
 Hukuka aykırı erisimini önlemek,
 Hukuka uygun olarak saklanmasını saglamak,
 için teknolojik olanaklar ve uygulama maliyetlerine göre idari ve teknik tedbirler almaktayız.
 <br/>
 9.2. Kisisel verilerin hukuka aykırı islenmesini önlemek için aldıgımız tedbirler
 Sirketimiz içerisinde gerekli denetimleri yapmakta ve yaptırmakta,
 Çalısanlarımızı kisisel verilerin hukuka uygun olarak islenmesi hakkında egitmekte ve bilgilendirmekte,
 Sirketimiz yürüttükleri faaliyetler detaylı olarak tüm is birimleri özelinde degerlendirilmekte, söz konusu degerlendirme sonucunda ilgili birimlerin gerçeklestirdigi ticari faaliyetler özelinde kisisel verileri islemekte,
 Kisisel verilerin islenmesi amacıyla üçüncü kisilerle isbirligi yapıldıgı hallerde kisisel verileri isleyen sirketler ile yapılan sözlesmelerde; kisisel verileri isleyen kisilerin gerekli güvenlik tedbirlerinin almasına iliskin hükümlere yer vermekte,
 Kisisel verilerin hukuka aykırı olarak ifsa edilmesi veya veri sızıntısı olması halinde KVK Kurul’una durumu bildirerek bu hususta mevzuat tarafından öngörülen incelemeleri yapmakta ve tedbirleri almaktayız.
 <br/>
 9.2.1. Kisisel verilere hukuka aykırı erisimi engellemek için alınan teknik ve idari tedbirler
 Kisisel verilerin hukuka aykırı erisimini engellemek için;
 Teknik uzmanlıgı olan çalısanlar istihdam etmekte,
 Teknik tedbirleri periyodik olarak güncellemekte ve yenilemekte,
 Sirketimiz içerisinde erisim yetkilendirme prosedürleri olusturmakta,
 Aldıgımız teknik tedbirlerin ve denetim süreçlerinin raporlanmasına iliskin prosedürleri belirlemekte,
 Veri kayıt sistemlerini mevzuata uygun sekilde olusturmakta ve periyodik olarak denetimlerini yapmakta,
 Olusabilecek risklere karsı acil yardım planları olusturup bunların uygulanmasına iliskin sistemler gelistirmekte,
 Çalısanlarımızı kisisel verilere erisim, yetkilendirme hususlarında egitmekte ve bilgilendirmekte,
 Kisisel verilerin islenmesi, saklanması gibi faaliyetler amacıyla üçüncü kisilerle isbirligi yapıldıgı hallerde kisisel verilere erisim saglayan sirketler ile yapılan sözlesmelerde; kisisel verilere erisim saglayan kisilerin gerekli güvenlik tedbirlerinin alınmasına iliskin hükümlere yer vermekte,
 Kisisel verilere hukuka aykırı erisimi engellemeyi saglamak üzere teknolojik gelismeler dahilinde güvenlik sistemleri kurmaktayız.
 <br/>
 9.2.2. Kisisel verilerin hukuka aykırı ifsası durumunda aldıgımız tedbirler
 Kisisel verilerin hukuka aykırı ifsasının engellenmesine yönelik idari ve teknik tedbirler almakta ve ilgili prosedürlerimize uygun sekilde bunları güncellemekteyiz. Kisisel verilerin yetkisiz olarak ifsa edildigini tespit etmemiz halinde bu durumu Ilgili Kisi’ye ve KVK Kurul’una bildirmek için sistem ve alt yapılar olusturmaktayız.
 Alınan tüm idari ve teknik tedbirlere ragmen hukuka aykırı bir ifsa gerçeklesmesi durumunda KVK Kurulu tarafından gerek görülmesi halinde, bu durum, KVK Kurulu’nun internet sitesinde veya baska bir yöntemle ilan edilebilecektir.
 <br/> <br/>
 KISISEL VERI SAHIBININ HAKLARI
 <br/> <br/>
 Aydınlatma yükümlülügümüz kapsamında Kisisel Veri Sahibi’ni bilgilendirmekte ve bu bilgilendirmeye iliskin sistem ve alt yapılar kurmaktayız. Kisisel Veri Sahibi’nin kisisel verilerinize iliskin haklarını kullanması için gerekli olan teknik ve idari düzenlemeleri yapmaktayız.
 Kisisel Veri Sahibi kisisel verileri üzerinde;
 Kisisel verilerin islenip islenmedigini ögrenme,
 Kisisel veriler islenmisse buna iliskin bilgi talep etme,
 Kisisel verilerin islenme amacını ve bunların amacına uygun kullanılıp kullanılmadıgını ögrenme,
 Yurt içinde veya yurt dısında kisisel verilerin aktarıldıgı üçüncü kisileri bilme,
 Kisisel verilerin eksik veya yanlıs islenmis olması halinde bunların düzeltilmesini isteme,
 Kisisel verilerin islenmesini gerektiren sebeplerin ortadan kalkması halinde kisisel verilerin silinmesini veya yok edilmesini isteme,
 Yukarıda bahsedilen düzeltme, silme veya yok etme islemlerinin, kisisel verilerin aktarıldıgı üçüncü kisilere bildirilmesini isteme,
 Islenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhe bir sonuç ortaya çıkmasına itiraz etme,
 Kisisel verilerin kanuna aykırı olarak islenmesi sebebiyle zarara ugraması halinde zararın giderilmesini talep etme,
 haklarına sahiptir.
 
 <br/>
 10.1. Kisisel verilere iliskin hakların kullanılması
 Kisisel Veri Sahibi, Kisisel verileri ile ilgili talebini KVK Kurul’u tarafından ayrı bir yöntem belirlenmesi halinde bu yöntem ile veya Etiler Mah. Çamlık Mevkii Çal Hasan Sok. Sedir Apt. No 3/3 Besiktas/ISTANBUL adresine yazılı ve ıslak imzalı olarak ya da senakarahasann@gmail.com e-posta adresimize gönderebilecektir.
 Kisisel Veri Sahibi yukarıda belirtilen hakları kullanmak için yapacagı ve kullanmayı talep ettigi hakka iliskin açıklamaların içeren basvuruda; talep edilen hususun açık ve anlasılır olması, talep edilen konunun basvuranın sahsı ile ilgili olması veya baskası adına hareket ediliyor ise bu konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi, ayrıca basvurunun kimlik ve adres bilgilerini içermesi ve basvuruya kimligini tevsik edici belgelerin eklenmesi gerekmektedir. Söz konusu talepler bireysel olarak yapılacak olup yetkisiz üçüncü kisilerin kisisel veriler ile ilgili yaptıgı talepler degerlendirmeye alınmayacaktır.
  
 Basvurular, “Veri Sahibi Basvuru Formu” ile birlikte yapılmalıdır.
 <br/>
 10.2. Basvurunun degerlendirilmesi
 <br/>
 10.2.1. Basvurunun cevaplandırılma süresi
 Kisisel verilere iliskin talepler, niteligine göre en kısa sürede ve her halükarda en geç 30 (otuz) gün içinde ücretsiz olarak veya KVK Kurulu tarafından ücrete iliskin yayınlanacak tarifedeki kosulların olusması durumunda tarifedeki ücret mukabili sonuçlandırılır.
 Basvuru sırasında veya basvuru degerlendirilirken ek bilgi ve belge talep edilmesi söz konusu olabilecektir.
 <br/>
 10.2.2. Basvuruyu reddetme hakkımız
 Kisisel veriler ile ilgili basvurular;
 Kisisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle arastırma, planlama ve istatistik gibi amaçlarla islenmesi,
 Kisisel verilerin özel hayatın gizliligini veya kisilik haklarını ihlal etmemek ya da suç teskil etmemek kaydıyla, sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlügü kapsamında islenmesi,
 Kisisel Veri Sahibi tarafından alenilestiren kisisel verilerin islenmesi
 Basvurunun haklı bir nedene dayanmaması
 Basvurunun ilgili mevzuata aykırı bir istem içermesi
 Basvuru usulüne uyulmaması
 hallerinde gerekçelendirilerek reddedilir.
 <br/>
 10.3. Basvurunun degerlendirilme usulü
 Isbu Politika’nın 10.2.1 maddesinde belirtilen cevaplandırma süresinin baslayabilmesi için yapılan taleplerin yazılı ve ıslak imzalı olarak ya da elektronik imzalı gönderilmesi veya KVK Kurulu’nun belirledigi diger yöntemlerle basvuranın kimliginin tevsik edici bilgi ve belgelerle göndermeniz gerekmektedir.
 Talep kabul edilir ise ilgili islem uygulanır ve yazılı veya elektronik ortamda bildirim yapılır. Talebin reddi halinde ise, gerekçesi açıklanarak yazılı veya elektronik ortamda basvuru sahibine bildirilir.
 <br/>
 10.4. Kisisel Verileri Koruma Kurulu’na olan sikâyet hakkı
 Basvurunun reddedilmesi, verdigimiz cevabı yetersiz bulunması veya süresinde cevap verilmemesi hallerinde; basvuru sahibinin cevabı ögrendigi tarihten itibaren 30 (otuz) gün ve her halde basvuru tarihinden itibaren 60 (altmıs) gün içerisinde KVK Kurulu’na sikâyette bulunma hakkı bulunmaktadır.
  
 Söz konusu hakların kullanımına iliskin talepler, kisisel veri sahipleri Alısveris Sitemiz Tarafından 6698 sayılı Kanun Kapsamında belirtilen yöntemlerle iletilebilecektir. Alısveris sitemiz söz konusu talepleri degerlendirerek 30 gün içerisinde sonuçlandıracaktır.
 Isbu form üzerinde yer verilen hususlar ile ilgili olarak, hukuki ve teknolojik gelismeler dogrultusunda degisiklikler söz konusu olabilecektir.
   </div>

    </div>
  )
}
