import React, { useState, useEffect } from "react";
import './newsletter.css';
import palms from "./palms-newsletter.png"
import { Divider, Grid } from "@mui/material";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { HeaderHome } from "../../components/headerHome/HeaderHome";
import Navbar from "../../components/Navbar";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";





export default function Newsletter() {
  const navigate = useNavigate();

//   const [email, setEmail] = useState("");


    const  subscribe = async(email) =>{
        const emailInfo = {
            body: {
                email: email
            }
        }
        await API.post(
        "almaAppApi",
        "/api/send-newsletter-sub-wo-discount",emailInfo
      )
        .then((result) => {
          console.log(result);
          navigate("../");
        })
        .catch((err) => {
          //user does not exist
          //     console.log(err);
        });

        
       }
    
    const validationSchema = Yup.object().shape({
        email:Yup.string().required("EMAIL IS REQUIRED"),
    });

    const handleSubmit = (values) => {
        console.log("dfgsdgdfg");
       subscribe(values.email);
    
      };

    return(
        <div>
            <Navbar></Navbar>
            <HeaderHome></HeaderHome>
        <div className='subscribe-newsletter'>
            <div className='subscribe-newsletter-text'>JOIN OUR JOURNEY</div>

            <Formik
            initialValues={{ 
                email: ""
              }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
                <Form>
                    <Field className='subscribe-newsletter-input' name="email" placeholder='EMAIL ADDRESS *'>
                        
                    </Field>


            <Grid container xl={5.1} lg={5.1} md={5.1} sm={5.1} xs={12} style={{margin:"auto"}}>
            
                <Grid item xl={5} lg={5} md={5} sm={5} xs={7}>
                    <button className="subscribe-newsletter-black-sign-up-button"
                    type="submit">
                        SIGN UP
                    </button>
                </Grid>
                <Grid item xl={7} lg={7} md={7} sm={7} xs={5}>
                    <img className='subscribe-newsletter-img' src= {palms}></img>

                </Grid>

            </Grid>

            </Form>

            </Formik>
            
        </div>
        </div>
    );
}