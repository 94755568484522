import React from 'react'
import Navbar from '../../components/Navbar'
import { HeaderHome } from '../../components/headerHome/HeaderHome'



export default function RefundPolicy() {
  return (
    <div>
      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <div className='policy-text'>

MESAFELİ SATIŞ SÖZLEŞMESİ

<br/>
<br/>
 
 İşbu Sözleşme, ALICI (Tüketici)’nın işlem yaptığı hallerde SATICI’ya ait www.alma-rituals.com elektronik ticaret internet sitesine (“İNTERNET SİTESİ”) sipariş vererek satın almak istediği aşağıda belirtilen ürün/hizmetlerin (“Ürün/Ürünler”) ALICI’ya satış-teslimi ve diğer hususlara ilişkin olarak tarafların hak ve yükümlülüklerini düzenler. ALICI işbu Sözleşme’yi İNTERNET SİTESİ’nde onayladıktan sonra, sipariş verdiği Ürün(ler)’in bedeli ve masrafları seçtiği ödeme yöntemi ile tahsil olunur.
 
<br/>
<br/>
 MADDE 1 – TARAFLAR

<br/>
<br/>
 Satıcı<br/>
 Adı,Soyadı/Unvanı: SENA KARAHASAN (www.alma-rituals.com)<br/>
 Adresi: Etiler Mah. Çamlık Mevki Çal Hasan Sok. Sedir Apt. no 3/3 Etiler Beşiktaş/İSTANBUL<br/>
 Telefon: 05378676512<br/>
 E-Posta: senakarahasann@gmail.com<br/>
 Banka Hesabı: Garanti Bankası -<br/>
 
 TR32 0006 2000 3400 0006 6190 83<br/>
  
 <br/><br/>
 Alıcı (Tüketici)<br/>
 Adı,Soyadı/ Unvanı:<br/>
 Adres:<br/>
 Telefon:<br/>
 E-Posta:<br/>
 <br/>
 MADDE 2 – KONU<br/><br/>
 İşbu sözleşmenin konusu, Alıcı’nın Satıcı’ya ait www.alma-rituals.com internet sitesinden elektronik ortamda siparişini yaptığı aşağıda nitelikleri ve satış ücreti belirtilen ürünün satışı ve teslimi ile ilgili olarak 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
 Alıcı, satıcının isim, unvan, açık adres, telefon ve diğer erişim bilgileri, satışa konu malın temel nitelikleri, vergiler dahil olmak üzere satış fiyatı, ödeme şekli, teslimat koşulları ve masrafları gibi satışa konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı, şikayet ve itirazlarını iletebilecekleri resmi makamlar konusunda açık, anlaşılır ve internet ortamına uygun şekilde satıcı tarafından bilgilendirildiğini, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında ürün sipariş verdiğini işbu sözleşme hükümlerince kabul ve beyan eder.
 www.alma-rituals.com sitesinde yer alan ön bilgilendirme ve alıcı tarafından verilen sipariş üzerine düzenlenen fatura işbu sözleşmenin ayrılmaz parçalarıdır.
 MADDE 3 – SÖZLEŞME KONUSU ÜRÜN/ÖDEME/TESLİMAT BİLGİLERİ
 Elektronik ortamda alınan ürün/ürünlerin cinsi ve türü, miktarı, marka/modeli, satış bedeli, ödeme şekli, teslim alacak kişi, teslimat adresi, fatura bilgileri, kargo ücreti aşağıda belirtildiği gibidir. Fatura edilecek kişi ile sözleşmeyi yapan kişi aynı olmak zorundadır. Aşağıda yer alan bilgiler doğru ve eksiksiz olmalıdır. Bu bilgilerin doğru olmadığı veya noksan olduğu durumlardan doğacak her türlü sorumluluğu alıcı kabul eder. Satıcı, gerekli gördüğü durumlarda Alıcı’nın vermiş olduğu bilgiler gerçekle örtüşmediğinde siparişi durdurma hakkını saklı tutar. Satıcı siparişte sorun tespit durumlarda Alıcı’nın vermiş olduğu telefon, e-posta ve posta adreslerinden Alıcı’ya ulaşamadığı takdirde siparişin yürürlüğe koyulmasını 15 (onbeş) gün süreyle dondurur. Alıcı’nın bu süre zarfında Satıcı ile konuyla ilgili olarak iletişime geçmesi beklenir. Bu süre içerisinde Alıcı’dan herhangi bir cevap alınamazsa Satıcı, her iki tarafın da zarar görmemesi için siparişi iptal eder.
 <br/>
 ÜRÜN<br/>
 BİRİM FİYAT (TL)<br/>
 ADET<br/>
 TOPLAM (TL)<br/>
 Marka, Ürün, Renk, Beden vb.<br/>
  
 <br/><br/>
  
 Sipariş işlem ve gönderim-kargo ücretleri
  
 SİPARİŞ ÖDEME TOPLAMI (KDV DAHİL)<br/>
  
  
 Ödeme (Tahsilat) Bilgileri<br/>
 Ödeme Şekli – Aracı                                  : (Kredi Kartı/Kapıda Kredi Kartlı veya Nakit Tahsilat)<br/>
 Kart Tipi                                                        :<br/>
 Kart Numarası                                             :<br/>
 Kredi Kartına Taksit/Tek Çekim               :<br/>
 Toplam Tutar                                               :<br/>
 TESLİMAT BİLGİLERİ<br/>
 Adı, Soyadı/ Unvanı<br/>
 <br/>
  
 Adres<br/>
  
 Telefon<br/>
  
 E-Posta<br/>
  
 FATURA BİLGİLERİ<br/><br/>
 Adı, Soyadı/ Unvanı<br/>
  
 Adres<br/>
  
 Telefon<br/>
  
 E-Posta<br/>
  
 <br/><br/>
 MADDE 4 – SÖZLEŞME TARİHİ VE MÜCBİR NEDENLER

 <br/><br/>
 Sözleşme tarihi, Alıcı tarafından siparişin verildiği tarihtir. Sözleşmenin imzalandığı tarihte mevcut olmayan veya öngörülemeyen, tarafların kontrolleri dışında gelişen, ortaya çıkmasıyla taraflardan birinin ya da her ikisinin de sözleşme ile yüklendikleri borç ve sorumluluklarını kısmen ya da tamamen yerine getirmelerini ya da bunları zamanında yerine getirmelerini olanaksızlaştıran durumlar, mücbir sebep (Doğal afet, savaş, terör, ayaklanma, değişen mevzuat hükümleri, el koyma veya grev vb.) olarak kabul edilecektir. Mücbir sebep şahsında gerçekleşen taraf, diğer tarafa durumu derhal yazılı olarak bildirecektir.
 Mücbir sebebin devamı esnasında tarafların edimlerini yerine getirememelerinden dolayı herhangi bir sorumlulukları doğmayacaktır. İşbu mücbir sebep durumu 30 (otuz) gün süreyle devam ederse, taraflardan her birinin, tek taraflı olarak fesih hakkı doğmuş olacaktır.
 <br/><br/>
 MADDE 5 – SATICININ HAK VE YÜKÜMLÜLÜKLERİ
 <br/>
 <br/>5.1. Satıcı, 4077 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik hükümleri uyarınca sözleşmede kendisine yüklenen edimleri mücbir haller dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder.
 <br/>5.2. 18 (onsekiz) yaşından küçük kişiler www.alma-rituals.com adresinden alışveriş yapamaz. Satıcı, Alıcı’nın sözleşmede belirttiği yaşının doğru olduğunu esas alacaktır ancak Alıcı’nın yaşını yanlış yazmasından dolayı Satıcı’ya hiçbir şekilde sorumluluk yüklenemeyecektir.
 <br/>5.3. Sistem hatalarından meydana gelen fiyat yanlışlıklarından www.alma-rituals.com sorumlu değildir. Buna istinaden Satıcı, internet sitesindeki sistemden veya yasadışı yollarla internet sitesine yapılabilecek müdahaleler sebebiyle ortaya çıkabilecek tanıtım, fiyat hatalarından sorumlu değildir. Sistem hatalarına dayalı olarak Alıcı, Satıcı’dan hak iddiasında bulunamaz.
 <br/>5.4. www.alma-rituals.com adresinden kredi kartı (Visa, MasterCard vs.) ya da banka havalesi ile alışveriş yapılabilir. Sipariş tarihinden itibaren bir hafta içinde havalesi yapılmayan siparişler iptal edilir. Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’nin) banka hesaplarına ulaştığı belirlenen andır. Ödemeli gönderi ya da posta çeki gibi müşteri hizmetleri ile görüşülmeden gerçekleştirilen ödeme yöntemleri kabul edilemez.
 <br/><br/>MADDE 6 – ALICININ HAK VE YÜKÜMLÜLÜKLERİ
 <br/><br/>6.1. Alıcı, sözleşmede kendisine yüklenen edimleri mücbir sebepler dışında eksiksiz yerine getirmeyi kabul ve taahhüt eder.
 <br/>6.2. Alıcı, sipariş vermekle birlikte iş sözleşme hükümlerini kabul etmiş sayıldığını ve sözleşmede belirtilen ödeme şekline uygun ödemeyi yapacağını kabul ve taahhüt eder.
 <br/>6.3. Alıcı, www.alma-rituals.com internet sitesinden satıcının isim, unvan, açık adres, telefon ve diğer erişim bilgileri, satışa konu ürünün temel nitelikleri, vergiler dâhil olmak üzere satış fiyatı, ödeme şekli, teslimat koşulları ve masrafları gibi satışa konu mal ile ilgili tüm ön bilgiler ve “cayma” hakkının kullanılması ve bu hakkın nasıl kullanılacağı, şikâyet ve itirazlarını iletebilecekleri resmi makamlar konusunda açık, anlaşılır ve internet ortamına uygun şekilde bilgi sahibi olduğunu bu ön bilgileri elektronik ortamda teyit ettiğini kabul ve beyan eder.
 <br/>6.4. Bir önceki maddeye bağlı olarak Alıcı, ürün sipariş ve ödeme koşullarının, ürün kullanım talimatlarının, olası durumlara karşı alınan tedbirlerin ve yapılan uyarıların olduğu www.alma-rituals.com sipariş/ödeme/kullanım prosedürü bilgilerini okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.
 <br/>6.5. Alıcı, aldığı ürünü iade etmek istemesi durumunda ne surette olursa olsun ürüne ve ambalajına zarar vermemeyi, iade anında fatura aslını ve irsaliyesini iade etmeyi kabul ve taahhüt eder.
 <br/><br/>MADDE 7 – ÜRÜN İADE VE CAYMA HAKKINA İLİŞKİN PROSEDÜR
 <br/><br/>ALICI Ürün'ü teslim aldığı tarihten itibaren ondört (14) gün içinde herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin bu Sözleşme'den cayma hakkına sahiptir. Ancak kanunen şu mal/hizmetlere ilişkin sözleşmelerde, kullanılmamış/istifade edilmemiş olsa dahi, cayma hakkı bulunmamaktadır:
 Alıcı'nın özel istekleri veya onun kişisel ihtiyaçları doğrultusunda hazırlanan mallar (üzerinde değişiklik ya da ilaveler yapılarak kişiye/kişisel ihtiyaçlara özel hale getirilenler, Alıcı, siparişine istinaden yurt içinden veya dışından ithal/temin edilen özel Ürünler dahil)
 Kozmetik vb.leri ile çikolata vb. gıda maddeleri gibi çabuk bozulabilen veya son kullanma tarihi geçebilecek ürünler
 Yine kozmetik, mayo, iç giyim mamülleri vb. teslimden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış ve iadesi sağlık-hijyen açısından uygun olmayan mallar
 Teslimden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallar
 Ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış kitap, CD, DVD, ses ve görüntü kayıtları, yazılımlar vb. dijital içerikli her türlü ürünler ile bilgisayar sarf malzemeleri; vi) elektronik ortamda anında ifa edilen tüm hizmetler ve tüketiciye anında teslim edilen her türlü gayri maddi mallar
 Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve satıcı/sağlayıcının kontrolünde olmayan mal veya hizmetler,
 Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlar
 Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesi hizmeti
 Alıcı onayı ile cayma hakkı süresi içinde ifasına başlanan hizmetler ve
 Genel olarak ilgili mevzuat uyarınca mesafeli satış kapsamı dışında kabul edilen diğer mal-hizmetler ile Alıcı'nın ticari/mesleki amaçla satın alma yaptığı haller.
 Cayma hakkı kullanımı mümkün olan hallerde, Alıcı, cayma süresi içinde malı, işleyişine, teknik özelliklerine ve kullanım talimatlarına uygun bir şekilde kullanmadığı takdirde meydana gelen değişiklik ve bozulmalardan kanun gereği sorumludur. Buna göre, cayma tarihine kadarki süreçte Ürün'ün kullanım talimatlarına, teknik özelliklerine ve işleyişine uygun bir şekilde kullanılmamasından ötürü değişiklik veya bozulma olursa Alıcı cayma hakkını kaybedebilir; Satıcı tarafından kabul edildiği hallerde, iade edilecek Ürün bedelinden değişiklik / bozulma kadar indirim yapılır. Cayma hakkı bulunan hallerde Alıcı'nın cayma hakkını kullandığına dair açık bir bildirimi yasal 14 günlük süre içinde Satıcı'ya yöneltmiş (yukarıda belirtilen iletişim adreslerine sözlü/yazılı iletmiş) olması yeterlidir. Söz konusu hakkın süresi içerisinde kullanılması durumunda, Ürün'ün azami on (10) gün içerisinde, giderleri Alıcı'ya ait olmak üzere Satıcı'nın yukarıdaki adresine gönderilmesi zorunludur. İnternet Sitesi'nde ürün iadeleri için anlaşmalı kargo firması belirtilmiş ise, Alıcı Ürün'ü bulunduğu İlçe dâhilindeki veya haricindeki bir şubesinden gönderebilir, bu takdirde Alıcı'dan masraf alınmaz. Bu iade işleminde Ürün'ün kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir. Ayrıca vergi mevzuatı gereği, Alıcı tarafından kanunen İade Faturası kesilmesi gereken hallerin yanı sıra Ürünle beraber iade edilecek olan fatura üzerinde, aşağıda belirtilen, iade ile ilgili bölüm doldurulup imzalanacaktır. Faturası kurumlar (tüzel kişiler) adına düzenlenen sipariş iadeleri, İade Faturası kesilmediği takdirde kabul edilmeyecektir). "Ürünün iade edileceği adres, Satıcı adresi / iade için teslim olunan kargo firması adresi."
 Yukarıdaki belirtilen gereklerin Alıcı tarafından yerine getirilmesi kaydı ile cayma bildiriminin Satıcı'ya ulaştığı tarihten itibaren 14 gün içinde, Ürün bedeli ve varsa Ürün'ün Alıcı'ya teslim masrafları Alıcı'ya, Ürün'ü satın alırken kullandığı ödeme aracına uygun bir şekilde iade edilir.
 <br/><br/>MADDE 8 – SİPARİŞ/ÖDEME PROSEDÜRÜ
 <br/><br/>8.1. – SİPARİŞ<br/>
 Alışveriş sepetine eklenen ürünlerin KDV dahil TL tutarı (Taksitli işlemlerde toplam taksit tutarları) Alıcı tarafından onaylandıktan sonra, ilgili banka kartının posu üzerinden işleme alınır. Bu nedenle siparişler, sevk edilmeden
 önce müşteriye sipariş onay maili gönderilir. Sipariş Onay maili gönderilmeden sevkiyat yapılmaz.
 Süreçteki herhangi bir aksama durumu ya da kredi kartı ile ilgili ortaya çıkabilecek problemler alıcıya sözleşmede belirttiği telefon/faks/e-mail yollarından biri veya bir kaçı kullanılmak sureti ile bildirilir. Gerekirse alıcıdan bankası ile görüşmesi istenebilir. Siparişlerin işleme alınma zamanı, siparişin verildiği an değil, kredi kartı hesabından gerekli tahsilatın yapıldığı ya da havalenin (EFT’nin) satıcı hesaplarına ulaştığının belirlendiği andır.
 İstisnai olarak haklı bir nedenle sözleşme konusu malın tedarik edilemeyeceğinin anlaşılması ve/veya stok problemi ile karşılaşılması durumunda alıcı hemen açık ve anlaşılır bir şekilde bilgilendirilip onay vermesi durumunda alıcıya eşit kalitede ve fiyatta başka bir mal gönderilebilir ya da alıcının arzusu ve seçimi doğrultusunda; yeni başka bir ürün gönderilebilir, ürünün stoklara girmesi ya da teslime engel diğer engelin ortadan kalkması beklenebilir ve/veya sipariş iptal edilebilir.
 Sözleşme konusu malın teslim yükümlülüğünün yerine getirilmesinin imkânsızlaştığı hâllerde alıcı bu durumdan haberdar edilerek ödemiş olduğu toplam bedel ve varsa onu borç altına sokan her türlü belge en geç on gün içinde kendisine iade edilerek sözleşme iptal edilir. Böyle bir durumda alıcının satıcıdan ilave herhangi bir maddi ve manevi zarar talebi olmayacaktır.
 <br/><br/> 8.2. ÖDEME<br/>
 www.alma-rituals.com internet sitesinde, internet ortamında kredi kartı bilgilerini kullanmak istemeyen alıcılara nakit havale ile sipariş imkanları sunulmuştur. Havale ile ödemede alıcı kendisine en uygun bankayı seçip havalesini yapabilir. Eğer EFT yapılmışsa hesaba geçme tarihi dikkate alınacaktır. Havale ve/veya EFT yaparken “Gönderen Bilgileri”nin Fatura Bilgileri ile aynı olması ve sipariş numarasının yazılması gereklidir.
 Ürünün tesliminden sonra Alıcı’ya ait kredi kartının Alıcı’nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşun ürün bedelini Satıcı’ya ödememesi halinde, Alıcı’nın kendisine teslim edilmiş ürünü 10 gün içinde Satıcı’ya göndermesi zorunludur. Bu tür durumlarda nakliye giderleri Alıcı’ya aittir.
 Alıcı kredi kartı ile ödeme yapmayı tercih etmiş ise ALICI, ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve ALICI arasındaki “Kredi Kartı Sözleşmesi” kapsamında uygulanacağını kabul, beyan ve taahhüt eder.
 <br/><br/>
 MADDE 9 – SEVKİYAT/TESLİMAT PROSEDÜRÜ
 <br/><br/>Ürün/ürünler satıcının anlaşmalı olduğu kargo ile alıcının adresine teslim edilecektir. Teslimat süresi, Sipariş onayı mailinin gönderilmesinden ve sözleşmenin kurulmasından itibaren 30 gündür. Alıcıya önceden yazılı olarak veya bir sürekli veri taşıyıcısıyla bildirilmek koşuluyla bu süre en fazla on gün uzatılabilir.
 Ürünler, Kargo şirketlerinin adres teslimatı yapmadığı bölgelere telefon ihbarlı olarak gönderilir.
 Kargo Şirketinin haftada bir gün teslimat yaptığı bölgelerde, sevk bilgilerindeki yanlışlık ve eksiklik olduğu hallerde, bazı sosyal olaylar ve doğal afetler gibi durumlarda belirtilen gün süresinde sarkma olabilir. Bu sarkmalardan dolayı alıcı satıcıya herhangi bir sorumluluk yükleyemez. Ürün, Alıcı’dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden, sevk bilgilerindeki yanlışlık ve/veya Alıcının yerinde olmamasından doğabilecek ekstra kargo bedellerinden satıcı sorumlu değildir. Belirtilen günler içeriğinde ürün/ürünler müşteriye ulaşmadıysa teslimat problemleri senakarahasann@gmail.com e-posta adresi kullanılmak sureti ile derhal bildirilmelidir.
 Zarar görmüş paket durumunda; Zarar görmüş paketler teslim alınmayarak Kargo Şirketi yetkilisine tutanak tutturulmalıdır. Eğer Kargo Şirketi yetkilisi paketin hasarlı olmadığı görüşünde ise, paketin orada açılarak ürünlerin hasarsız teslim edildiğini kontrol ettirme ve durumun yine bir tutanakla tespit edilmesini isteme hakkı alıcıda vardır. Paket Alıcı tarafından teslim alındıktan sonra Kargo Şirketinin görevini tam olarak yaptığı kabul edilmiş olur. Paket kabul edilmemiş ve tutanak tutulmuş ise, durum, tutanağın Alıcı’da kalan kopyasıyla birlikte en kısa zamanda satıcı Müşteri Hizmetlerine bildirilmelidir.
  
 <br/><br/>MADDE 10 - GÜVENLİK, GİZLİLİK, KİŞİSEL BİLGİLER, ELEKTRONİK İLETİŞİMLER VE FİKRİ-SINAİ HAKLAR İLE İLGİLİ KURALLAR<br/><br/>
 İnternet Sitesi'nde bilgilerin korunması, gizliliği, işlenmesi-kullanımı ve iletişimler ile diğer hususlarda aşağıda cari esasları belirtilen gizlilik kuralları-politikası ve şartlar geçerlidir.
 <br/>10.1. Alıcı tarafından İnternet Sitesi'nde girilen bilgilerin ve işlemlerin güvenliği için gerekli önlemler, Satıcı tarafındaki sistem altyapısında, bilgi ve işlemin mahiyetine göre günümüz teknik imkânları ölçüsünde alınmıştır. Bununla beraber, söz konusu bilgiler Alıcı cihazından girildiğinden Alıcı tarafında korunmaları ve ilgisiz kişilerce erişilememesi için, virüs ve benzeri zararlı uygulamalara ilişkin olanlar dâhil, gerekli tedbirlerin alınması sorumluluğu Alıcı'ya aittir.
 <br/>10.2. Alıcı Satıcı'ya belirtilen iletişim kanallarından ulaşarak veri kullanımı-işlenmelerini ve/veya iletişimleri her zaman için durdurabilir. Alıcı'nın bu husustaki açık bildirimine göre, kişisel veri işlemleri ve/veya tarafına iletişimler yasal azami süre içinde durdurulur; ayrıca dilerse, hukuken muhafazası gerekenler ve/veya mümkün olanlar haricindeki bilgileri, veri kayıt sisteminden silinir ya da kimliği belli olmayacak biçimde anonim hale getirilir. Alıcı isterse kişisel verilerinin işlenmesi ile ilgili işlemler, aktarıldığı kişiler, eksik veya yanlış olması halinde düzeltilmesi, düzeltilen bilgilerin ilgili üçüncü kişilere bildirilmesi, verilerin silinmesi veya yok edilmesi, otomatik sistemler ile analiz edilmesi sureti ile kendisi aleyhine bir sonucun ortaya çıkmasına itiraz, verilerin kanuna aykırı olarak işlenmesi sebebi ile zarara uğrama halinde giderilmesi gibi konularda Satıcı'ya her zaman yukarıdaki iletişim kanallarından başvurabilir ve bilgi alabilir. Bu hususlardaki başvuru ve talepleri yasal azami süreler içinde yerine getirilecek yahut hukuki gerekçesi tarafına açıklanarak kabul edilmeyebilecektir.
 <br/> 10.3. İnternet Sitesi'ne ait her türlü bilgi ve içerik ile bunların düzenlenmesi, revizyonu ve kısmen/tamamen kullanımı konusunda; Satıcı'nın anlaşmasına göre diğer üçüncü şahıslara ait olanlar hariç; tüm fikri-sınai haklar ve mülkiyet hakları Satıcı'ya aittir.
 <br/>10.4. Satıcı yukarıdaki konularda gerekli görebileceği her türlü değişikliği yapma hakkını saklı tutar; bu değişiklikler Satıcı tarafından İnternet Sitesi'nden veya diğer uygun yöntemler ile duyurulduğu andan itibaren geçerli olur.
 <br/>10.5. İnternet Sitesi'nden ulaşılan diğer sitelerde kendilerine ait gizlilik-güvenlik politikaları ve kullanım şartları geçerlidir, oluşabilecek ihtilaflar ile menfi neticelerinden Satıcı sorumlu değildir.
 <br/><br/>MADDE 11 – GENEL HÜKÜMLER<br/>
 <br/>11.1. Sözleşme konusu Ürün, yasal 30 günlük süre aşılmamak koşulu ile Alıcı'ya veya İnternet Sitesi'nde gösterdiği adresteki üçüncü kişi/kuruluşa aşağıda belirtilen esaslarda teslim edilir. Satıcı, Ürünler'i, gönderileri için anlaşmalı kargo firması aracılığı ile göndermekte ve teslim ettirmektedir. Bu kargo firmasının Alıcı'nın bulunduğu yerde şubesi olmaması halinde, Alıcı'nın Ürünü, kargo firmasının, Satıcı tarafından bildirilen bir diğer yakın şubesinden teslim alması gerekmektedir. Stoklarda olan Ürünler, sipariş tarihinden itibaren, en geç üç (3) iş günü içerisinde kargoya teslim edilir. Ancak, aynı sipariş içinde kampanyalı Ürün'ler var ise kampanyanın sona ermesi beklenir ve sonrasında en geç 3 (üç) iş günü içinde, sipariş konusu bütün Ürün'ler Tüketici tarafından sipariş esnasında bildirilen kişi ve adrese teslim edilmek üzere kargo firmasına verilir. Kargo Firmaları, Satıcı'dan teslim aldığı gönderileri, uzaklıklarına göre değişmekle birlikte normal şartlar altında ortalama 3 (üç) iş günü içerisinde Alıcı'lara ulaştırmaktadır.
 <br/>11.2 Genel olarak ve aksi açıkça belirtilmediği sürece teslimat masrafları (kargo ücreti vb.) Alıcı'ya aittir. Satıcı, satış anında yürüttüğü ve İnternet Sitesi'nde şartlarını ilan ettiği kampanyalara bağlı olarak söz konusu teslimat masraflarının tamamını ya da bir kısmını Alıcı'ya yansıtmayabilir.
 <br/>11.3 Ürünlerin teslimatı anında Alıcı'nın adresinde bizatihi bulunmaması ve adresteki kişilerin teslimatı kabul etmemesi durumunda Satıcı, bu husustaki edimini yerine getirmiş kabul edilecektir. Adreste teslim alacak bir kimsenin olmaması durumunda kargo firması ile temas kurarak ürünlerin sevkiyatını takip etmek Alıcı'nın sorumluluğundadır. Ürün, Alıcı'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun adresinde bulunmaması veya teslimatı kabul etmemesinden Satıcı sorumlu tutulamaz. Bu hallerde Alıcı'nın Ürün'ü geç teslim almasından kaynaklanan her türlü zarar ile Ürün'ün kargo şirketinde beklemiş olması ve/veya kargonun Satıcı'ya iade edilmesinden dolayı oluşan giderler de Alıcı'ya aittir.
 <br/>11.4. Alıcı, Ürün'ü teslim aldığı anda kontrol etmekle ve Ürün'de kargodan kaynaklanan bir sorun gördüğünde, Ürün'ü kabul etmemek ve Kargo firması yetkilisine tutanak tutturmakla sorumludur. Aksi halde Satıcı sorumluluk kabul etmeyecektir.
 <br/>11.5. Alıcı'nın, Satıcı tarafından aksi yazılı öngörülmemiş ise, Ürün'ü teslim almadan önce bedelini tamamen ödemiş olması gerekir. Peşin satışlarda teslimattan önce Ürün bedeli Satıcı'ya tamamen ödenmediği, taksitli satışlarda vadesi gelen taksit tutarı tediye edilmediği takdirde Satıcı, tek taraflı olarak sözleşmeyi iptal edebilir ve Ürün'ü teslim etmeyebilir. Ürün teslimatı sonrasında herhangi bir sebepten dolayı, işlem yapılan kredi kartının ait olduğu Banka/finansman kurumunun Ürün bedelini Satıcı'ya ödememesi ya da ödediği bedeli geri talep etmesi halinde, Ürün en geç 3 gün içinde Alıcı tarafından Satıcı'ya iade edilir. Ürün bedelinin ödenmemesi Alıcı'nın bir kusurundan veya ihmalin kaynaklanıyor ise kargo masrafları Alıcı tarafından karşılanacaktır. Satıcı'nın iadeyi kabul etmeksizin Ürün bedeli alacağını takip dâhil diğer tüm akdi-kanuni hakları ayrıca ve her halükarda saklıdır. Tereddüte mahal vermemek bakımından; Alıcı'nın satış bedelini, bankalardan (finansman kuruluşları dâhil) sahip olduğu kredi kartı, taksit kart vb. ile ödediği hallerde, bu kartların sağladığı tüm imkanlar doğrudan kartı veren kuruluşça sağlanmış kredi ve/veya taksitli ödeme imkanlarıdır; bu çerçevede gerçekleşen ve Satıcı'nın bedelini defaten veya peyder pey tahsil ettiği Ürün satışları işbu Sözleşme'nin tarafları yönünden kredili veya taksitli satış değildir, peşin satıştır. Satıcı'nın kanunen taksitle satış sayılan hallerdeki yasal hakları (taksitlerin ödenmemesi halinde sözleşmeyi fesih ve/veya kalan borcun tümünün temerrüt faizi ile birlikte ödenmesini talep hakları dahil) ilgili mevzuat çerçevesinde mevcut ve saklıdır. Alıcı'nın temerrüdü durumunda yürürlükteki kanunların öngördüğü şekilde aylık oranda temerrüt faizi tatbik edilir.
 
 <br/>11.6. Ürün'ün normal satış/teslimat koşulları dışında olağanüstü durumlar (hava muhalefeti, yoğun trafik, deprem, sel, yangın gibi) nedeni ile yasal azami 30 günlük süre zarfında teslim edilememesi söz konusu ise, Satıcı teslimat ile ilgili olarak Alıcı'yı bilgilendirir. Bu durumda Alıcı siparişi iptal edebilir, benzer bir ürün sipariş edebilir veya olağanüstü durum sonuna kadar bekleyebilir.
 <br/>11.7. Satıcı Sözleşme konusu Ürün'ü tedarik edemeyeceğinin anlaşılması halinde, bu durumu öğrendiği tarihten itibaren üç (3) gün içinde açık bir şekilde Alıcı'yı kanuna uygun yöntemle bilgilendirmek ve sözlü/yazılı onayını almak kaydıyla, eşit kalite-fiyatta başka bir mal/hizmeti tedarik edebilir ve Sözleşme konusu taahhüdünü bu suretle yerine getirmiş sayılır. Alıcı söz konusu onayı verip vermemekte her bakımdan serbesttir ve onay vermediği hallerde sipariş iptaline (Sözleşme feshine) ilişkin akdi-kanuni hükümler uygulanır.
 <br/>11.8. Sözleşme-kanuna uygun caymalar dâhil sipariş iptallerinde ve Sözleşme fesihlerinde, Ürün bedeli tahsil edilmiş ise, azami 14 gün içinde Alıcı'ya iade edilir. Aşağıdaki kuralın gerekleri saklıdır. İade Alıcı'nın Satıcı'ya Ürün bedeli tediyesinde kullandığı ödeme aracına uygun bir şekilde yapılır. Örneğin Kredi kartlı ödemelerde iade işlemi de Alıcı kredi kartına iade sureti ile yapılır ve Ürün tutarı, siparişin Alıcı tarafından iptal edilmesinden sonra aynı sürede ilgili bankaya iade edilir; bu tutarın bankaya iadesi işleminin Satıcı tarafından yapılmasında sonra Alıcı hesaplarına yansıması tamamen banka işlem süreci ile ilgili olduğundan, Alıcı olası gecikmeler için Satıcı'nın herhangi bir şekilde müdahalesi ve sorumluluk üstlenmesinin mümkün olamayacağını şimdiden kabul eder (bankaların iadeyi Alıcı hesabına yansıtma işlemleri genellikle üç haftayı bulabilmektedir). Satıcı'nın iade edilecek bedel için, bu Sözleşme ve kanundan doğan mahsup, indirim ve tenzilat hakları vardır ve saklıdır. Sözleşme'nin Satıcı'nın edimini ifa etmemesinden ötürü Alıcı tarafından feshedildiği hallere ilişkin Alıcı kanuni hakları da saklı ve mevcuttur.
 <br/>11.9. Alıcı Ürün ve satışla ilgili talep ve şikâyetlerini, Sözleşme'nin giriş kısmındaki Satıcı iletişim kanallarından ulaşarak, sözlü veya yazılı şekilde Satıcı'ya bildirebilir.
 <br/>11.10. Yukarıda 3. maddede yazılı hususların bir kısmı mahiyeti itibari ile bu Sözleşme'de yer almayabilir; bununla beraber, Alıcı tarafından İnternet Sitesi'nde görülen/onaylanan Ön Bilgilendirmelerde, keza İnternet Sitesi'nin --ilgisine göre satış aşamaları veya genel-- bilgilendirme sayfaları/bölümlerinde yer almaktadırlar.
 <br/>11.11. Alıcı, kabulü sonrasında bildirdiği e-posta (mail) adresine gönderildiklerinden, söz konusu Bilgilendirmelere ve bu Sözleşme'ye, anılan maili cihazına kaydedip saklayarak her zaman ulaşabilir ve inceleyebilir. Öte yandan, Satıcı nezdindeki sistemlerde üç yıl süre ile muhafaza edilir.
 <br/>11.12.  Bu Sözleşme'den ve/veya uygulanmasından doğabilecek her türlü uyuşmazlığın çözümünde Satıcı kayıtları (bilgisayar-ses kayıtları gibi manyetik ortamdaki kayıtlar dâhil) delil oluşturur; tarafların ilgili emredici yasal düzenlemelerden bu hususta doğan hakları geçerli ve saklıdır.
 <br/><br/>MADDE 12 – ALICI’NIN KANUNİ BAŞVURU YOLLARI – YETKİLİ YARGI MERCİLERİ<br/><br/>
 Bu Sözleşme'den doğabilecek uyuşmazlıklarda, Gümrük ve Ticaret Bakanlığı'nca yasa gereği her yıl belirlenen-ilan
 edilen parasal sınırlar dâhilinde İl ve İlçe Tüketici Hakem Heyetleri, bu sınırları aşan durumlarda Tüketici Mahkemeleri görevli-yetkilidir. Alıcı, bu çerçevede, kendisinin veya dilerse Satıcı'nın yerleşim yerindeki (ikametgâhındaki) Hakem Heyetleri ve Tüketici Mahkemeleri'ne başvurabilir. Alıcı, işbu Sözleşme'de ve ayrılmaz parçasını oluşturan sipariş-sözleşme ön bilgilendirmelerinde (İnternet Sitesi'nde) yazılı tüm koşulları ve açıklamaları okuduğunu, satışa konu Ürün/Ürün'lerin temel özellik-nitelikleri, satış fiyatı, ödeme şekli, teslimat koşulları, Satıcı ve satışa konu Ürün ile ilgili diğer tüm ön bilgiler-bilgilendirmeler ve cayma hakkı ile kişisel bilgiler-elektronik iletişim dâhil bu Sözleşme'nin yazılı bütün maddelerinde bilgi sahibi olduğunu, tamamını İnternet Sitesi'nde elektronik ortamda gördüğünü ve yine tüm bunlara elektronik ortamda teyit-onay-kabul-iznini vererek Ürün'ü sipariş ile işbu Sözleşme hükümlerini kabul ettiğini kabul ve beyan eder. Gerek söz konusu ön bilgilendirmeler, gerek bu Sözleşme, Alıcı'nın Satıcı'ya bildirdiği yukarıdaki elektronik posta (mail) adresine de gönderilmekte, anılan elektronik postada siparişin alındığı teyidi sipariş özeti ile birlikte ayrıca yer almaktadır.
 Siparişin gerçekleşmesi durumunda Alıcı işbu Sözleşmenin tüm koşullarını kabul etmiş sayılır.


    </div>
    </div>
  )
}
