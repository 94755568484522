import { useEffect, useState } from "react";
import axios from "axios";
import "./edit-single-product.css";
import { API, Amplify, Storage } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Spinner from "../pages/checkout/Spinner";
import Navbar from "../components/Navbar";


Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d", //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: "almarituals143254-dev", //REQUIRED -  Amazon S3 bucket name
      region: "us-east-1", // REQUIRED - Amazon Cognito Region
    },
  },
});

function EditSingle() {
  const location = useLocation();
  const [product, setProduct] = useState([location.state.product]);

  const [lowResImages, setLowResImages] = useState([]);
  const [highResImages, setHighResImages] = useState([]);

  const [isUploadInProgress, setIsUploadInProgress] = useState(false);


  const navigate = useNavigate();


  useEffect(() => {
    setHighResImages(product[0].high_res_images[0]);
    setLowResImages(product[0].images[0]);
    console.log(product[0]);


    
  }, []);

  useEffect(() => {

    
  }, [lowResImages, highResImages]);

  useEffect(() => {}, [isUploadInProgress]);


  const uploadLowResImages = async () => {

    var lowResImageKeyArray= [];
    console.log("uploada lowResImages girdim" + lowResImageKeyArray);

    const lowResImages = document.querySelector(
      'input[name="lowResImages"]'
    ).files;
    console.log(lowResImages);

    const uploadPromises = [];

    for (let i = 0; i < lowResImages.length; i++) {
      const lowResFile = lowResImages[i];
      const uploadPromise = Storage.put(lowResFile.name, lowResFile, {
        contentType: lowResFile.type,
      }).then((res) => {
        lowResImageKeyArray.push(
            `https://d27wok9y6t3pi.cloudfront.net/public/${res.key}`
          );
      });
      uploadPromises.push(uploadPromise);
    }
    await Promise.all(uploadPromises);


    setLowResImages(lowResImageKeyArray);
  };

  const uploadHighResImages = async () => {
    console.log("uploada highResImages girdim");

    var highResImageKeyArray= [];

    const highResImages = document.querySelector(
      'input[name="highResImages"]'
    ).files;
    console.log(highResImages);

    const uploadPromises = [];

    for (let i = 0; i < highResImages.length; i++) {
      const highResFile = highResImages[i];
      const uploadPromise = Storage.put(
        "high" + highResFile.name,
        highResFile,
        {
          contentType: highResFile.type,
        }
      ).then((res) => {
        console.log(res);
        console.log(res.key);
       
      });
      uploadPromises.push(uploadPromise);
    }

    await Promise.all(uploadPromises);


    for (let i = 0; i < highResImages.length; i++) {
      highResImageKeyArray.push(
        `https://d27wok9y6t3pi.cloudfront.net/public/high${highResImages[i].name}`


      );
    }


    console.log(highResImageKeyArray);

   

    setHighResImages(highResImageKeyArray);


  };

  const submit = async (values) => {
    console.log("hello" +values.lowResImages);
    setIsUploadInProgress(true);

    await uploadLowResImages(values.lowResImages);
    await uploadHighResImages(values.highResImages);

    const isImageListEmpty = (values.lowResImages == undefined && values.lowResImages == undefined) 
    || (values.lowResImages != undefined && values.lowResImages == undefined) || (values.lowResImages == undefined && values.lowResImages != undefined)

    console.log(values.lowResImages == undefined);

    var myInit;

    myInit = {
      body: {
        productName: values.productName,
        collectionName: values.collectionName,
        productType: values.productType,
        color: values.color,
        colorName: values.colorName,
        explanation: values.explanation,
        careInstructions: values.careInstructions,
        material: values.material,
        priceTry: values.priceInTRY,
        priceEur: values.priceInEUR,
        currency: "TRY",
        stock: values.stock,
        price: values.priceInTRY,
        lowResImageKeyArray: lowResImages,
        highResImageKeyArray: highResImages
      }
    }
    if((values.lowResImages !== undefined) && (values.lowResImages.length === lowResImages.length && values.lowResImages.every((value, index) => value === lowResImages[index]))) {
      myInit = {
        ...myInit,
        lowResImageKeyArray: lowResImages
      }
    }

    if(values.highResImages !== undefined && (values.highResImages.length === highResImages.length && values.highResImages.every((value, index) => value === highResImages[index]))) {
      myInit = {
        ...myInit,
        highResImageKeyArray: highResImages
      } 
    }

    //undefined olmayabilir da
    

   
      


    console.log(myInit);

    API.put(
      "almaAppApi",
      `/api/images/${product[0]._id}`,
      myInit 
    ).then((result) => {
      console.log(result);
      console.log("product is updated");

      // window.location.reload();
      navigate("/edit");
    });
  };

  const validationSchema = Yup.object().shape({
    collectionName: Yup.string().required("collectionName NAME IS REQUIRED"),
    productType: Yup.string().required("productType NAME IS REQUIRED"),
    productName: Yup.string().required("productName NUMBER IS REQUIRED"),
    colorName: Yup.string().required("colorName IS REQUİRED"),
    color: Yup.string().required("color IS REQUIRED"),
    explanation: Yup.string().required("explanation IS REQUIRED"),
    careInstructions: Yup.string().required("careInstructions IS REQUIRED"),
    material: Yup.string().required("material IS REQUIRED"),
    priceInTRY: Yup.string().required("priceInTRY IS REQUIRED"),
    priceInEUR: Yup.string().required("priceInEUR IS REQUIRED"),
    stock: Yup.string().required("stock IS REQUIRED"),
    // lowResImages: Yup.string().required("lowResImages IS REQUIRED"),
    // highResImages: Yup.string().required("highResImages IS REQUIRED"),

  });

  return (
    <div>
      <Navbar></Navbar>
      <div className="edit-single-form">
        <Formik
          initialValues={{
            collectionName: product[0].collection_name,
            productName: product[0].name,
            productType: product[0].product_type,
            color: product[0].color,
            colorName: product[0].color_name,
            explanation: product[0].explanation,
            material: product[0].material,
            priceInTRY: product[0].price_try,
            priceInEUR: product[0].price_eur,
            careInstructions: product[0].care_instructions,
            stock: product[0].stock,
          }}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({ values }) => (
            <Form>
              <Field
                name="collectionName"
                type="text"
                placeholder="COLLECTION NAME"
              />
              <Field
                name="productType"
                type="text"
                placeholder="PRODUCT TYPE"
              />
              <Field
                name="productName"
                type="text"
                placeholder="PRODUCT NAME"
              />
              <Field
                name="colorName"
                type="text"
                placeholder="COLOR NAME (white,black etc..)"
              />
              <Field name="color" type="text" placeholder="COLOR HEX VALUE" />
              <Field name="explanation" type="text" placeholder="EXPLANATION" />
              <Field
                name="careInstructions"
                type="text"
                placeholder="CARE INSTRUCTIONS"
              />
              <Field name="material" type="text" placeholder="MATERIAL" />
              <Field
                name="priceInTRY"
                type="number"
                placeholder="PRICE (TRY)"
              />
              <Field
                name="priceInEUR"
                type="number"
                placeholder="PRICE (EUR)"
              />
              <Field name="stock" type="number" placeholder="STOCK" />

              <Grid container xl={1} lg= {1} md={1} sm={1} xs={2} margin="30px auto">
                {lowResImages.map((imageKey) => (
                  <Grid key={imageKey} item md={12}>
                    <img
                      className="edit-product-image-small"
                      src={imageKey}
                    ></img>
                  </Grid>
                ))}
              </Grid>

              <Grid
                container
                xl={12}
                md={6}
                direction="row"
                columnGap={2}
                margin="auto"
              >
                {highResImages.map((imageKey) => (
                  <Grid key={imageKey} xl={2} lg= {2} md={2} sm={1} xs={2}  item>
                    <img
                      className="edit-product-image-small"
                      src={imageKey}
                    ></img>
                  </Grid>
                ))}
              </Grid>

              <label>Upload low resolution images:</label>
              <Field name="lowResImages" type="file" multiple onChange={uploadLowResImages} />

              <label>Upload high resolution images:</label>
              <Field name="highResImages" type="file" multiple onChange={uploadHighResImages}/>

              <button
                    disabled={isUploadInProgress}
                className="account-black-button"
                type="submit"
              >
                {isUploadInProgress ? <Spinner></Spinner> : "UPLOAD"}
              </button>
            </Form>
          )}
        </Formik>
      </div>

    </div>
  );
}

export default EditSingle;
