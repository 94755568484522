import React, { useState, useEffect, useRef } from "react";
import "./homepage.css";
import l_homepage from "./fullPage/1200px-homepage.png";
import { Formik, Form, Field } from "formik";
import {API,Amplify, Storage} from 'aws-amplify';
import {IoCloseOutline} from 'react-icons/io5'
import { Link } from 'react-router-dom'
import Spinner from "../checkout/Spinner";


import xl_homepage from "./fullPage/1920px-homepage.png"
import m_homepage from "./fullPage/992px-homepage.png"
import sm_homepage from "./fullPage/768px-homepage.png"
import xs_homepage from "./fullPage/600px-homepage.png"

import popup_image from "./popup-image.png"

import { HeaderHome } from "../../components/headerHome/HeaderHome";

import Navbar from "../../components/Navbar";
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { Divider } from "@mui/material";






export default function Home() {
 
  // if pop up shown before dont open it again
  const [isOpen, setIsOpen] = useState(localStorage.getItem('isPopupShown') ? false : true);

  const [showSearchFromHome,setShowSearchFromHome] = useState(sessionStorage.getItem("showSearch"));
  
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [secondProduct, setSecondProduct] = useState(null);

  const divRef = useRef(null);
  const containerRef = useRef(null);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
  

  useEffect(() => {

  }, [showSearchFromHome]);

  useEffect(() => {

  }, [isSubmitInProgress]);

  useEffect(() => {

    
    // add event listener to document object
    document.addEventListener("mousedown", handleClickOutside);


    // if pop up shown before dont open it again 
    localStorage.setItem('isPopupShown', 'true');


    // remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchProduct = () => {
    API.get("almaAppApi", `/api/product/643fd2c1f3903525e343a22e`, {})
      .then((result) => {

        console.log("result geldi");
        var pro = result;
        console.log(result[0]);

        setProduct(result[0]);

      return result[0];
      })
      .catch(err => {
        console.log("err geldi");
        console.log(err);
      });
      
  }

  const fetchProductWithHRImages = async () => {
    console.log("hello from the outside")
      const result = await API.get("almaAppApi", `/api/product/643fd2c1f3903525e343a22e`, {})
      .then((result) => {console.log("myresulttttt");

      console.log(result);


        navigate("/shopSingle",{state:{productWithHRImages:result[0], product:result[0]}});
        
      });
  };

  const fetchSecondProductWithHRImages = async () => {
    console.log("hello from the outside")
      const result = await API.get("almaAppApi", `/api/product/643fd910594c54fdcd9e0dde`, {})
      .then((result) => {console.log("myresulttttt");

      console.log(result);


        navigate("/shopSingle",{state:{productWithHRImages:result[0], product:result[0]}});
        
      });
  };


  const fetchSecondProduct = () => {
    API.get("almaAppApi", `/api/product/63b5a423e815763388b93600`, {})
      .then((result) => {

        console.log("result geldi");
        var pro = result[0];
        console.log(pro.images);

        setSecondProduct(result[0]);

      return result[0].images;
      })
      .catch(err => {
        console.log("err geldi");
        console.log(err);
      });
      
  }

  function handleClickOutside(event) {
    console.log("clicked");
    console.log(containerRef.current.contains(event.target));

    if (containerRef.current.contains(event.target)) {
      setIsOpen(false);
      console.log("close");
    }
  }

  function removePopup() {
      setIsOpen(false);
  }

  const submit = async (values) => {

    setIsSubmitInProgress(true);
    console.log(values);

        const myInit = {
          body: {
            email:values.email
          },
        };



        API.post("almaAppApi", "/api/send-newsletter-sub-w-discount", myInit).then((response) => {
          console.log(response);
          setIsSubmitInProgress(true);
          removePopup();
        });
  }


  const handleEnterPressed = (e) => {
    if (e.key === 'Enter') {
      navigate('../shop',{state:{name:e.target.value}});

      console.log("enter pressed");;
    }
  }

  const resetInput = (e) => {
    e.target.value = '';
  }




  return (
    
  <div className="my-container">
    
    <div  ref={containerRef} className= {isOpen ? "home-page-container-blur" : "" }>
          <Navbar showSearchFromHome= {showSearchFromHome} setShowSearchFromHome={setShowSearchFromHome}></Navbar>
          <HeaderHome></HeaderHome>
          {showSearchFromHome && 
          <div className='search-bar'>
            <input placeholder="SEARCH" onClick={e => resetInput(e)} onKeyUp={e => handleEnterPressed(e)}>
            </input>
            <Divider></Divider>
          </div>
        }
          <img className='xl-homepage' src={xl_homepage}></img>
          <img className='l-homepage' src={l_homepage}></img>
          <img className='m-homepage' src={m_homepage}></img>
          <img className='s-homepage' src={sm_homepage}></img>
          <img className='xs-homepage' src={xs_homepage}></img>

          {/* <Link key={`mykey`} to="/shopSingle"  state= {{imagesSrc: product === null ? fetchProduct() : product.images[0], productId:"6364e09a776ad5b6e6ce5fd0"  }}>
            <div className='my-clickable'></div>
          </Link>

          <Link key={`mykey2`} to="/shopSingle"  state= {{imagesSrc: secondProduct === null ? fetchSecondProduct() : secondProduct.images[0], productId:"63b5a423e815763388b93600"  }}>
            <div className='my-clickable-second'></div>
          </Link> */}



          <Link key={`mykey`} onClick={fetchProductWithHRImages}>
              <div className='first-clickable'></div>
          </Link>

          <Link key={`mykey2`} onClick={fetchSecondProductWithHRImages}>
              <div className='second-clickable'></div>
          </Link>

          
    </div>

    {isOpen && (
      <div className="popup" ref={divRef}>
        <div className="popup-text">
        <IoCloseOutline className="pop-up-close-icon"  onClick={() => removePopup()}>
                        </IoCloseOutline>
          <p> SIGN UP AND GET 10% OFF <br></br> ON YOUR FIRST ORDER! </p>

          <Formik
                    initialValues={{ 
                    }}
                    onSubmit={submit}
                >
                    {({ values }) => (
                        <Form>
                        <Field className="popup-input" name="email" type="mail" placeholder="YOUR E-MAIL"  />
                        <button disabled={isSubmitInProgress}
                        type="submit">
                          {isSubmitInProgress  ? <Spinner></Spinner> : "SIGN UP"}
                            </button>
                        </Form>
                    )}
                </Formik>
        </div>
          <img className="popup-img" src ={popup_image}></img>

      </div>
    )}
</div>
  );
}
