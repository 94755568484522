import React from 'react'
import './HeaderHome.css';
import image from './slogan.png'

export const HeaderHome = () => {
  return (
    <div className='wrapper'>
      <img className='headerHome' src={image}></img>
    </div>
  )
}
