import React, { useState, useEffect }  from 'react'
import './upload-financial-values.css';
import {API,Amplify, Storage} from 'aws-amplify';
import { Formik, Form, Field } from "formik";
import Spinner from '../pages/checkout/Spinner';
import * as Yup from "yup";


Amplify.configure({
  Auth: {
      identityPoolId: 'us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-1', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
      AWSS3: {
          bucket: 'almarituals143254-dev', //REQUIRED -  Amazon S3 bucket name      
          region: 'us-east-1', // REQUIRED - Amazon Cognito Region
      }
  }
});


function UploadFinancialValues() {

  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  useEffect(() => {
  }, [isUploadInProgress]);

  const submit = async (values) => {
    console.log(values);
    setIsUploadInProgress(true);
        const myInit = {
          body: {
            salesTax: values.salesTax,
            shippingPriceTRY: values.shippingPriceTRY,
            shippingPriceEUR: values.shippingPriceEUR,
            discountCode: values.discountCode
          },
        };

        API.put("almaAppApi", "/api/financial-values", myInit).then((response) => {
          console.log(response);
          console.log("done");
          window.location.reload();
        });
  }

  const validationSchema = Yup.object().shape({
     salesTax: Yup.string().required("SALES TAX VALUE IS REQUIRED"),
     shippingPriceTRY: Yup.string().required("SHIPPING PRICE IS REQUIRED"),
     shippingPriceEUR: Yup.string().required("SHIPPING PRICE IS REQUIRED"),
     discountCode: Yup.string().required("DISCOUNT CODE IS REQUIRED"),
  });

  return (
    <div className="upload-financial-values-form">
      <h2>UPDATE FINANCIAL VALUES</h2>
      <Formik
          initialValues={{lowResImages:[]}}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({ values }) => (
            <Form>
              <Field name="salesTax" type="number" placeholder="SALES TAX (Percentage)"  />
              <Field name="shippingPriceTRY" type="number" placeholder="SHIPPING PRICE (TRY)"  />
              <Field name="shippingPriceEUR" type="number" placeholder="SHIPPING PRICE (EUR)"  />

              <Field name="discountCode" type="text" placeholder="DISCOUNT CODE"  />
              <button
                    disabled={isUploadInProgress}
                    className="account-black-button"
                    type="submit"
                    onClick={() => {
                    }}
                  >
                  {isUploadInProgress  ? <Spinner></Spinner> : "UPLOAD"}
               </button>
            </Form>
          )}
        </Formik>

    </div>
  );
}

export default UploadFinancialValues;