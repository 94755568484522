import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../components/Products/useLocalStorage';
import "./Account.css"

export default function Account() {

  const navigate = useNavigate();
  const [token, setToken] = useLocalStorage("token", []);

  useEffect(() => {
    if (token !== null && token.length!==0) {
      navigate('../loggedIn');
    } else {
      navigate('../signIn');
    }
  }, []);

}

