import logo from "./logo.svg";
import React, { useEffect, useState } from "react";
import "./App.css";
import "./pages/homepage/homepage.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";

import Home from "./pages/homepage/home";
import Shop from "./pages/shop";
import Collection from "./pages/collection";
import ShopSingle from "./components/Products/shopSingle";
import Notebook from "./pages/notebook/notebook";
import Account from "./pages/Account";
import ShoppingBag from "./pages/shoppingBag/shoppingBag";
import Upload from "./backoffice/upload";
import Edit from "./backoffice/Edit";
import EditSingle from "./backoffice/EditSingle";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Checkout2 from "./pages/checkout/Checkout2";
import Payment from "./pages/checkout/Payment";
import Shipping from "./pages/checkout/Shipping";
import { HeaderHome } from "./components/headerHome/HeaderHome";

import RefundPolicy from "./pages/policies/RefundPolicy";
import TermsOfService from "./pages/policies/TermsOfService";
import ShippingPolicy from "./pages/policies/ShippingPolicy";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import ContactUs from "./pages/policies/ContactUs";

import useLocalStorage from "./components/Products/useLocalStorage";
import ThreeDsScreen from "./pages/ThreeDsScreen";
import SignIn from "./pages/SignIn";
import LoggedIn from "./pages/LoggedIn";
import ForgotPassword from "./pages/ForgotPassword";
import SignUp from "./pages/SignUp";
import Newsletter from "./pages/policies/Newsletter";
import UploadFinancialValues from "./backoffice/UploadFinancialValues";
import ResetPassword from "./pages/ResetPassword";
import SendMail from "./pages/SendMail";
import Admin from "./backoffice/Admin";
import SendOrderInfoMail from "./backoffice/SendOrderInfoMail";

function App() {
  const [showSearch, setShowSearch] = useLocalStorage("showSearch", false);

  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);

  useEffect(() => {
    console.log("useEffect");
  }, []);

  useEffect(() => {}, [showSearch]);

  function PrivateOutlet() {
    return sessionStorage.getItem("isAdmin") ? (
      <Outlet />
    ) : (
      <Navigate to="/signIn" />
    );
  }

  return (
    <div className="main-div">
      <Router>
        {/* <Navbar showSearch={showSearch} setShowSearch={setShowSearch}></Navbar> */}
        {/* <HeaderHome></HeaderHome> */}
        <Routes>
          {/* <Route path='/' element={<Home showSearch={showSearch} setShowSearch={setShowSearch}/>} /> */}

          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} exact />
          <Route path="/collections" element={<Collection />} exact />

          <Route path="/shopSingle/:id" element={<ShopSingle />} exact />

          <Route path="/notebook" element={<Notebook />} />

          <Route path="/account" element={<Account />} exact />

          <Route path="/signIn" element={<SignIn />} exact />

          <Route path="/admin" element={<PrivateOutlet />}>
            <Route path="" element={<Admin />} />
            <Route path="/admin/upload" element={<Upload />} />
            <Route
              path="/admin/upload-financial-values"
              element={<UploadFinancialValues />}
            />
            <Route path="/admin/edit" element={<Edit />} />
            <Route path="/admin/editSingle" element={<EditSingle />} />
            <Route
              path="/admin/send-order-info"
              element={<SendOrderInfoMail />}
            />
          </Route>

          <Route path="/loggedIn" element={<LoggedIn />} exact />

          <Route path="/forgot-password" element={<ForgotPassword />} exact />

          <Route path="/reset-password" element={<ResetPassword />} exact />

          <Route path="/signUp" element={<SignUp />} exact />

          <Route path="/shoppingBag" element={<ShoppingBag />} />

          <Route
            path="/instagram"
            element="https://www.instagram.com/almarituals_/"
          />

          <Route path="/checkout" element={<Checkout2 />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/termsOfService" element={<TermsOfService />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
          <Route path="/shippingPolicy" element={<ShippingPolicy />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/newsletter" element={<Newsletter />} />

          <Route path="/payment/three-ds-screen" element={<ThreeDsScreen />} />
          <Route path="/send-email" element={<SendMail />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
