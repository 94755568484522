import React, { useEffect, useState } from "react";

import { useNavigate, BrowserRouter as Router } from 'react-router-dom'

import { API, Amplify } from "aws-amplify";

import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";

import "./edit-products.css";
import Navbar from "../components/Navbar";


Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d", //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: "almarituals143254-dev", //REQUIRED -  Amazon S3 bucket name
      region: "us-east-1", // REQUIRED - Amazon Cognito Region
    },
  },
});

export default function Edit() {
  //list all products
  const [productsArray, setProductsArray] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    API.get("almaAppApi", "/api/images/:/:/:/:", {}).then((result) => {
      console.log(result);
      setProductsArray(result);
    });
  });


  const fetchProductWithHRImages = async (productId) => {
    console.log("hello from the outside" + productId)
      await API.get("almaAppApi", `/api/product/${productId}`, {})
      .then((result) => {console.log("myresulttttt");

        navigate("/admin/editSingle",{state:{product:result[0]}});
        
      });
  };

  return (
  <div >
    <Navbar></Navbar>
  <Grid
    container
    columnSpacing={1}
    display="flex"
    alignItems="center"
    justifyContent="center"
    width="90%"
    margin="50px auto"
  >
    {productsArray.map((item) => (
      <Grid item className="edit-product-container" key={item._id} xs={2} sm={1} md={1} lg={1} xl={1} >
          <Link
            item
            key={`${item.name}`}
            onClick={() => fetchProductWithHRImages(item._id)}
          >
            <img className="edit-product-image-small"
              src={`${item.images[0][0]}`}
            ></img>
          </Link>

          <div>
            <div> {item.name} </div>
            <div>{item.product_type} </div>
          </div>

      </Grid>

      

    ))}
  </Grid>

  </div>
  );
};

