import { useState } from "react";

import "./Account.css";

import Grid from "@mui/material/Grid";

import useLocalStorage from "../components/Products/useLocalStorage";

import { API } from "aws-amplify";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import jwt from "jwt-decode";
import Navbar from "../components/Navbar";
import { HeaderHome } from "../components/headerHome/HeaderHome";

export default function SignIn({ onSignIn }) {
  const navigate = useNavigate();

  const [token, setToken] = useLocalStorage("token", []);

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const signIn = (emailError, passwordError) => {

    console.log("sign Innnnn");
    if (emailError) {
      setErrorMessage(emailError);
      return;
    } else if (passwordError) {
      setErrorMessage(passwordError);
      return;
    } else{
      setErrorMessage("");
    }
    
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {

    console.log("handle submit");
    console.log(values.email);

    const loginInfo = {
      body: {
        email: values.email,
        password: values.password,
      },
    };

    console.log(loginInfo);

    API.post("almaAppApi", "/api/login", loginInfo)
      .then((response) => {
        setToken(response.token);
        const user = jwt(response.token);
        if (user.role === "admin") {
          sessionStorage.setItem("isAdmin", "true");
          console.log("admin is here");
          navigate("../admin");

        } else {
          navigate("../",{state:{userName:user.name}});
          window.location.reload();
        }

        setErrorMessage(null);
      })
      .catch(function (error) {
        console.log(error);
        console.log(`error is: ${JSON.stringify(error)}`);
        // console.log(error.response.data.message);
        setErrorMessage(error.response.data.message);
      });
    // perform login action here
    // if login fails, update error message: setErrorMessage('Incorrect email or password');
    setSubmitting(false);
  };

  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
      <div className="form-container">
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="login-form">
              <button
              
                className="login-dont-you-have-an-account"
                onClick={() => {
                  navigate('../signUp');
                }}
              >
                DON'T YOU HAVE AN ACCOUNT? <u className="underline-styling">REGISTER</u>
              </button>

              <Field name="email" type="email" placeholder="EMAIL ADDRESS"  />

              <Field name="password" type="password" placeholder="PASSWORD" />

              <div className="account-error">{errorMessage}</div>
              {/* {errors.email ? <div className="account-error">{errors.email}</div> : null}
            {errors.password  ? <div className="account-error">{errors.password}</div> : null}
   */}
              <Grid
                container
                xl={12}
                marginTop={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                  <button
                    className="account-black-button"
                    type="submit"
                    onClick={() => {
                      signIn(errors.email, errors.password);
                    }}
                  >
                    SIGN IN
                  </button>
                </Grid>

                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                  <div className="shopping-bag-sum-small-space"></div>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                  <button className="account-grey-button"
                    onClick={() => {
                      // forgotPassword();

                       navigate('../forgot-Password');

                    }}>
                    FORGOT YOUR PASSWORD
                  </button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
