import React from 'react'
import './contactUs.css'
import left from "./contact-us-palm-left.png"
import right from "./contact-us-palm-right.png"
import { Grid } from '@mui/material'
import { HeaderHome } from '../../components/headerHome/HeaderHome'

import Navbar from '../../components/Navbar'


export default function ContactUs() {
  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <div className='contact-us'>
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} >
          <Grid item xl={3} lg={3} sm={3} md={3} >
              <img className='contact-us-image' src ={left}></img>
          </Grid>
          <Grid item container xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="3%" paddingRight="3%">
    
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="contact-us-info">
              <div className='contact-us-title'>CONTACT US</div>
              <div className='contact-us-small-space'></div>
              <div>INQUIRIES</div>
              <div>INFO@ALMA-RITUALS.COM</div>
              <div className='contact-us-small-space'></div>
              <div>PHONE</div>
              <div>+90 (537) 867 65 12</div>
              <div className='contact-us-small-space'></div>
              <div>ADDRESS</div>
              <div>BEBEK MAH. KUCUK BEBEK CAD. DEMET APT. NO 28/6 </div>
              <div>BESIKTAS, ISTANBUL 34342</div>
              <div className='contact-us-small-space'></div>
            </Grid>

            <Grid item lg={5.75} md={5.75} sm={12} xs={12}>
              <input className='contact-us-name-input' placeholder='NAME'></input>
            </Grid>

            <Grid item lg={0.5} md={0.5} xs={12}>
            </Grid>

            <Grid item lg={5.75} md={5.75} xs={12}>
              <input className='contact-us-email-input' placeholder='EMAIL'></input>
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
              <input className='contact-us-message-input' placeholder='MESSAGE'></input>
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
              <button className='contact-us-black-sign-up-button'>SEND</button>
            </Grid>
            
          </Grid>
          
          <Grid item xl={3} lg={3} sm={3} md={3}>
              <img className='contact-us-image' src= {right} ></img>
          </Grid>
        </Grid>
    </div>
    </div>
  )
}
