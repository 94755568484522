import React, { useState, useEffect }  from 'react'
import './uploadProduct.css';
import {API,Amplify, Storage} from 'aws-amplify';
import { Formik, Form, Field } from "formik";
import Spinner from '../pages/checkout/Spinner';
import * as Yup from "yup";
import Navbar from '../components/Navbar';


Amplify.configure({
  Auth: {
      identityPoolId: 'us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-1', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
      AWSS3: {
          bucket: 'almarituals143254-dev', //REQUIRED -  Amazon S3 bucket name      
          region: 'us-east-1', // REQUIRED - Amazon Cognito Region
      }
  }
});

function Upload() {

  const [files, setFiles] = useState([]);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  var lowResImageKeyArray = [];
  var highResImageKeyArray = [];

  useEffect(() => {
  }, [isUploadInProgress]);

  const uploadLowResImages = async () => {
    console.log("uploada lowResImages girdim");


    const lowResImages = document.querySelector('input[name="lowResImages"]').files;
    console.log(lowResImages);

    const uploadPromises = [];

  for(let i = 0; i<lowResImages.length; i++){
    const lowResFile = lowResImages[i];
    const uploadPromise = Storage.put("high"+lowResFile.name, lowResFile, {
      contentType: lowResFile.type
      }).then(res => {
        if (!lowResImageKeyArray.includes(`https://d27wok9y6t3pi.cloudfront.net/public/${res.key}`)) {
          lowResImageKeyArray.push(`https://d27wok9y6t3pi.cloudfront.net/public/${res.key}`);
        }
    });
    uploadPromises.push(uploadPromise);
  }
  await Promise.all(uploadPromises);
  }

  const uploadHighResImages = async () => {
    console.log("uploada highResImages girdim");


    const highResImages = document.querySelector('input[name="highResImages"]').files;
    console.log(highResImages);

    const uploadPromises = [];

  for(let i = 0; i<highResImages.length; i++){
    const highResFile = highResImages[i];
    const uploadPromise = Storage.put("high"+highResFile.name, highResFile, {
      contentType: highResFile.type
    }).then(res => {
      console.log(res);
      console.log(res.key);
      // if (!highResImageKeyArray.includes(`https://d27wok9y6t3pi.cloudfront.net/public/${res.key}`)) {
      //   highResImageKeyArray.push(`https://d27wok9y6t3pi.cloudfront.net/public/${res.key}`);
      // }
    });
    uploadPromises.push(uploadPromise);
  }

  await Promise.all(uploadPromises);

  for (let i = 0; i < highResImages.length; i++) {
    highResImageKeyArray.push(
      `https://d27wok9y6t3pi.cloudfront.net/public/high${highResImages[i].name}`

    );
  }

    console.log(highResImageKeyArray);

    console.log("done highResImageKeyArray");
    
  }

  const submit = async (values) => {
    console.log(values);
    console.log("hello");

    setIsUploadInProgress(true);


    await uploadLowResImages();
    await uploadHighResImages();

        const myInit = {
          body: {
            productName: values.productName,
            collectionName: values.collectionName,
            productType: values.productType,
            color: values.color,
            colorName: values.colorName,
            explanation: values.explanation,
            careInstructions: values.careInstructions,
            material: values.material,
            priceTry: values.priceInTRY,
            priceEur: values.priceInEUR,
            currency: "TRY",
            stock: values.stock,
            price: values.priceInTRY,
            lowResImageKeyArray: lowResImageKeyArray,
            highResImageKeyArray: highResImageKeyArray,
          },
        };

        API.post("almaAppApi", "/api/images", myInit).then((response) => {
          console.log(response);
          console.log("done");
          window.location.reload();
        });
  }

  const fileSelected = event => {
    console.log(event.target.files);
		setFiles([...event.target.files]);

    console.log(files);
	}

  const validationSchema = Yup.object().shape({
    collectionName: Yup.string().required("collectionName NAME IS REQUIRED"),
    productType: Yup.string().required("productType NAME IS REQUIRED"),
    productName: Yup.string().required("productName NUMBER IS REQUIRED"),
    colorName: Yup.string().required("colorName IS REQUİRED"),
    color: Yup.string().required("color IS REQUIRED"),
    explanation: Yup.string().required("explanation IS REQUIRED"),
    careInstructions: Yup.string().required("careInstructions IS REQUIRED"),
    material: Yup.string().required("material IS REQUIRED"),
    priceInTRY: Yup.string().required("priceInTRY IS REQUIRED"),
    priceInEUR: Yup.string().required("priceInEUR IS REQUIRED"),
    stock: Yup.string().required("stock IS REQUIRED"),
    lowResImages: Yup.string().required("lowResImages IS REQUIRED"),
    highResImages: Yup.string().required("highResImages IS REQUIRED"),
  
  
  });

  return (<div>
    <Navbar></Navbar>
    <div className="create">
      <h2>ADD A PRODUCT</h2>

      <Formik
          initialValues={{lowResImages:[]}}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {({ values }) => (


            <Form>
              <Field name="collectionName" type="text" placeholder="COLLECTION NAME"  />
              <Field name="productType" type="text" placeholder="PRODUCT TYPE"  />
              <Field name="productName" type="text" placeholder="PRODUCT NAME"  />
              <Field name="colorName" type="text" placeholder="COLOR NAME (white,black etc..)"  />
              <Field name="color" type="text" placeholder="COLOR HEX VALUE"  />
              <Field name="explanation" type="text" placeholder="EXPLANATION"  />
              <Field name="careInstructions" type="text" placeholder="CARE INSTRUCTIONS"  />
              <Field name="material" type="text" placeholder="MATERIAL"  />
              <Field name="priceInTRY" type="number" placeholder="PRICE (TRY)"  />
              <Field name="priceInEUR" type="number" placeholder="PRICE (EUR)"  />
              <Field name="stock" type="number" placeholder="STOCK"  />

              <label>Upload low resolution images:</label>
              <Field name="lowResImages" type="file" multiple/>

              <label>Upload high resolution images:</label>
              <Field name="highResImages" type="file" multiple/>


              <button
                    disabled={isUploadInProgress}
                    className="account-black-button"
                    type="submit"
                    onClick={() => {
                    }}
                  >
                  {isUploadInProgress  ? <Spinner></Spinner> : "UPLOAD"}

                  </button>

              






            </Form>

          )}
        </Formik>

    </div>

  </div>
  );
}

export default Upload;