import React from 'react'
import './contactUs.css'
import left from "./shipping-palm-left.png"
import right from "./shipping-palm-right.png"
import { Grid } from '@mui/material'
import { HeaderHome } from '../../components/headerHome/HeaderHome'

import Navbar from '../../components/Navbar'


export default function ShippingPolicy() {
  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <div className='contact-us'>
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} >
          <Grid item xl={3} lg={3} sm={3} md={3} >
              <img className='contact-us-image' src ={left}></img>
          </Grid>
          <Grid item container xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="3%" paddingRight="3%">
    
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="shipping-policy-info">

            
              <div>ONCE WE HAVE RECEIVED YOUR PAYMENT, WE WILL SEND YOUR ORDER CONFIRMATION VIA E-MAIL.<br></br>

PRE-ORDER ITEMS WILL BE PRODUCED AFTER YOUR PURCHASE AND WILL BE READY TO SHIP IN THE ESTIMATED DELIVERY TIME AS STATED ON THE PRODUCT DETAIL PAGE.<br></br><br></br>

THE ITEMS THAT ARE IN STOCK WILL BE PROCESSED AND SHIPPED WITHIN 2-5 BUSINESS DAYS.<br></br><br></br>

FOR DOMESTIC ORDERS, OUR DELIVERY TAKES 1-2 BUSINESS DAYS.<br></br><br></br>

IF YOU HAVE PROBLEMS WITH TRACKING YOUR ORDER, PLEASE CONTACT OUR CUSTOMER SERVICE TEAM VIA OUR SUPPORT E-MAIL: INFO@ALMA-RITUALS.COM<br></br><br></br>

ORDERS ARE NOT SHIPPED OR DELIVERED ON WEEKENDS OR HOLIDAYS.</div>
            </Grid>

          </Grid>
          
          <Grid item xl={3} lg={3} sm={3} md={3}>
              <img className='contact-us-image' src= {right} ></img>
          </Grid>
        </Grid>
    </div>
    </div>
  )
}
