import React, { useState, useEffect }  from 'react'
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CheckoutShoppingBagItems from '../../components/checkoutComponents/CheckoutShoppingBagItems';
import ShoppingBagSum from '../../components/shoppingBagComponents/ShoppingBagSum';
import useLocalStorage from '../../components/Products/useLocalStorage';

import  '../../components/shippingComponents/shipping.css';
import "./payment.css"


import { Link,useLocation, BrowserRouter as Router } from 'react-router-dom'
import axios from "axios";
import { useNavigate } from 'react-router-dom'; 


import {API,Amplify, Storage} from 'aws-amplify';

import pay_with_iyzico from './pay_with_iyzico_colored.png'
import logo_band from './logo_band_colored@1X.png'
import card from "./credit-cart-img.png"
import carpi from "./carpi.png"

import {IoCloseOutline} from 'react-icons/io5'
import Spinner from './Spinner';
import jwt from "jwt-decode";
import Navbar from '../../components/Navbar';
import { HeaderHome } from '../../components/headerHome/HeaderHome';

import { Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from "yup";


export default function Payment() {

  const location = useLocation();
  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);


  const [token, setToken] = useLocalStorage("token", []);
  const [tempToken, setTempToken] = useLocalStorage("tempToken", []);
  const [isCardInfoReset, setCardInfoReset] = useLocalStorage(false);



  const [emailInputEnabled, setEmailInputEnabled] = useState(false);
  const [addressInputEnabled, setAddressInputEnabled] = useState(false);

  const [emailInputBorderActive, setEmailInputBorderActive] = useState(false);
  const [addressInputBorderActive, setAddressInputBorderActive] = useState(false);
  
  const [userEmail, setUserEmail] = useState(location.state.userEmail);
  const [userAddress, setUserAddress] = useState(location.state.userAddress);
  const [userFirstName, setUserFirstName] = useState(location.state.userFirstName);
  const [userLastName, setUserLastName] = useState(location.state.userLastName);
  const [userPhoneNumber, setUserPhoneNumber] = useState(location.state.userPhoneNumber);
  const [userCountry, setUserCountry] = useState(location.state.userCountry);
  const [userPostalCode, setUserPostalCode] = useState(location.state.userPostalCode);
  const [userCity, setUserCity] = useState(location.state.userCity);
  const [userApartmentSuite, setUserApartmentSuite] = useState(location.state.userApartmentSuite);

  const [cardInfoValidationError, setCardinfoValidationError] = useState("");

  const [billingInfo, setBillingInfo] = useState({
    billingCity: '',
    billingCountry: '',
    billingAddress: '',
    billingApartmentSuite: '',
    billingPostalCode: ''
  });

  const [isProceed, setIsProceed] = useState(false);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const [cartId, setCartId] = useState("");

  const [isUpdatingStock, setIsUpdatingStock] = useState(false);
  const [isMaxStock, setIsMaxStock] = useState(false);


  const [sameAsShippingAddress, setSameAsShippingAddress] = useState(true);

  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expireMonth, setExpireMonth] = useState("");
  const [expireYear, setExpireYear] = useState("");
  const [cvc, setCvc] = useState("");

  const [isShippingMethodFree, setShippingMethodFree] = useState(false);
  const [shippingPriceTRY, setShippingPriceTRY] = useLocalStorage("shippingPriceTry", []);
  const [shippingPriceEUR, setShippingPriceEUR] = useState("");




  const navigate = useNavigate();

  useEffect(() => {

    let sum =  cartItems.reduce(function(prev, current) {
      return(prev + +current.price);
    }, 0);

    if (sum >= 600) {
      setShippingMethodFree(true);
    } else {

      setShippingMethodFree(false);
    }
      
  }, [cartItems]);

  useEffect(() => {
    console.log("lala");
      
     }, [isUpdatingStock,isMaxStock]);
  useEffect(() => {
    if (cartId) {
      console.log("cart id set")

      const user = jwt((token !== null && token.length!==0) ? token : tempToken);

      console.log( "user address with regenerated token is:" +  user.address);

      console.log( "user address secondLine with regenerated token is:" +  user.apartmentSuite);

    console.log("cart id is set, payment is about to happen");
      const data = {
        body:{
          cardHolderName:cardHolderName,
          cardNumber:cardNumber,
          expireMonth:expireMonth,
          expireYear:expireYear,
          cvc:cvc,
          billingInfo:billingInfo,
          discountApplied: localStorage.getItem("discountCode") !== null 

        },
        headers:{
          Authorization:(token !== null && token.length!==0) ? token : tempToken
        }
    }

    console.log("data" + data);

    API.post("almaAppApi", `/api/threeds/payments/${cartId}/with-new-card`, data)
    .then(response => {


      console.log(response.errorCode);

      if (response.status === "failure") {

        setIsPaymentInProgress(false);

        // if (response.errorCode === "12") {
        //   setCardinfoValidationError("CARD NUMBER IS NOT VALID. RELOADING ...");
        // } else if (response.errorCode === "14") {
        //   setCardinfoValidationError("EXPIRY YEAR IS NOT VALID. RELOADING ...");
        // } else if (response.errorCode === "6001") {
        //   setCardinfoValidationError("CARD INFO IS NOT VALID. RELOADING ...");
        // }

        setCardinfoValidationError("CARD INFO IS NOT VALID. RELOADING ...");


        console.log(response.errorCode);
        window.setTimeout(() =>window.location.reload(),3000);


                // cardHolderName: "CANSIN BAYIR",
                // cardNumber: "4987493491379521",
                // expireMonth: "01",
                // expireYear:"2024", 
                // cvc: "199",
                // country: ""
      } else {
          navigate("three-ds-screen",{  state:{html:response, cartId:cartId, cartItems:cartItems}});  
      }
    })
    }

  }, [cartId]);

  useEffect(() => {

    if(token !== null && token.length!==0 && isProceed) {
      //update user
      const updatedUserInfo = {
        body: {
          email:userEmail,
          country: userCountry,
          firstName: userFirstName, 
          lastName: userLastName , 
          address: userAddress,
          apartmentSuite: userApartmentSuite,
          postalCode: userPostalCode,
          city: userCity,
          phoneNumber:userPhoneNumber
        },
      };

      const userEmailInfo = {
        body: {
          email:userEmail
        },
      };

      let regeneratedToken;
  
      API.put("almaAppApi", `/api/update-user`, updatedUserInfo)
        .then((result) => {
          console.log("update user result : " + result);
          console.log(userEmailInfo);
          API.post("almaAppApi", "/api/regenerate-token", userEmailInfo).then((result) => {
            regeneratedToken= (result.token);

            
            //CART INSERT
            const productIds = [];
            cartItems.map((item) => {
              for (let i = 0; i < item.qty; i++) {
                productIds.push(item._id);
              }
            });
            const data = {
              body:{
                productIds:productIds,
              },
              headers:{
              Authorization: regeneratedToken 
              }
            }
            API.post("almaAppApi", `/api/new-cart`, data)
            .then(response => {
              setCartId(response);
              setToken(regeneratedToken);
              console.log(response);
            }).catch((err) => {
              console.log("err is" + err);
            });
          }).catch((err) => {
            console.log("err is" + err);
          });
        });



        
    } else if( isProceed) {
      console.log("proceeed TEMPTOKEN EXIST");

      //new user
      createUserWithoutRegister();
    }
  }, [isProceed]);

  useEffect(() => {

    console.log("NO ERRRORS FROM FIRST TRY");


    if(tempToken !== null && tempToken.length!==0 && isProceed) {
        console.log("temptoken is set, proceed is true expected");
        console.log("temptoken is" + tempToken);
        console.log("isproceed is" + isProceed);


        //CART INSERT
        const productIds = [];
        cartItems.map((item) => {
          for (let i = 0; i < item.qty; i++) {
            productIds.push(item._id);
          }
        });
        const data = {
          body:{
            productIds:productIds,
          },
          headers:{
          Authorization: tempToken 
          }
      }
      API.post("almaAppApi", `/api/new-cart`, data)
      .then(response => {
        console.log("new-cart response" + response)
        setCartId(response);
      })
    } 
  }, [tempToken]);

  useEffect(() => {

    console.log("FROM CARD INFO RESET");

    if(tempToken !== null && tempToken.length!==0 && isProceed) {
        console.log("temptoken is set, proceed is true expected");
        console.log("temptoken is" + tempToken);
        console.log("isproceed is" + isProceed);


        //CART INSERT
        const productIds = [];
        cartItems.map((item) => {
          for (let i = 0; i < item.qty; i++) {
            productIds.push(item._id);
          }
        });
        const data = {
          body:{
            productIds:productIds,
          },
          headers:{
          Authorization: tempToken 
          }
      }
      API.post("almaAppApi", `/api/new-cart`, data)
      .then(response => {
        console.log("new-cart response" + response)
        setCartId(response);
      })
    } 
  }, [isCardInfoReset]);

  async function checkIfEmailExists(email) {
    console.log(email);
      try {

        const response = await API.get("almaAppApi", `/api/checkout-email-exist/${email}`);
        console.log(response); // assuming the response contains the boolean value
        return response;
      } catch (error) {
        console.log(error);
        console.log(`error is ${JSON.stringify(error)}`);
        console.log(error.response.data.message);
        return false;
      }
    
  }


  const createUserWithoutRegister = async () => {

    const isUserRegistered = await checkIfEmailExists(userEmail);
    console.log(isUserRegistered);

    if (isUserRegistered === false) { 
      
      console.log("Notregistered");
      
    const registerInfo = {
      body: {
        firstName: userFirstName,
        lastName: userLastName,
        phoneNumber: userPhoneNumber,
        country: userCountry,
        city: userCity,
        postalCode: userPostalCode,
        apartmentSuite:userApartmentSuite,
        address:userAddress,
        email: userEmail,
        password: "dfgdfggd",
        isRegistered: false}, 
    };
    
    API.post("almaAppApi", "/api/register", registerInfo)
        .then(response => {
          console.log("user creation başarılı");
          console.log(response);
          console.log(response.userJson);
          console.log(response.token);

          setTempToken(response.token);
        })
        .catch(function (error) {
          console.log(error.message)
        });
    } else {
      console.log("registered");
      setCardInfoReset(true);
     
    }


   
      
  };

  const onRemoveCloseIcon = async (product) => {
    console.log(product.qty);

    if(!isUpdatingStock){
      setIsUpdatingStock(true);

      const exist = cartItems.find((x) => x._id === product._id);
      console.log(exist);
        if (exist) {

          try {
            // const result = await API.put(
            //   "almaAppApi",
            //   `/api/product-stock-update-increase-by-count/${exist._id}/${exist.qty}`,
            //   {}
            // );
            setCartItems(cartItems.filter((x) => x._id !== product._id));

          } catch (error) {
            console.log(error);
          }
        } else {
          // setCartItems(
          //   cartItems.map((x) =>
          //     x._id === product._id ? { ...exist, qty: 0 } : x
          //   )
          // );
        }
      setIsUpdatingStock(false);
    }
  }

  // when + icon clicked, number of products increase by one, stock decrease by one
  const onAdd = async (product) => {
    const exist = cartItems.find((x) => x._id === product._id);
    if (exist && !isUpdatingStock) {

      if (exist.stock == 1) {
        console.log("out of stock")
        setIsMaxStock(true);
      } else {
        setIsMaxStock(false);
      }
      setIsUpdatingStock(true);
      console.log("updatinggggg")

      try {
        // const result = await API.put(
        //   "almaAppApi",
        //   `/api/product-stock-update-decrease/${exist._id}`,
        //   {}
        // );
        setCartItems(
          cartItems.map((x) => 
          x._id === product._id ? {
            ...exist,
            qty: exist.qty + 1,
            stock: exist.stock - 1,
            price: exist.price + exist.originalPrice,
          } : x)
        );
      } catch (error) {
        console.log(error);
      }

      setIsUpdatingStock(false);

    } 
  };


        // when - icon clicked, number of products decreased by one, stock increase by one
        const onRemove = async (product) => {

          const exist = cartItems.find((x) => x._id === product._id);
          if(!isUpdatingStock) {
            setIsUpdatingStock(true);
            try {
              if (exist.qty === 1 ) {
                console.log("qty 11111");
                // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${exist._id}`, {});
                setCartItems(cartItems.filter((x) => x._id !== exist._id));
    
              } else {
                // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${product._id}`, {});
                setCartItems(
                  cartItems.map((x) =>
                    x._id === product._id ? { ...exist, qty: exist.qty - 1 , stock: exist.stock + 1,  price: exist.price - exist.originalPrice} : x
                  )
                );
              }
            } catch (error) {
              
            }
            if (exist.stock > -1) {
              setIsMaxStock(false);
            } else {
              setIsMaxStock(true);
            }
            setIsUpdatingStock(false);
          }
    
        };

        const handleSubmit = (values) => {
          setIsProceed(true);
          setIsPaymentInProgress(true);
          
          setCardHolderName(values.cardHolderName);
          setCardNumber(values.cardNumber);
          setExpireMonth(values.expireMonth);
          setExpireYear(values.expireYear);
          setCvc(values.cvc);

          setBillingInfo({
            billingCity: values.billingCity,
            billingCountry: values.billingCountry,
            billingAddress: values.billingAddress,
            billingApartmentSuite: values.billingApartmentSuite,
            billingPostalCode: values.billingPostalCode
          });
      
        };

        // const validationSchema = Yup.object().shape({

        //   // country: Yup.string().when('sameAsShippingAddress', (sameAsShippingAddress, schema) => {
        //   //   return sameAsShippingAddress === false
        //   //     ? schema.required('Country is required')
        //   //     : schema;
        //   // }),
        //   // address:Yup.string().required("address IS REQUIRED"),
        //   // apartmentSuite:Yup.string().required("apartmentSuite IS REQUIRED"),
        //   // postalCode:Yup.string().required("postalCode IS REQUIRED"),
        //   // city:Yup.string().required("city IS REQUIRED"),

        //   cardHolderName: Yup.string().required("CARD HOLDER NAME IS REQUIRED"),
        //   cardNumber: Yup.string().required("CARD NUMBER IS REQUIRED")
        //   ,
        //   expireMonth: Yup.string().required("EXPIRE MONTH IS REQUIRED")
        //   ,
        //   expireYear: Yup.string().required("EXPIRE YEAR IS REQUİRED")
        //   ,
        //   cvc: Yup.string().required("CVC IS REQUIRED")
        //   ,
        // });

        // const validationSchema = Yup.object().shape({
        //   cardHolderName: Yup.string().required('Card Holder Name is required'),
        //   expiryYear: Yup.string()
        //     .required('Expiry Year is required')
        //     .matches(/^[0-9]{4}$/, 'Must be exactly 4 digits'),
        //   expiryMonth: Yup.string()
        //     .required('Expiry Month is required')
        //     .matches(/^(0[1-9]|1[0-2])$/, 'Must be exactly 2 digits between 01 and 12'),
        //   cardNumber: Yup.string()
        //     .required('Card Number is required')
        //     .matches(/^[0-9]{16}$/, 'Must be exactly 16 digits'),
        //   cvc: Yup.string()
        //     .required('CVC is required')
        //     .matches(/^[0-9]{3}$/, 'Must be exactly 3 digits'),
        // });

        const validateCardHolderName = (value) => {
          let error;
          if (!value) {
            error = 'CARD HOLDER NAME IS REQUIRED';
          }
          return error;
        };
        
        const validateExpiryYear = (value) => {
          let error;
          if (!value) {
            error = 'EXPIRY YEAR IS REQUIRED';
          } else if (!/^[0-9]{4}$/.test(value)) {
            error = 'EXPIRY YEAR MUST BE EXACTLY 4 DIGITS';
          }
          return error;
        };
        
        const validateExpiryMonth = (value) => {
          let error;
          if (!value) {
            error = 'EXPIRY MONTH IS REQUIRED';
          } else if (!/^(0[1-9]|1[0-2])$/.test(value)) {
            error = 'EXPIRY MONTH MUST BE EXACTLY 2 DIGITS BETWEEN 01 AND 12';
          }
          return error;
        };
        
        const validateCardNumber = (value) => {
          let error;
          if (!value) {
            error = 'CARD NUMBER IS REQUIRED';
          } else if (!/^[0-9]{16}$/.test(value)) {
            error = 'CARD NUMBER MUST BE EXACTLY 16 DIGITS';
          }
          return error;
        };
        
        const validateCVC = (value) => {
          let error;
          if (!value) {
            error = 'CVC IS REQUIRED';
          } else if (!/^[0-9]{3}$/.test(value)) {
            error = 'CVC MUST BE EXACTLY 3 DIGITS';
          }
          return error;
        };
        

        const lala = () => {
          return(<div><Grid container xl={12} lg={12} md={12} sm= {12} xs={12}>

            <Grid item>
                <div className='checkout-small-space'></div>
                <div className='checkout-small-space'></div>
                <div>SHIPPING ADDRESS</div>
                <div className='checkout-small-space'></div>
            </Grid>

            <Grid item xl={12} xs={12} >
                <Field className='checkout-large-input' name="billingCountry" type="text" placeholder="COUNTRY" />
                <div className='checkout-x-small-space'></div>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm= {12} xs={12} >
                <Field className='checkout-large-input' name="billingAddress" type="text" placeholder="ADDRESS" />
                <div className='checkout-x-small-space'></div>
                <Field className='checkout-large-input' name="billingApartmentSuite" type="text" placeholder="APARTMENT / SUITE ETC." />
                <div className='checkout-x-small-space'></div>
            </Grid>

            <Grid item container xl={12}>
                <Grid item xl={5.85} lg={5.85} md={5.85}  sm={5.85} xs={12}>
                    <Field className='checkout-small-input' name="billingPostalCode" type="number" placeholder="POSTAL CODE" />
                    <div className='checkout-x-small-space'></div>
                </Grid>
                <Grid item xl={.3} lg={.3} md={.3} sm={.3}  xs={12}></Grid>
                <Grid item xl={5.85} lg={5.85}  md={5.85}  sm={5.85} xs={12}>
                    <Field className='checkout-small-input' name="billingCity" type="text" placeholder="CITY" />
                    <div className='checkout-x-small-space'></div>
                </Grid>
            </Grid>

        </Grid></div>);
        }

  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
      <Grid container xl={11.25} lg={11.25} md={11.25} sm={11.2} xs={11} className="payment-container">
      <Grid item xl={5.9} lg={5.9} md={5.9} sm={6.1} xs={12}>
        <div className="payment-contact-and-ship-to-container">
          <Grid container xl={12} xs={12} rowGap={2} marginBottom={3}>
            <Grid item xl={3} lg={3} md={3} xs={3}>
              CONTACT
            </Grid>
            <Grid item xl={8} lg={7.7} md={7.3} xs={6.9}>
              <input
                className={
                  emailInputBorderActive
                    ? "payment-changable-enabled-email-input"
                    : "payment-changable-disabled-email-input"
                }
                value={userEmail}
                disabled={!emailInputEnabled}
                onChange={(e) => setUserEmail(e.target.value)}
              ></input>
            </Grid>
            <Grid item xl={1} lg={1} md={1} xs={1}>
              <button
                className="change-button"
                disabled={token !== null && token.length!==0}
                onClick={() => {
                  
                  setEmailInputEnabled(true);
                  setEmailInputBorderActive(true);
                }}
              >
                CHANGE
              </button>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container xl={12} xs={12} marginTop={3} marginBottom={3}>
            <Grid item xl={3} lg={3} md={3} xs={3}>
              SHIP TO
            </Grid>
            <Grid item xl={8} lg={7.7} md={7.3} xs={7}>
              <input
                className={
                  addressInputBorderActive
                    ? "payment-changable-enabled-address-input"
                    : "payment-changable-disabled-address-input"
                }
                value={userAddress}
                disabled={!addressInputEnabled}
                onChange={(e) => setUserAddress(e.target.value)}
              ></input>
            </Grid>
            <Grid item xl={1} lg={1} md={1} xs={1}>
              <button
                className="change-button"
                onClick={() => {
                  setAddressInputEnabled(true);
                  setAddressInputBorderActive(true);
                }}
              >
                CHANGE
              </button>{" "}
            </Grid>
          </Grid>

          <Divider></Divider>
          <Grid container xl={12} marginTop={3}>
            <Grid item xl={11.4} lg={11.4} md={11}>
              SHIPPING METHOD
            </Grid>
            <Grid item xl={0.5} lg={0.5} md={0.5}>
            {isShippingMethodFree ? "FREE" : shippingPriceTRY + " TRY"}
            </Grid>
          </Grid>
        </div>
        <div className="spaceLarge"></div>

        <div className="spaceLarge"></div>
        <div>PAYMENT</div>
        <div className="spaceSmall"></div>
        <div>ALL TRANSACTIONS ARE SECURE AND ENCRYPTED.</div>
        <div className="spaceSmall"></div>
        <Formik 
              initialValues={{ 
                // cardHolderName: "CANSIN BAYIR",
                // cardNumber: "4987493491379521",
                // expireMonth: "01",
                // expireYear:"2024", 
                // cvc: "199",
                // country: ""
                cardHolderName: "",
                cardNumber: "",
                expireMonth: "",
                expireYear:"", 
                cvc: "",
                country: ""
              }}

              // validationSchema={validationSchema}
              onSubmit={handleSubmit}>
                {({ errors }) => (
              <Form>
                <div className="payment-iyzico-info-container">
                  <Grid container xl={12} xs={12} rowGap={3}>
                    <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                      IYZICO - CREDIT AND DEBIT CARDS
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <img className="payment-card-image" src={logo_band}></img>
                    </Grid>
                  </Grid>
                  <div className="spaceSmall"></div>

                  <Divider></Divider>

                  <Grid display="flex" justifyContent="center">
                    <img className='payment-card-icon' src={card}></img>

                  </Grid>

                  <div className="spaceSmall"></div>

                  <Grid container xl={12} xs={12}>
                    <Grid item xl={10} lg={10} md={10} xs={12}>
                      AFTER CLICKING "COMPLETE ORDER", YOU WILL BE REDIRECTED TO IYZICO
                      - CREDIT AND DEBIT CARDS TO COMPLETE YOUR PURCHASE SECURELY
                    </Grid>
                  </Grid>
                </div>

        <div className="spaceLarge"></div>
        <div className="spaceLarge"></div>
        <div>BILLING ADDRESS</div>
        <div className="spaceSmall"></div>
        <div>SELECT THE ADDRESS THAT MATCHES YOUR CARD OR PAYMENT METHOD</div>
        <div className="spaceLarge"></div>

        <div className="shipping-contact-and-ship-to-container">
          
        <Grid container xl={12} xs={12} rowGap={2} marginBottom={3}>
            <Grid item xl={1} xs={1}>
              <Field type="radio" checked={sameAsShippingAddress} 
              onClick={() => setSameAsShippingAddress(true)}></Field>
            </Grid>
            <Grid item xl={11} lg={11} md={11} xs={11}>
              SAME AS SHIPPING ADDRESS
            </Grid>
            
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Divider></Divider>
            </Grid>
            
            <Grid item xl={1} xs={1}>
              <Field type="radio"  checked={!sameAsShippingAddress}
              onClick={() => {
                setSameAsShippingAddress(false);
              }}></Field>
            </Grid>
            <Grid item xl={11} lg={11} md={11} xs={11}>
              USE DIFFERENT ADDRESS 
            </Grid>

          
          </Grid>
          


          

          {!sameAsShippingAddress && lala()}
          
         
        </div>

        <Grid container xl={12} lg={12} xs={12} >
          <Grid item xl={12} lg={12} xs={12}>
            <div className="payment-small-space"></div>
            {/* <Divider></Divider> */}
            <Field
              className="payment-large-input"
              type="text"
              name="cardHolderName"
              placeholder='CARD HOLDER NAME'
              validate={validateCardHolderName}
            />
            <ErrorMessage name="cardHolderName" />
            <div className="payment-x-small-space"></div>
          </Grid>

          <Grid item xl={12} xs={12}>
            {/* <Divider></Divider> */}
            <Field
              className="payment-large-input"
              type="number"
              name="cardNumber"
              placeholder='CARD NUMBER'
              validate={validateCardNumber}
              
            />

 <ErrorMessage name="cardNumber" />
            <div className="payment-x-small-space"></div>
          </Grid>
          </Grid>

          <Grid item container xl={12} lg={12} xs={12}>
            <Grid item xl={5.85} lg={5.85} md={5.85} xs={12}>
              {/* <Divider></Divider> */}
              <Field
                className="payment-small-input"
                type="text"
                name="expireMonth"
                placeholder='EXPIRE MONTH (MM)'
                validate={validateExpiryMonth}
              />

<ErrorMessage name="expireMonth" />
              <div className="payment-x-small-space"></div>
            </Grid>

            <Grid item xl={0.3} lg={0.3} md={0.3}></Grid>

            <Grid item xl={5.85} lg={5.85} md={5.85} xs={12}>
              {/* <Divider></Divider> */}
              <Field
                className="payment-small-input"
                type="text"
                name="expireYear"
                placeholder='EXPIRE YEAR (YYYY)'
                validate={validateExpiryYear}
              />
              <ErrorMessage name="expireYear" />
              <div className="payment-x-small-space"></div>
            </Grid>
          </Grid>

          <Grid item container xl={12} lg={12} xs={12}>
            <Grid item xl={5.85} lg={5.85} md={5.85} xs={12}>
              {/* <Divider></Divider> */}
              <Field
                className="payment-small-input"
                type="text"
                name="cvc"
                placeholder="CVC"
                validate={validateCVC}
              />

<ErrorMessage name="cvc" />

              <div className="payment-x-small-space"></div>
            </Grid>
            <Grid item xl={0.3} lg={0.3} md={0.3}></Grid>

            <Grid item xl={5.85} lg={5.85} md={5.85} xs={12}>
              <button disabled={isPaymentInProgress }
                className="payment-proceed-button"
                type="submit"
                // onClick={() => {
                //   setIsProceed(true);
                //   setIsPaymentInProgress(true);
                //   console.log("clicked");
                // }}
              >
                {isPaymentInProgress  ? <Spinner></Spinner> : "PROCEED"}
                
              </button>
            </Grid>

            <div >{cardInfoValidationError}

            </div>

          </Grid>
        </Form>
)}
</Formik>
      </Grid>

      <Grid xl={1.5} lg={1.5} md={1.5} sm={0.5} xs={0}></Grid>

      <Grid item container xl={4.6} lg={4.6} md={4.55} sm={5.4} xs={12}>
        <Grid key="" item xl={12} lg={12} xs={12} md={12} sm={12}>
          {/* <CheckoutShoppingBagItems cartItems={cartItems} setCartItems={setCartItems}></CheckoutShoppingBagItems> */}
          <div className='payment-shopping-bag-container'>
            {cartItems.map((item) => (
              <div>
                <Grid key={item._id} container xl={12} lg={12} xs={12}>
                  <Grid item xl={3} lg={3} md={3} sm={2.5} xs={2.5} display="flex" justifyContent="center" alignItems="flex-start">
                    <img
                      className="payment-basket-image"
                      src={cartItems[cartItems.indexOf(item)].images[0][0]}
                    ></img>
                  </Grid>

                  <Grid item xl={7.5} lg={7.5} md={7.5} sm={7.4} xs={7.9}>
                    <div className="payment-shopping-bag-item-details">
                      <div>{item.name}</div>
                      <div>{item.product_type}</div>
                      <div className="spaceSmall"></div>
                      <div>One Size Fits All</div>
                      <div>Color: Black</div>
                      <div className="spaceSmall"></div>

                      <div className="spaceSmall"></div>
                      <button
                        className="shopping-bag-remove"
                        onClick={() => onRemove(item)}
                        disabled={isUpdatingStock}
                      >
                        -
                      </button>
                      <div className="shopping-bag-item-qty">{item.qty}</div>
                      <button
                        className="shopping-bag-add"
                        onClick={() => onAdd(item)}
                        disabled={isUpdatingStock || isMaxStock || item.stock == 0}
                      >
                        +
                      </button>
                    </div>
                  </Grid>

                  <Grid item container  xl={1.5} lg={1.5} md={1.5} sm={2} xs={1.6} direction="column" >

                          <Grid item container xl={1} lg={1} md={1} sm={1} xs={1}  display="flex" justifyContent="flex-end" >
                            <Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
                              <div className='spaceLarge'></div>
                            </Grid>
                            <Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
                              <img className='payment-carpi' src={carpi} 
                        onClick={() => onRemoveCloseIcon(item)}></img>
                            </Grid>
                            {/* <IoCloseOutline fontSize="23px" style={{color:"grey", height:"100%", width:"100%"}} onClick={() => onRemoveCloseIcon(item)}>
                            </IoCloseOutline> */}
                          </Grid>

                          <Grid item xl={7} lg={7} md={7} sm={3} xs={6}>
                            <div className='spaceLarge'></div>
                          </Grid>

                          <Grid item xl={1} lg={1} md={1} sm={2} xs={2} display="flex" justifyContent="right" alignItems="center">
                            <div >{item.price}{item.currency}</div>
                          </Grid>

                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <div className="spaceSmall"></div>
                    <div className="spaceSmall"></div>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} xs={12}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <div className="spaceLarge"></div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ShoppingBagSum  className='payment-shopping-bag-sum' cartItems={cartItems} setCartItems={setCartItems}></ShoppingBagSum>
        </Grid>
      </Grid>
    </Grid>
    </div>
  );
}
