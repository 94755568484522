import {
  useLocation,
  useNavigate,
  BrowserRouter as Router,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./Products.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { API, Amplify } from "aws-amplify";
import LazyImage from "./LazyImage";

export default function Products(props) {
  const { productsArray } = props;
  const [productWithHRImages, setProductWithHRImages] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // const productImage = productsArray[0].images[0][0];
  useEffect(() => {
    console.log(productWithHRImages);
  }, [productWithHRImages]);

  Amplify.configure({
    Auth: {
      identityPoolId: "us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d", //REQUIRED - Amazon Cognito Identity Pool ID
      region: "us-east-1", // REQUIRED - Amazon Cognito Region
    },
    Storage: {
      AWSS3: {
        bucket: "almarituals143254-dev", //REQUIRED -  Amazon S3 bucket name
        region: "us-east-1", // REQUIRED - Amazon Cognito Region
      },
    },
  });

  return (
    <Grid
      container
      className="products-grid"
      xl={11.56}
      lg={11.56}
      md={11.56}
      sm={11.56}
      xs={11}
      margin="auto"
    >
      {productsArray.map((product) => {
        console.log("Product ID:", product._id);
        console.log("Product stock value:", product.stock);
        console.log("Product stock type:", typeof product.stock);

        if (product.stock === 0) {
          console.log(`Product ${product.name} is out of stock uclu cizgi`);
        } else if (product.stock == 0) {
          console.log(`Product ${product.name} is out of stock esitli`);
        } else {
          console.log(`Product ${product.name} is in stock`);
        }

        return (
          <Grid item key={product._id} xs={12} sm={4} md={3} lg={3} xl={3}>
            <div className="product-item">
              {product.stock === 0 ? (
                <div className="image-container not-clickable">
                  <img
                    className="product-image-small"
                    src={`${product.images[0][0]}`}
                    alt={product.name}
                  />
                  <div className="sold-out-container">
                    <div className="sold-out">SOLD OUT</div>
                  </div>
                </div>
              ) : (
                <Link
                  className="image-container"
                  key={`${product.name}`}
                  to={{
                    pathname: `/shopSingle/${product._id}`,
                  }}
                >
                  <img
                    className="product-image-small"
                    src={`${product.images[0][0]}`}
                    alt={product.name}
                  />
                </Link>
              )}
            </div>

            <div className="products-item-info-container">
              <div>{product.name}</div>
              <div>
                {product.product_type} / {product.price} TRY
              </div>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
