import { useEffect, useState } from 'react';
import "./LoggedIn.css"
import Grid from '@mui/material/Grid';
import jwt from "jwt-decode";
import useLocalStorage from '../components/Products/useLocalStorage';
import { Formik, Form, Field} from 'formik';
import * as Yup from "yup";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Divider } from '@mui/material';

import { HeaderHome } from '../components/headerHome/HeaderHome';
import Navbar from '../components/Navbar';

export default function LoggedIn() {

  const [token, setToken] = useLocalStorage("token", []);
  const [user, setUser] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(token);
    if (token !== null && token.length!==0) {
        setUser(jwt(token));
    }
   }, [token]);

   const signOut = () => {
    setToken(null);
    navigate('../signIn');
    sessionStorage.removeItem("isAdmin");
    window.location.reload();

  };

  //  const validationSchema = Yup.object().shape({
  //   email: Yup.string().email("Invalid email").required("Email is required"),
  //   password: Yup.string().required("Password is required"),
  // });

  const update = (values, { setSubmitting }) => {

    const updatedUserInfo = {
      body: {
        email:user.email,
        country: values.country,
        firstName: values.firstName, 
        lastName: values.lastName , 
        address: values.address,
        apartmentSuite: values.apartmentSuite,
        postalCode: values.postalCode,
        city: values.city,
        phoneNumber:values.phoneNumber
      },
    };

    API.put("almaAppApi", `/api/update-user`, updatedUserInfo)
      .then((result) => {
        console.log(updatedUserInfo);
          console.log("user is updated");
      });
    // perform login action here
    // if login fails, update error message: setErrorMessage('Incorrect email or password');
    setSubmitting(false);
  };

  const validateUpdate = (
    firstNameError,
    lastNameError,
    phoneNumberError,
    countryError,
    cityError,
    postalCodeError,
    addressError
  ) => {
    
    console.log("sign Uppppp");
    if (firstNameError) {
      setErrorMessage(firstNameError);
      return;
    } else if (lastNameError) {
      setErrorMessage(lastNameError);
      return;
    } else if (phoneNumberError) {
        setErrorMessage(phoneNumberError);
        return;
    } else if (countryError) {
        setErrorMessage(countryError);
        return;
    } else if (cityError) {
        setErrorMessage(cityError);
        return;
    } else if (postalCodeError) {
        setErrorMessage(postalCodeError);
        return;
    } else if (addressError) {
        setErrorMessage(addressError);
        return;
    } else{
      setErrorMessage("");
    }
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const updateValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("FIRST NAME IS REQUIRED"),
    lastName: Yup.string().required("LAST NAME IS REQUIRED"),
    phoneNumber: Yup.string().required("PHONE NUMBER IS REQUIRED").matches(phoneRegExp, 'PHONE NUMBER IS NOT VALID'),
    country: Yup.string().required("COUNTRY IS REQUİRED"),
    city: Yup.string().required("CITY IS REQUIRED"),
    postalCode: Yup.string().required("POSTAL CODE IS REQUIRED")
    .min(5, 'POSTAL CODE SHOULD BE 5 DIGITS')
    .max(5, 'POSTAL CODE SHOULD BE 5 DIGITS'),
    address: Yup.string().required("ADDRESS IS REQUIRED"),
  });

  const updateForm = () => {
    return(
      <div>

<Navbar ></Navbar>
            <HeaderHome></HeaderHome>
      <div className="form-container">
        <Formik
          initialValues={{ 
            firstName: user.name, 
            lastName: user.surname , 
            phoneNumber:user.phoneNumber,
            country: user.country,
            city: user.city,
            postalCode: user.postalCode,
            apartmentSuite: user.apartmentSuite,
            address: user.address
          }}
          validationSchema={updateValidationSchema}
          onSubmit={update}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="sign-up-form">
              <button
                className="login-dont-you-have-an-account"
                onClick={() => {
                  navigate("../signIn");
                }}
              >
                UPDATE YOUR INFO
              </button>

              <Field name="firstName" type="name" placeholder="FIRST NAME" />
              <Field name="lastName" type="name" placeholder="LAST NAME" />
              <Field name="phoneNumber" type="tel" placeholder="PHONE NUMBER" />
              <Field name="country" type="text" placeholder="COUNTRY" />
              <Field name="city" type="text" placeholder="CITY" />
              <Field name="postalCode" type="number" placeholder="POSTAL CODE" />
              <Field name="address" type="text" placeholder="ADDRESS" />
              <Field name="apartmentSuite" type="text" placeholder="APARTMENT / SUITE ETC." />

              <div className="account-error">{errorMessage}</div>

              <div>
                <Grid
                  container
                  xl={12}
                  marginTop={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xl={4.5} xs={4.5}>
                    <button
                      className="account-black-button"
                      onClick={(e) => {
                        validateUpdate(
                            errors.firstName,
                            errors.lastName,
                            errors.phoneNumber,
                            errors.country,
                            errors.city,
                            errors.postalCode,
                            errors.address);
                      }}
                    >
                      UPDATE
                    </button>
                  </Grid>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>

    );
  }

  const accountInfo = () => {
    return(
<div>

<Navbar ></Navbar>
            <HeaderHome></HeaderHome>
    <div className='logged-in-form-container'>


      <div className="logged-in-form">
        <div className='logged-in-welcome-msg'> WELCOME , {user.name} {user.surname} </div>
        <input className='logged-in-account-info' disabled="true" value={user.email}></input>

        <input className='logged-in-account-info' disabled="true" placeholder='PASSWORD : ****'></input>

        <Grid container xl={12}  marginTop={6}>
          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
            <button
            className="logged-in-black-button"
            type="submit"
            onClick={ () => { setIsUpdate(true)}}>
            EDIT YOUR INFO
            </button>
          </Grid>

          <Grid item xl ={2} lg={2} md={2} sm={2} xs={2}></Grid>
          <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
            <button
            className="logged-in-black-button"
            type="submit"
            onClick={ () => {signOut()}}>
            SIGN OUT
            </button>
          </Grid>
        </Grid>
        
      </div>
    </div>

</div>
      

    );
  }

  return (
    <div>
        {isUpdate? updateForm() : accountInfo()}

    </div>
    
  );
}

