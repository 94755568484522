import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { IoCloseOutline } from "react-icons/io5";

import "./shopping-bag.css";
import Navbar from "../../components/Navbar";
import { HeaderHome } from "../../components/headerHome/HeaderHome";
import useLocalStorage from "../../components/Products/useLocalStorage";
import ShoppingBagSum from "../../components/shoppingBagComponents/ShoppingBagSum";

import cartEmptyImg1 from "./cart-empty-img-1.png";
import cartEmptyImg2 from "./cart-empty-img-2.png";
import cartEmptyImg3 from "./cart-empty-img-3.jpg";
import carpi from "./carpi.png";

export default function ShoppingBag() {
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountCodeInput, setDiscountCodeInput] = useState("");
  const [isApplyDisabled, setApplyDisabled] = useState(true);
  const [shippingPriceTRY, setShippingPriceTRY] = useLocalStorage(
    "shippingPriceTry",
    [],
  );
  const [shippingPriceEUR, setShippingPriceEUR] = useState("");
  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);
  const [isUpdatingStock, setIsUpdatingStock] = useState(false);
  const [isMaxStock, setIsMaxStock] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const fetchDiscountData = async () => {
      try {
        const result = await API.get("almaAppApi", "/api/financial-values", {});
      } catch (error) {
        console.log(error);
      }
    };
    fetchDiscountData();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [isUpdatingStock, isMaxStock]);

  useEffect(() => {
    let sum = cartItems.reduce(function (prev, current) {
      return prev + +current.price;
    }, 0);

    setSubTotal(sum);

    if (sum >= 3000) {
      setTotal(sum);
    } else {
      setTotal(sum + shippingPriceTRY);
    }
  }, [cartItems]);

  const navigate = useNavigate();
  const [isCartItemsEmpty, setIsCartItemsEmpty] = useState(false);

  const navigateCheckout = () => {
    navigate("/checkout");
  };

  // when x icon clicked, product is removed compeletely (no matter what the quantity is)
  const onRemoveCloseIcon = async (product) => {
    console.log(product.qty);

    if (!isUpdatingStock) {
      setIsUpdatingStock(true);

      const exist = cartItems.find((x) => x._id === product._id);
      console.log(exist);
      if (exist) {
        try {
          // const result = await API.put(
          //   "almaAppApi",
          //   `/api/product-stock-update-increase-by-count/${exist._id}/${exist.qty}`,
          //   {}
          // );
          setCartItems(cartItems.filter((x) => x._id !== product._id));
        } catch (error) {
          console.log(error);
        }
      } else {
        // setCartItems(
        //   cartItems.map((x) =>
        //     x._id === product._id ? { ...exist, qty: 0 } : x
        //   )
        // );
      }
      setIsUpdatingStock(false);
    }
  };

  // when + icon clicked, number of products increase by one, stock decrease by one
  const onAdd = async (product) => {
    const exist = cartItems.find((x) => x._id === product._id);
    if (exist && !isUpdatingStock) {
      console.log("updatinggggg");
      console.log(exist.qty);
      console.log(exist.stock);

      if (exist.stock == 1) {
        console.log("out of stock");
        setIsMaxStock(true);
      } else {
        setIsMaxStock(false);
      }

      setIsUpdatingStock(true);

      try {
        // const result = await API.put(
        //   "almaAppApi",
        //   `/api/product-stock-update-decrease/${exist._id}`,
        //   {}
        // );
        setCartItems(
          cartItems.map((x) =>
            x._id === product._id
              ? {
                  ...exist,
                  qty: exist.qty + 1,
                  stock: exist.stock - 1,
                  price: exist.price + exist.originalPrice,
                }
              : x,
          ),
        );
      } catch (error) {
        console.log(error);
      }

      setIsUpdatingStock(false);

      // if (subTotal >= 600) {
      //   setTotal(total-shippingPriceTRY);
      // }
    }
  };

  // when - icon clicked, number of products decreased by one, stock increase by one
  const onRemove = async (product) => {
    const exist = cartItems.find((x) => x._id === product._id);

    console.log(exist.qty);
    console.log(exist.stock);

    if (!isUpdatingStock) {
      setIsUpdatingStock(true);
      try {
        if (exist.qty === 1) {
          console.log("qty 11111");
          // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${exist._id}`, {});
          setCartItems(cartItems.filter((x) => x._id !== exist._id));
        } else {
          // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${product._id}`, {});
          setCartItems(
            cartItems.map((x) =>
              x._id === product._id
                ? {
                    ...exist,
                    qty: exist.qty - 1,
                    stock: exist.stock + 1,
                    price: exist.price - exist.originalPrice,
                  }
                : x,
            ),
          );
        }
      } catch (error) {}
      if (exist.stock > -1) {
        setIsMaxStock(false);
      } else {
        setIsMaxStock(true);
      }
      setIsUpdatingStock(false);

      // if (subTotal >= 600) {
      //   setTotal(total-shippingPriceTRY);
      // }
    }
  };

  const shoppingBagDiv = () => {
    return (
      <Grid
        container
        xl={11.25}
        lg={11.25}
        md={11.25}
        sm={11.25}
        xs={11}
        className="shopping-bag-container"
      >
        <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
          <div>
            {cartItems.map((item) => (
              <div>
                <Grid key={item._id} container>
                  <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                    <img
                      className="shopping-bag-basket-image"
                      src={cartItems[cartItems.indexOf(item)].images[0][0]}
                    ></img>
                  </Grid>

                  <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                    <div className="spaceLarge"></div>
                  </Grid>

                  <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
                    <div>
                      <div>{item.name}</div>
                      <div>{item.product_type}</div>
                      <div className="spaceSmall"></div>
                      <div>One Size Fits All</div>
                      <div>Color: Black</div>
                      <div className="spaceSmall"></div>
                      <button
                        className="shopping-bag-remove"
                        disabled={isUpdatingStock}
                        onClick={() => onRemove(item)}
                      >
                        -
                      </button>
                      <div className="shopping-bag-item-qty">{item.qty}</div>
                      <button
                        className="shopping-bag-add"
                        disabled={isUpdatingStock || isMaxStock}
                        onClick={() => onAdd(item)}
                      >
                        +
                      </button>
                    </div>
                  </Grid>

                  <Grid
                    item
                    container
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    direction="column"
                  >
                    <Grid
                      item
                      container
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Grid item xl={10} lg={10} md={10} sm={10} xs={8}>
                        <div className="spaceLarge"></div>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={4}>
                        <img
                          className="shopping-bag-carpi"
                          src={carpi}
                          onClick={() => onRemoveCloseIcon(item)}
                        ></img>
                      </Grid>
                      {/* <IoCloseOutline fontSize="23px" style={{color:"grey", height:"100%", width:"100%"}} onClick={() => onRemoveCloseIcon(item)}>
                        </IoCloseOutline> */}
                    </Grid>

                    <Grid item xl={8} lg={8} md={8} sm={7} xs={7}>
                      <div className="spaceLarge"></div>
                    </Grid>
                    <Grid
                      item
                      xl={1}
                      lg={1}
                      md={1}
                      sm={1}
                      xs={1}
                      display="flex"
                      justifyContent="right"
                      alignItems="center"
                    >
                      <div>
                        {item.price}
                        {item.currency}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
                    <div className="spaceSmall"></div>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item xl={12} lg={12} xs={12}>
                    <div className="spaceLarge"></div>
                    <div className="spaceSmall"></div>
                    <div className="spaceSmall"></div>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </Grid>

        <Grid item xl={1} lg={1} md={1} sm={1}>
          <div className="spaceLarge"></div>
        </Grid>

        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <ShoppingBagSum
            cartItems={cartItems}
            setCartItems={setCartItems}
          ></ShoppingBagSum>

          <button
            className="shopping-bag-sum-black-button shopping-bag-checkout-button"
            onClick={navigateCheckout}
          >
            CHECKOUT
          </button>

          {/* <div className='shopping-bag-sum-container'>
              <Grid container xl={12} xs={12}>
                  <Grid item xl={8} xs={8}>
                      <div>SUBTOTAL</div>
                      <div>SHIPPING</div>
                      <div>TOTAL</div>
                  </Grid>
                  <Grid item xl={4} xs={4} textAlign="right">
                      <div>{subTotal} TRY</div>
                      <div> {subTotal >= 600 ? "FREE" : shippingPriceTRY + " TRY" } </div>
                      <div>{total} TRY</div>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                      <Divider></Divider>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                      <div className='shopping-bag-sum-small-space'></div>
                  </Grid>
                  <Grid item xl={8} xs={8}>
                      <input className='shopping-bag-sum-grey-button' placeholder="DISCOUNT CODE" value={discountCodeInput} onChange={(event) => {
                        const value = event.target.value;
                        setApplyDisabled(value === '');
                        setDiscountCodeInput(value);
                      }}></input>
                  </Grid>
                  <Grid item xl={0.3} xs={0.3}>
                  </Grid>
                  <Grid item xl={3.7} xs={3.7}>
                      <button className='shopping-bag-sum-black-button' onClick={applyDiscountCode} disabled={isApplyDisabled}>APPLY</button>
                  </Grid>

                  <Grid item xl={12} xs={12}>
                      <div className='shopping-bag-sum-small-space'></div>
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <button className='shopping-bag-sum-black-button shopping-bag-checkout-button' onClick={navigateCheckout}>CHECKOUT</button>
                  </Grid>
              </Grid>
          </div> */}
        </Grid>
      </Grid>
    );
  };

  const cartIsEmpty = () => {
    return (
      <div>
        <div className="cart-is-empty">
          YOUR CART IS CURRENTLY EMPTY <br />
          CLICK{" "}
          <u
            onClick={() => {
              navigate("../shop");
            }}
            className="underline-styling"
          >
            HERE
          </u>{" "}
          TO CONTINUE SHOPPING
        </div>
        <div className="cart-is-empty-image-container">
          <img className="cart-empty-image-1" src={cartEmptyImg1}></img>
          <img className="cart-empty-image-2" src={cartEmptyImg2}></img>
          <img className="cart-empty-image-3" src={cartEmptyImg3}></img>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (cartItems.length === 0) {
      console.log("empty");
      setIsCartItemsEmpty(true);
      localStorage.removeItem("discountCode");
    }
  }, [isCartItemsEmpty, cartItems]);

  return (
    <div>
      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
      {!isCartItemsEmpty && shoppingBagDiv()}
      {isCartItemsEmpty && cartIsEmpty()}
    </div>
  );
}
