import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import useLocalStorage from "../components/Products/useLocalStorage";

import "./ThreeDsScreen.css";
import Navbar from "../components/Navbar";
import { HeaderHome } from "../components/headerHome/HeaderHome";

export default function SendMail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);

  const sendMail = () => {
   console.log("button clicked");
    console.log(cartItems);

    let productHTML = '';
    let itemCount = 0; // kaç ürün alındı
    let total = 0; //toplam fiyat
    let currency; 
    cartItems.forEach((product) => {


      itemCount = itemCount + product.qty;

      total = total + product.price;

      currency = product.currency;

      productHTML += `
      <div class="product-detail" style="display: inline-flex; justify-content: center; margin: 50px auto 0;  width: 430px; border: none;">
  <img src="${product.images[0][0]}" style="width: 200px">
  <p style="width: 60%; line-height: 17px; text-align: left; padding-left: 30px; margin-top: 0px; font-size: 12px;letter-spacing: 1.7px;">
    ${product.name}<br>
    ${product.product_type}<br><br>
    Color: ${product.color_name}<br>
    One Size Fits All<br><br><br>
    3TRY<br><br>
    3 UNIT
  </p>
</div>
        
      `;
    });
    
    const html= `<!DOCTYPE html>
    <head>
      <title>Order is received!</title>

       
        <style>
          @font-face {
            font-family: 'regular';
            src: url("https://d27wok9y6t3pi.cloudfront.net/public/Linotype_Brewery_W01_Regular.ttf");
            font-weight: normal;
            font-style: normal;
          }
          body {
            font-family: 'regular', Helvetica, Arial;
          }
        </style>
    </head>
    <body >
    <div style=" width: 430px; margin: 0 auto; font-size: 13px;">
      <img class="brand" src="https://d27wok9y6t3pi.cloudfront.net/public/alma_logo.png" style="width: 93%; display: flex; margin: 0 auto 10px;">
      <div class="slogan" style="text-align: center; font-size: 9px;  letter-spacing: 1.3px; margin: 20px auto 50px;">ECO-CONSCIOUS EXOTIC<br>ESSENTIALS</div>
      <p class="header" style="font-size: 16px;  letter-spacing: 1.9px; text-align: center;">THANK YOU FOR YOUR PURCHASEW</p>
      <p class="order-no" style="font-size: 10px; letter-spacing: 1.9px; text-align: center;">ORDER NO. 34534535345</p>
      ${productHTML}
      <hr style="background-color: grey; margin-top: 25px; ">
      <div class="order-details" style="line-height: 20px; font-size: 13px; text-align: left; letter-spacing: 1.7px; width: 89%;">
        <p>3 ITEMS<br>TOTAL: 3TRY<br>SHIPPING: FREE<br><br></p>
        <div class="address-detail" style="text-align: left; margin-top: 30px; font-size: 12px; letter-spacing: 1.7px; width: 89%; line-height: 17px;">
          <p class="address-title">DELIVERY ADDRESS<br><br>myaddress</p>
        </div>
      </div>
      <div class="footer" style="margin-top: 120px; text-align: center; letter-spacing: 1.2px;">
        <div class="top-links" style="margin: auto; font-size: 5px; letter-spacing: 1.2px;">
          <a class="link" href="https://www.alma-rituals.com/contactUs" style=" margin-right: 12px; white-space: nowrap; color: black; text-decoration: none;">CONTACT US</a>
          <a class="link" href="https://www.alma-rituals.com/newsletter" style="margin-right: 12px; white-space: nowrap; color: black; text-decoration: none;">NEWSLETTER</a>
          <a class="link" href="https://www.instagram.com/almarituals_/" style="margin-right: 12px; white-space: nowrap; color: black; text-decoration: none;">INSTAGRAM</a>
        </div>
        <div class="bottom-links" style="margin: 20px auto; font-size: 5px; letter-spacing: 1.2px;">
          <a class="link" href="https://www.alma-rituals.com/termsOfService" style="margin-right: 12px; white-space: nowrap; color: black; text-decoration: none;">TERMS OF SERVICE</a>
          <a class="link" href="https://www.alma-rituals.com/refundPolicy" style="margin-right: 12px; white-space: nowrap; color: black; text-decoration: none;">REFUND POLICY</a>
          <a class="link" href="https://www.alma-rituals.com/shippingPolicy" style="margin-right: 12px; white-space: nowrap; color: black; text-decoration: none;">SHIPPING POLICY</a>
          <a class="link" href="https://www.alma-rituals.com/privacyPolicy" style="white-space: nowrap; color: black; text-decoration: none;">PRIVACY POLICY</a>
        </div>
      </div>
      <img class="brand" src="https://d27wok9y6t3pi.cloudfront.net/public/alma_logo.png" style="width: 93%; display: flex; margin: 0 auto;">
    </div>
  </body>
    </html>`;

console.log(html);
    const data = {
      body: {
        html: html,
      },
    };

        API.post(
          "almaAppApi",
          `/api/threeds/payments/send-mail`,
          data
        ).then((result) => {
          console.log("email sent");
        });
  };

  return (
    <div>
      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
      <button onClick={() => sendMail()}></button>
    </div>
  );
}
