import React from 'react'
import "./policies.css"
import Navbar from '../../components/Navbar'
import { HeaderHome } from '../../components/headerHome/HeaderHome'

export default function TermsOfService() {
  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <div className='policy-text'>

    ÇEREZ POLITIKASI
<br/>
<br/>

        
Bu politika kapsamında “Çerez” kavramı, çerez ve benzer amaca hizmet eden ürünleri ifade etmektedir
Sena Karahasan (Sirket) olarak, çevrimiçi mecralarımızı ziyaretleriniz sırasında sizlerin deneyimini gelistirmek için çerezlerden faydalanmaktayız. Bu teknolojinin kullanımı basta 6698 sayılı Kisisel Verilerin Korunması Kanunu olmak üzere tabi oldugumuz mevzuata uygun sekilde gerçeklestirilmektedir.
Isbu Çerez Politikası Aydınlatma Metni’nin (“Aydınlatma Metni”) amacı, tarafımızca isletilmekte olan www.alma-rituals.com internet sitesinin (“Site”) isletilmesi sırasında, Site kullanıcıları/üyeleri/ziyaretçileri (“Veri Sahibi”) tarafından çerezlerin kullanımı ile elde edilen kisisel verilen islenmesine iliskin olarak sizlere bilgi vermektir. Isbu metinde sitemizde hangi amaçlarla hangi tür çerezleri kullandıgımızı ve bu çerezleri nasıl kontrol edebileceginizi sizlere açıklamak istiyoruz.
www.alma-rituals.com sitesinde kullandıgımız çerezleri kullanmaktan vazgeçebilir, bunların türlerini veya fonksiyonlarını degistirebilir veya yeni çerezler ekleyebiliriz. Dolayısıyla isbu Aydınlatma Metni üzerinde gerçeklestirilmis olan her türlü degisiklik Site’de veya herhangi bir kamuya açık mecrada yayınlanmakla birlikte yürürlük kazanacaktır.
Çerez Nedir?
Çerez, bir siteyi ziyaret ettiginizde sitenin bilgisayarınıza veya mobil cihazınıza kaydettigi küçük boyutlu bir metin dosyasıdır. Çerezler bir web sitesinin çalısması, daha verimli çalısması veya web sitesinin sahiplerine bilgi saglamak için yaygın olarak kullanılmaktadır.
Çerezler hakkında daha detaylı bilgi için http://www.aboutcookies.org ve http://www.allaboutcookies.org adreslerini ziyaret edebilirsiniz.
Ne için kullanılır?

<br/>
<br/>
 
Web sitemizde, süpheli eylemlerin tespiti yoluyla güvenligin saglanması, kullanıcıların tercihleri dogrultusunda islevsellik ve performansın artırılması, ürünlerin ve hizmetlerin gelistirilmesi ve kisisellestirilmesi ile bu hizmetlere ulasımın kolaylastırılması, sözlesmesel ve hukuki sorumlulukların yerine getirilmesi amaçlı çerezler kullanmaktadır. Ayrıca kullanıcıların daha genis kapsamlı hizmet saglayıcılar ile bulusturulabilmesi amacıyla reklam çerezleri ve üçüncü taraflarla bilgi paylasımı da söz konusudur.
Çerezler bilgisayarlarda, telefonlarda ve diger cihazlarda sizi tanımlayan bilgileri ve siteyi kullanırken yaptıgınız hareketleri, kullanım alıskanlıklarınızı toplamak, tutmak ve devamında internet sitemizi iyilestirmek ve size özel kisisellestirmek için kullanılır.
Biz de Sirket olarak www.alma-rituals.com internet sayfasında, site içerigi ile nasıl etkilesime geçtiginizi tespit etmek ve kullanıcı deneyiminizi gelistirebilmek için çesitli çerezler kullanmaktayız.
Web sitemizde kullanılan çerezler hakkında daha detaylı bilgi edinmek için Çerez Tablomuzu inceleyebilirsiniz.
Birinci Taraf ve Üçüncü Taraf Çerezler
Sirket’e ait www.alma-rituals.com internet sayfasında hem birinci taraf hem de üçüncü taraf çerezler kullanılmaktadır.
Birinci taraf çerezler, sirketimiz tarafından www.alma-rituals.com domainine yerlestirilmis ve sadece Sirket tarafından okunabilen; kullanıcının dil, lokasyon tercihi gibi temel site fonksiyonlarının yerine getirilmesi sürecinde kullanıcı deneyiminizi gelistirebilmek amacıyla kullanılan çerezlerdir.
Üçüncü taraf çerezler, Sirket’in is ortakları veya servis saglayıcılar gibi üçüncü kisilere ait ve üçüncü kisiler tarafından yürütülen çerezlerdir.
<br/>
<br/>
Websitemizde Kullanılan Çerezler:


<br/>
 
Zorunlu Çerezler : Web sitemizin dogru biçimde çalısması için zorunludur. Örnegin, kimlik dogrulama, mevcut oturumunuz ile ilgili bilgilerin kaybolmaması gibi amaçlarla zorunlu çerezler kullanılmaktadır. Bu çerezler güvenlik ve dogrulama gibi amaçlar için kullanılmakta olup, herhangi bir pazarlama amacı dogrultusunda kullanılamaz.
<br/>
Reklam Çerezleri  : Davranıssal ve hedef odaklı reklamların ziyaretçilere gösterilmesi amacıyla kullanılan çerezlerdir.
<br/>
Analitik Çerezleri :Üyelerimizin/ziyaretçilerimizin kullanım alıskanlıklarını analiz etmek ve uygulama istatistiklerini belirlemek için kullanılan çerezlerdir. (Örnegin: Sitemizin kaç kere açıldıgı, sitemizin hangi saatlerde kullanıldıgı, sitemiz içerisinde en çok kullanılan bölümlerin neler oldugu)
<br/>
Teknik Çerezleri  : Internet sitesinin düzgün çalısmasının takibi amacıyla kullanılan çerezlerdir.
Kisisellestirme Çerezleri: Kullanıcı davranıs ve tercihlerine göre kisisellestirilmis içerik ve deneyim sunmak amacıyla kullanılan çerezlerdir.
<br/>
<br/>
Çerezleri Nasıl Silebilirim veya Reddedebilirim?<br/>
http://www.alma-rituals.com internet sayfasında, sitemizin aktif ve verimli bir sekilde çalısması için birtakım zorunlu çerezler kullanılmaktadır. Gizlilik Tercih Paneli üzerinden bu çerezler devre dısı bırakılamamaktadır. Sitemizde kullanılan zorunlu çerezler için “Gizlilik Tercih Paneli”ni kullanabilirsiniz
Diger taraftan, zorunlu çerezlerin dısında kalan tüm çerez grupları kendiliginden “Devre Dısı Bırakılmıs” bir sekilde gelmektedir. Kullanıcılarımız, istekleri dogrultusunda bu çerezleri tekrar aktif hale getirebilir veya aktiflestirmis oldugu çerezleri daha sonra tekrar devre dısı bırakabilir. Ancak önemle belirtmek gerekir ki kullandıgımız çerezlerin devre dısı bırakılması/kalması, http://www.alma-rituals.com internet sayfasındaki kullanıcı deneyiminizi etkileyebilir. Zorunlu olmayan çerezlerin aktif hale getirilmemesi durumunda, uygulamayı ve/veya internet sitesini kullanmaya devam edebilirsiniz fakat uygulamanın ve/veya internet sitesinin tüm islevlerine erisemeyebilirsiniz veya sınırlı bir erisim imkânı ile karsılasabilirsiniz. Uygulamadan ve/veya internet sitesinden tam anlamıyla faydalanmak için çerezlere izin vermenizi tavsiye ediyoruz.
Çerezlerin “Gizlilik Tercihi Paneli” Dısında Yönetilmesi:
Sitemizde kullanılan çerezleri en verimli sekilde yönetimi “Gizlilik Tercih Paneli” üzerinden saglanmaktadır. Ancak siz degerleri kullanıcılarımız, çerezleri yönetmek için tarayıcınıza baglı olarak asagıdaki yöntemleri de tercih edebilirsiniz:
Safari: Telefonunuzun 'Ayarlar' bölümünden 'safari' sekmesini seçip, 'Gizlilik ve Güvenlik' Bölümünden tüm çerez yönetiminizi saglayabilirsiniz.
Anlık olarak kaç sayıda çerez ile takip edildiginizi görüntülemek için Ghostery uygulamasını veya dogrudan tarayıcıyı olarak Brave kullanabilirsiniz.
<br/>
<br/>
Veri Sahibi olarak haklarınız nelerdir?<br/>
6698 sayılı Kisisel Verileri Koruma Kanunu’nun 11. Maddesi uyarınca veri sahipleri,
Kisisel verilerin islenip islenmedigini ögrenme,
Kisisel verileri islenmisse buna iliskin bilgi talep etme,
Kisisel verilerin islenme amacını ve bunların amacına uygun kullanılıp kullanılmadıgını ögrenme,
Yurtiçinde veya yurtdısında kisisel verilerin aktarıldıgı üçüncü kisileri bilme,
Kisisel verilerin eksik veya yanlıs islenmis olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan islemin kisisel verilerin aktarıldıgı üçüncü kisilere bildirilmesini isteme,
6698 sayılı Kisisel Verileri Koruma Kanunu ile ilgili diger kanun hükümlerine uygun olarak islenmis olmasına ragmen, islenmesini gerektiren sebeplerin ortadan kalkması halinde kisisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan islemin kisisel verilerin aktarıldıgı üçüncü kisilere bildirilmesini isteme,
Islenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kisinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
Kisisel verilerin kanuna aykırı olarak islenmesi sebebiyle zarara ugraması halinde zararın giderilmesini talep etme haklarına sahiptir.

    </div>
    </div>
  )
}
