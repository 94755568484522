import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";

import "./admin.css";


const Admin = () => {
  const [path, setPath] = useState("");
  const navigate = useNavigate();
  const handleUploadClick = () => {
    navigate("../admin/upload");
  };
  const handleEditClick = () => {
    navigate("../admin/edit");
  };
  const handleFinancialValuesClick = () => {
    navigate("../admin/upload-financial-values");
  };
  const handleSendMail = () => {
    navigate("../admin/send-order-info");
  };

  return (
    <div>
      <Navbar></Navbar>
      <div className="admin-panel-container">
        <button onClick={handleUploadClick}>UPLOAD PRODUCTS</button>
        <button onClick={handleEditClick}>EDIT PRODUCTS</button>
        <button onClick={handleFinancialValuesClick}>
          EDIT FINANCIAL VALUES
        </button>
        <button onClick={handleSendMail}> SEND ORDER INFO MAIL</button>
      </div>
    </div>
  );
};

export default Admin;
