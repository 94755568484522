import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API, Amplify } from "aws-amplify";

import useLocalStorage from "../components/Products/useLocalStorage";

import "./ThreeDsScreen.css";

export default function ThreeDsScreen(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);


  const onIframeRef = async (node) => {
    window.addEventListener('message', receiveMessageFromIframe, false);
    console.log()
    function receiveMessageFromIframe(event) {
      // if (event.origin !== 'http://localhost:3000') {
      //   return; // ignore messages from other origins
      // }console.log("lele");
    
      const message = event.data;
      if (message.type === 'buttonClickedFromCancel') {
        console.log('Button clicked on cancel in iframe!');
        navigate('/shoppingBag');
      } else if (message.type === 'buttonClickedFromFail') {
        console.log('Button clicked on fail in iframe!');
        navigate('/shoppingBag');
      } else if (message.type === 'buttonClickedFromSucces') {
        //cart items temizlenecek
        setCartItems([]);
        localStorage.setItem("discountCodeIsUsedBefore",true);
        console.log('Button clicked on success in iframe!');
        navigate('/');
        window.location.reload();
      }
    }
  };

  return (
    <div>
      <iframe
        className="iframe-payment"
        id="frameId"
        allowFullScreen="true"
        srcDoc={location.state.html}
        onLoad={ onIframeRef}
      ></iframe>
    </div>
  );
}
