import React ,{useState, useEffect} from 'react'

import Grid from "@mui/material/Grid";

import ShoppingBagSum from '../../components/shoppingBagComponents/ShoppingBagSum';
import useLocalStorage from '../../components/Products/useLocalStorage';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import jwt from "jwt-decode";
import {IoCloseOutline} from 'react-icons/io5'
import Divider from '@mui/material/Divider';
import {API,Amplify} from 'aws-amplify';

import { HeaderHome } from '../../components/headerHome/HeaderHome';

import Navbar from '../../components/Navbar';

import "../../components/checkoutComponents/checkout.css";

import carpi from "./carpi.png"





export default function Checkout2() {

  const [isUpdatingStock, setIsUpdatingStock] = useState(false);

  const [isSaveInfoForNextTime, setSaveInfoForNextTime] = useState(false); 
  const [shippingPriceTRY, setShippingPriceTRY] = useLocalStorage("shippingPriceTry", []);
  const [shippingPriceEUR, setShippingPriceEUR] = useState("");
  const [isMaxStock, setIsMaxStock] = useState(false);




  Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: 'almarituals143254-dev', //REQUIRED -  Amazon S3 bucket name      
            region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        }
    }
  });

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [cartItems, setCartItems] = useLocalStorage("cartItems", []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    console.log("lala");
      
     }, [isUpdatingStock,isMaxStock]);


     useEffect(() => {
      // scrollToTop();

      console.log(localStorage.getItem("discountCode"));
        
       }, []);



     useEffect(() => {
      setSubTotal(
          cartItems.reduce(function(prev, current) {
              return(prev + +current.price);
          }, 0)
      );
  
      let sum =  cartItems.reduce(function(prev, current) {
          return(prev + +current.price);
      }, 0);
  
      setTotal((sum));

      if (cartItems.length === 0) {
        console.log("empty");
        localStorage.removeItem("discountCode");
      }
  
    }, [cartItems]);




     const [token, setToken] = useLocalStorage("token", []);

     const [user, setUser] = useState((token !== null && token.length!==0) ? jwt(token) : "");
   
     const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

     const navigate = useNavigate();
   
     const  navigateShoppingBag = () =>{
      navigate("/shoppingBag",{state:{id:"id"}});
     } 
   
     const  navigateShipping = (userEmail, userAddress, userFirstName, userLastName, userPhoneNumber, userCountry, userCity, userPostalCode, userApartmentSuite) =>{
      navigate("/shipping", {state: {
          userEmail: userEmail,
          userAddress: userAddress,
          userFirstName: userFirstName,
          userLastName: userLastName,
          userPhoneNumber: userPhoneNumber,
          userCountry: userCountry,
          userCity: userCity,
          userPostalCode: userPostalCode,
          userApartmentSuite: userApartmentSuite
        },
      });
     } 
   
     const  navigateAccount = () =>{
      navigate("/account",{state:{id:"id"}});
     } 
   
     const handleSubmit = (values) => {
       console.log('Form data:', values);

       

       navigateShipping(
         values.email,
         values.address,
         values.firstName,
         values.lastName,
         values.phoneNumber,
         values.country,
         values.city,
         values.postalCode,
         values.apartmentSuite
       );
   
     };

   
     const validationSchema = Yup.object().shape({
       firstName: Yup.string().required("FIRST NAME IS REQUIRED"),
       lastName: Yup.string().required("LAST NAME IS REQUIRED"),
       phoneNumber: Yup.string().required("PHONE NUMBER IS REQUIRED").matches(phoneRegExp, 'PHONE NUMBER IS NOT VALID'),
       country: Yup.string().required("COUNTRY IS REQUİRED"),
       city: Yup.string().required("CITY IS REQUIRED"),
       postalCode: Yup.string().required("POSTAL CODE IS REQUIRED")
       .min(5, 'POSTAL CODE SHOULD BE 5 DIGITS')
       .max(5, 'POSTAL CODE SHOULD BE 5 DIGITS'),
       address: Yup.string().required("ADDRESS IS REQUIRED"),
       email: Yup.string().email("INVALID EMAIL").required("EMAIL IS REQUIRED").test("check-email-exists", "THERE IS AN ACCOUNT WITH THIS EMAIL PLEASE LOGIN ON ACCOUNT PAGE", 
       async function checkIfEmailExists(email) {
        console.log(email);

        if (user) {
          return true;
        } else {

          try {

            const response = await API.get("almaAppApi", `/api/checkout-email-exist/${email}`);
            console.log(response); // assuming the response contains the boolean value
            return !response;
          } catch (error) {
            console.log(error);
            console.log(`error is ${JSON.stringify(error)}`);
            console.log(error.response.data.message);
            return false;
          }


        }
        
      })
     });

     const onRemoveCloseIcon = async (product) => {
      console.log(product.qty);
  
      if(!isUpdatingStock){
        setIsUpdatingStock(true);
  
        const exist = cartItems.find((x) => x._id === product._id);
        console.log(exist);
          if (exist) {
  
            try {
              // const result = await API.put(
              //   "almaAppApi",
              //   `/api/product-stock-update-increase-by-count/${exist._id}/${exist.qty}`,
              //   {}
              // );
              setCartItems(cartItems.filter((x) => x._id !== product._id));
  
            } catch (error) {
              console.log(error);
            }
          } else {
            // setCartItems(
            //   cartItems.map((x) =>
            //     x._id === product._id ? { ...exist, qty: 0 } : x
            //   )
            // );
          }
        setIsUpdatingStock(false);
      }
    }
  
  // when + icon clicked, number of products increase by one, stock decrease by one
  const onAdd = async (product) => {
    const exist = cartItems.find((x) => x._id === product._id);
    if (exist && !isUpdatingStock) {


      if (exist.stock == 1) {
        console.log("out of stock")
        setIsMaxStock(true);
      } else {
        setIsMaxStock(false);
      }

      setIsUpdatingStock(true);
      console.log("updatinggggg")

      try {
        // const result = await API.put(
        //   "almaAppApi",
        //   `/api/product-stock-update-decrease/${exist._id}`,
        //   {}
        // );
        setCartItems(
          cartItems.map((x) => 
          x._id === product._id ? {
            ...exist,
            qty: exist.qty + 1,
            stock: exist.stock - 1,
            price: exist.price + exist.originalPrice,
          } : x)
        );
      } catch (error) {
        console.log(error);
      }

      setIsUpdatingStock(false);

    } 
  };


        // when - icon clicked, number of products decreased by one, stock increase by one
        const onRemove = async (product) => {

          const exist = cartItems.find((x) => x._id === product._id);
          if(!isUpdatingStock) {
            setIsUpdatingStock(true);
            try {
              if (exist.qty === 1 ) {
                console.log("qty 11111");
                // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${exist._id}`, {});
                setCartItems(cartItems.filter((x) => x._id !== exist._id));
    
              } else {
                // const result = await API.put("almaAppApi", `/api/product-stock-update-increase/${product._id}`, {});
                setCartItems(
                  cartItems.map((x) =>
                    x._id === product._id ? { ...exist, qty: exist.qty - 1 , stock: exist.stock + 1,  price: exist.price - exist.originalPrice} : x
                  )
                );
              }
            } catch (error) {
              
            }

            if (exist.stock > -1) {
              setIsMaxStock(false);
            } else {
              setIsMaxStock(true);
            }
            setIsUpdatingStock(false);
          }
    
        };


    





  return (

    <div>






                

      <Navbar ></Navbar> 
      <HeaderHome></HeaderHome>

      
      <Grid container xl={11.25} lg={11.25} md={11.25} sm={11.2} xs={11} className='checkout-div' >

            <Grid item xl={5.9} lg={5.9} md={5.9} sm={6.1} xs={12} >
              {/* <ContactInfo navigateToShoppingBag="/shoppingBag" navigateToShipping="/shipping" navigateToAccount="account"></ContactInfo> */}
              <Formik 
              initialValues={{ 
                email: user.email,
                country: user.country,
                firstName: user.name, 
                lastName: user.surname , 
                address: user.address,
                apartmentSuite: user.apartmentSuite,
                postalCode: user.postalCode,
                city: user.city,
                phoneNumber:user.phoneNumber,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              >
              {({ errors }) => (
              <Form>
                <Grid container xl={12} lg={12} md={12} sm= {12} xs={12} >

                    <Grid item xl={12} lg={12} md={12} sm= {12} xs={12} >

                        <div className='checkout-small-title'>CONTACT INFORMATION</div>
                        <Field className='checkout-large-input'  disabled={user !== null && user.length!==0}  name="email" type="text" placeholder="EMAIL ADDRESS" />
                        <div className='checkout-x-small-space'></div>

                    </Grid>

                    <div >

                      {(errors.email && <ErrorMessage name="email" />)}

                    </div>
                    {(token === null ||  token.length===0 )&&
                            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>

                                    <Grid item xl={5.85} lg={5.85} md={5.85} sm={5.85} xs={12}>
                                    <button className='checkout-black-button'onClick={navigateAccount}>ALREADY HAVE AN ACCOUNT? LOGIN</button>
                                    <div className='checkout-x-small-space'></div>

                                    </Grid>

                                    <Grid item xl={0.3} lg={0.3} md={0.3} sm={0.3} xs={12}></Grid>

                                    <Grid item xl={5.85} lg={5.85} md={5.85} sm={5.85} xs={12}>
                                    <button className='checkout-grey-button' type="submit" >EMAIL ME WITH NEWS AND OFFERS</button>
                                    </Grid>
                            </Grid>

                            


                    }
                    <Grid item>
                        <div className='checkout-small-title'>SHIPPING ADDRESS</div>
                    </Grid>

                    <Grid item xl={12} xs={12} >
                        <Field className='checkout-large-input' name="country" type="text" placeholder="COUNTRY" />
                        <div className='checkout-x-small-space'></div>
                    </Grid>

                    <Grid item container >
                        <Grid item xl={5.85} lg={5.85} md={5.85} sm={5.85} xs={12}>
                            <Field className='checkout-small-input' name="firstName" type="name" placeholder="FIRST NAME" />
                            <div className='checkout-x-small-space'></div>
                        </Grid>
                        <Grid item xl={.3} lg={.3} md={.3} sm={.3}  xs={12}></Grid>
                        <Grid item xl={5.85} lg={5.85} md={5.85} sm={5.85} xs={12}>
                            <Field className='checkout-small-input' name="lastName" type="name" placeholder="LAST NAME" />
                            <div className='checkout-x-small-space'></div>
                        </Grid>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm= {12} xs={12} >
                        <Field className='checkout-large-input' name="address" type="text" placeholder="ADDRESS" />
                        <div className='checkout-x-small-space'></div>
                        <Field className='checkout-large-input' name="apartmentSuite" type="text" placeholder="APARTMENT / SUITE ETC." />
                        <div className='checkout-x-small-space'></div>
                    </Grid>

                    <Grid item container xl={12}>
                        <Grid item xl={5.85} lg={5.85} md={5.85}  sm={5.85} xs={12}>
                            <Field className='checkout-small-input' name="postalCode" type="number" placeholder="POSTAL CODE" />
                            <div className='checkout-x-small-space'></div>
                        </Grid>
                        <Grid item xl={.3} lg={.3} md={.3} sm={.3}  xs={12}></Grid>
                        <Grid item xl={5.85} lg={5.85}  md={5.85}  sm={5.85} xs={12}>
                            <Field className='checkout-small-input' name="city" type="text" placeholder="CITY" />
                            <div className='checkout-x-small-space'></div>
                        </Grid>
                    </Grid>

                    <Grid item xl={12} xs={12}>
                        <Field className='checkout-large-input' name="phoneNumber" type="tel" placeholder="PHONE NUMBER" />
                    </Grid>

                    <Grid item xl={1} xs={1} marginTop={2}>
                        <Field type="radio"
                    checked={isSaveInfoForNextTime}
                    onClick={() => setSaveInfoForNextTime(!isSaveInfoForNextTime)}
                     ></Field>
                    </Grid>
                    <Grid  style={{ fontSize: '10px' }} item xl={11} lg={11} md={11} xs={11} marginTop={2.1}>
                        SAVE THIS INFORMATION FOR THE NEXT TIME
                    </Grid>

                    <div >

                      {  (errors.country && <ErrorMessage name="country" />) 
                      || (errors.firstName && <ErrorMessage name="firstname" />)
                      || (errors.lastName && <ErrorMessage name="lastName" />)
                      || (errors.address && <ErrorMessage name="address" />)
                      || (errors.apartmentSuite && <ErrorMessage name="apartmentSuite" />)
                      || (errors.postalCode && <ErrorMessage name="postalCode" />)
                      || (errors.city && <ErrorMessage name="city" />)
                      || (errors.phoneNumber && <ErrorMessage name="phoneNumber" />)}

                    </div>

                    <Grid item container xl={12} lg={12} marginTop={3}>
                        <Grid item xl={5.85} lg={5.85} md={5.85} sm={5.85} xs={12}>
                            <button className='checkout-grey-button' onClick={navigateShoppingBag}>RETURN TO CART</button>
                        <div className='checkout-x-small-space'></div>

                        </Grid>

                        <Grid item xl={0.3} lg={0.3} md={0.3} sm={0.3} xs={12}></Grid>

                        <Grid item xl={5.85} lg={5.85} md={5.85} sm={5.85} xs={12}>
                            <button className='checkout-black-button' type="submit" >CONTINUE TO SHIPPING</button>
                        </Grid>
                    </Grid>

                </Grid>

                </Form>
        )}
              </Formik>
            </Grid>
            
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.3} xs={0}></Grid>

            <Grid item container xl={4.6} lg={4.6} md={4.55} sm={4.6} xs={12}  >
              <Grid item xl={12} lg={12} xs={12} md={12} sm={12}  >
                {/* <CheckoutShoppingBagItems cartItems = {cartItems} setCartItems={setCartItems} ></CheckoutShoppingBagItems> */}
                <div className='checkout-shopping-bag-container'>
                  {cartItems.map((item) => (
                    <div>
                      <Grid key={item._id} container xl={12} lg={12} xs={12} >

                        <Grid item xl={3} lg={3} md={3} sm={2.5} xs={2.5} display="flex" justifyContent="center" alignItems="flex-start">
                          <img className="checkout-basket-image" src={cartItems[cartItems.indexOf(item)].images[0][0]}></img>
                        </Grid>

                        <Grid item xl={7.5} lg={7.5} md={7.5} sm={7.4} xs={7.9} >
                          <div className='checkout-shopping-bag-item-details'>
                            <div>{item.name}</div>
                            <div>{item.product_type}</div>
                            <div className='spaceSmall'></div>
                            <div>One Size Fits All</div>
                            <div>Color: Black</div>
                            <div className='spaceSmall'></div>

                            <button className="shopping-bag-remove" disabled={isUpdatingStock} onClick={() => onRemove(item)}>-</button>
                            <div className="shopping-bag-item-qty">{item.qty}</div>
                            <button className="shopping-bag-add" disabled={isUpdatingStock || isMaxStock || item.stock == 0} onClick={() => onAdd(item)}>+</button>
                          </div>
                        </Grid>

                        {/* <Grid item container  xl={1} lg={1} xs={1}  display="flex" justifyContent="flex-end" >
                          <Grid item >
                            <IoCloseOutline fontSize="22px" style={{color:"grey"}} onClick={() => onRemoveCloseIcon(item)}>
                            </IoCloseOutline>
                          </Grid>
                          <Grid item marginTop={10}>
                            <div >{item.price}{item.currency}</div>
                          </Grid>
                        </Grid> */}

                        <Grid item container  xl={1.5} lg={1.5} md={1.5} sm={2} xs={1.6} direction="column" >

                          <Grid item container xl={1} lg={1} md={1} sm={1} xs={1}  display="flex" justifyContent="flex-end" >
                            <Grid item xl={9} lg={8} md={8} sm={8} xs={8}>
                              <div className='spaceLarge'></div>
                            </Grid>
                            <Grid item xl={3} lg={4} md={4} sm={4} xs={4}>
                              <img className='checkout-carpi' src={carpi} 
                        onClick={() => onRemoveCloseIcon(item)}></img>
                            </Grid>
                            {/* <IoCloseOutline fontSize="23px" style={{color:"grey", height:"100%", width:"100%"}} onClick={() => onRemoveCloseIcon(item)}>
                            </IoCloseOutline> */}
                          </Grid>

                          <Grid item xl={7} lg={7} md={7} sm={3} xs={6}>
                            <div className='spaceLarge'></div>
                          </Grid>

                          <Grid item xl={1} lg={1} md={1} sm={2} xs={2} display="flex" justifyContent="right" alignItems="center">
                            <div >{item.price}{item.currency}</div>
                          </Grid>

                        </Grid>
                        
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
                          <div className='spaceSmall'></div>
                          <div className='spaceSmall'></div>
                          <div className='spaceSmall'></div>


                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Divider></Divider>
                        </Grid>

                        

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className='spaceLarge'></div>
                          <div className='spaceSmall'></div>
                        </Grid>

                      </Grid>
                      
                    </div>
                    ))}
                </div>

              </Grid>
              
              <Grid item xl={11.8} lg={11.8} md={11.8} sm={12} xs={12}>
                <div className='checkout-shopping-bag-sum'>
                  <ShoppingBagSum cartItems={cartItems} setCartItems={setCartItems}></ShoppingBagSum>
                </div>

              </Grid>
                
            </Grid>




        </Grid>
    </div>
        
  )
}
