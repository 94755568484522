import React from 'react';
import Grid from "@mui/material/Grid";
import { useLocation, BrowserRouter as Router, useParams} from 'react-router-dom'
import  { useEffect, useState }  from 'react'
import './shopSingle.css';
import Product from './Product';
import {API,Amplify} from 'aws-amplify';
import Navbar from '../Navbar';
import { HeaderHome } from '../headerHome/HeaderHome';
import useLocalStorage from './useLocalStorage';


export default function ShopSingle(){

    const location = useLocation();
    const [shippingPriceTry, setShippingPriceTry] = useLocalStorage("shippingPriceTry", []);

    const [slideIndex, setSlideIndex] = useState(0);


    const { id } = useParams();

    const [product, setProduct] = useState(null);



    const fetchProductWithHRImages = async (productId) => {
      console.log("hello from the outside" + productId)
        await API.get("almaAppApi", `/api/product/${productId}`, {})
        .then((result) => {console.log("myresulttttt");
        console.log(result[0]);
  
          setProduct(result[0]);
        });
    };

    useEffect(() => {
      console.log("fetching");
      fetchProductWithHRImages(id);
    }, [id]);


    useEffect(() => {

      const fetchFinancialValues = async () => {
        try {
          const result = await API.get("almaAppApi", "/api/financial-values", {});
          console.log(result);
          setShippingPriceTry(result.shipping_price_TRY);
        } catch (error) {
          console.log(error);
        }
      };

      fetchFinancialValues();
    }, []);

    useEffect(() => {
      // fetchProductWithHRImages();
    }, [slideIndex]);

    useEffect(() => {
      scrollToTop();
        
       }, []);

       useEffect(() => {
          
         }, [product]);


  // const fetchProductWithHRImages = () => {
  //   const product = location.state.product;
  //     API.get("almaAppApi", `/api/product/${product._id}`, {})
  //     .then((result) => {
  //     // setProductWithHRImages(result[0]);
  //     });
  // };

    const moveDot = index => {
        setSlideIndex(index);
    }

    const moveImage = index => {
      if(product.high_res_images[0].length-1 === slideIndex) {
        setSlideIndex(0);
      } else {
        setSlideIndex(index+1);
      }
    }

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    if (!product) {
      return  <div>

<Navbar></Navbar>
        <div style={{height:"1000px"}}></div>
      </div>
    }

  return (

    <div  >

    <Navbar></Navbar>
    <HeaderHome></HeaderHome>
    <Grid className="slide-up" container xl={11} lg={11} md={11} sm={11} xs={11} margin="auto" marginBottom={20}>

      <Grid item xl={3} lg={3} md={3} sm={3}></Grid>

      <Grid container item xl={6} lg={6} md={6} sm={6} xs={12} display="flex" justifyContent="center">
        <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
          <img
            className="product-main-image"
            src={product && product.high_res_images[0][slideIndex]}
            onClick={() => moveImage(slideIndex)}
            
          ></img>
        </Grid>

        <Grid item xl={0} lg={0} md={0} sm={0} xs={12}>
          <div className="spaceLarge"></div>
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={11}
          xs={11}
          display="flex"
          justifyContent="center"
        >
          {product && (product.high_res_images[0]).map((item, index) => {
            return (
              <div
                onClick={() => moveDot(index)}
                className={slideIndex === index ? "dot active" : "dot"}
              ></div>
            );
          })}
        </Grid>

        <Grid item xl={0} lg={0} md={0} sm={0} xs={12}>
          <div className="spaceLarge"></div>
        </Grid>
      </Grid>

      <Grid item xl={0.5} lg={0.5} md={0.5} sm={0.5}></Grid>

      <Grid item container xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={12} display="flex" justifyContent="center">
          <Product product={product}></Product>
      </Grid>

    </Grid>


    </div>
  );
}

