import React, { useEffect, useState }  from 'react'
import { useLocation, BrowserRouter as Router, Navigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import {API,Amplify} from 'aws-amplify';
import useLocalStorage from './useLocalStorage';
import CloseIcon from '@mui/icons-material/Close';
import  Grid from "@mui/material/Grid";
import CircleButton from '../../pages/CircleButton';

import './shopSingle.css';

export default function Product(props) {
    const location = useLocation();

    const {product} = props;


    console.log(product);

    Amplify.configure({
        Auth: {
            identityPoolId: 'us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d', //REQUIRED - Amazon Cognito Identity Pool ID
            region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        },
        Storage: {
            AWSS3: {
                bucket: 'almarituals143254-dev', //REQUIRED -  Amazon S3 bucket name      
                region: 'us-east-1', // REQUIRED - Amazon Cognito Region
            }
        }
    });

    const [productsWithSameType, setProductsWithSameType] = useState("");
    const [showNav, setShowNav] = useState(false);
    // const product = location.state.product;
    const [cartItems, setCartItems] = useLocalStorage("cartItems", []);
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        // const productType = location.state.productType;
        // API.get("almaAppApi", `/api/products/${productType}`, {
        // })
        // .then((result) => {
        //   // console.log("products with same type : " + result);
        //   setProductsWithSameType(result);

        // });
    }, [showNav]);


    useEffect(() => {
        console.log("yeni cartItems: ");
        console.log(cartItems);

    }, [cartItems]);

    useEffect(() => {
        console.log(product);

    }, []);

    const toggleSideBarAndAdd = () => {
      console.log("toggle side barrr");
      console.log(cartItems);

      const exist = cartItems.find((x) => x._id === product._id);
      if (exist) {
        // API.put("almaAppApi", `/api/product-stock-update-decrease/${product._id}`, {})
        // .then((result) => {
        //   setCartItems(
        //     cartItems.map((x) => ({...exist, qty: exist.qty, stock: exist.stock - 1}))
        //   );
        // });
      } else {
        // API.put("almaAppApi", `/api/product-stock-update-decrease/${product._id}`, {})
        // .then((result) => {
          setCartItems([...cartItems, {...product, qty: 1, stock: product.stock - 1, originalPrice: product.price }]);
        // });
      }

      setShowNav(!showNav);
      console.log(cartItems);

    };

    const onRemove = (item) => {
        const exist = cartItems.find(x => x._id === item._id);
        if (exist) {
            // API.put("almaAppApi", `/api/product-stock-update-increase/${item._id}`, {})
            // .then((result) => {
                setCartItems(cartItems.filter((x) => x._id!==item._id));
            // });
        }

        if(cartItems.length === 1){
            setShowNav(!showNav);
        }
    }

    const goToShoppingBagDirectly = () => {
        const exist = cartItems.find(x => x._id === product._id);
        if (exist) {
            // API.put("almaAppApi", `/api/product-stock-update-decrease/${product._id}`, {})
            // .then((result) => {
                setCartItems(
                cartItems.map((x) => (x._id === product._id ? {...exist, qty: exist.qty , stock: exist.stock - 1} : x))
                );
            // });
        } else {
            // API.put("almaAppApi", `/api/product-stock-update-decrease/${product._id}`, {})
            // .then((result) => {
                setCartItems([...cartItems, {...product, qty: 1, stock: product.stock - 1, originalPrice: product.price }]);
            // }); 
        }
        console.log("IM FROM goToShoppingBagDirectly " + cartItems);
        navigate('../shoppingBag');
        return <div>sdfgfsdgdf</div>
    }


    const goToShoppingBagFromSideBar = () => {

        console.log("IM FROM goToShoppingBagFromSideBar ");

        console.log(cartItems);

        const exist = cartItems.find(x => x._id === product._id);
        if (exist) {
            setCartItems(
            cartItems.map((x) => x._id === product._id ? {...exist, qty: exist.qty} : x)
        );
        } else {
            // API.put("almaAppApi", `/api/product-stock-update-decrease/${product._id}`, {})
            // .then((result) => {
                setCartItems([...cartItems, {...product, qty: 1, stock : product.stock - 1,originalPrice: product.price }]);
            // }); 
        }
        navigate('../shoppingBag');
        return <div>sdfgfsdgdf</div>
    }

    const SideBar = () => {
        return(
            <div className='shop-single-navbar active'>
                {/* <Grid item display="flex" justifyContent="flex-end" marginBottom={2}>
                    <CloseIcon  onClick={() => setShowNav(!showNav)}></CloseIcon>
                </Grid> */}
                {cartItems.map((item) => (
                    <div>
                        <Grid key={item._id} container justifyContent="center" >
                            <Grid item  xl={3.5} lg={3.5} md={3.5}>
                                <img className="product-basket-image" src={item.images[0][0]}></img>
                            </Grid>
                            <Grid item xl={2} lg={2} md={2}></Grid>
                            <Grid item xl={6.5} lg={6.5} md={6.5}>
                                <div className='side-bar-product-name'>{item.name}</div>
                                <div className='side-bar-product-type'>{item.product_type}</div>
                                <div className='spaceXsmall'></div>
                                <div className='side-bar-product-color-name'>Color: {item.color_name}</div>
                                <div className='one-size-fits-all'>One Size Fits All</div>
                                <div className='spaceXsmall'></div>
                                <button className='delete-button' onClick={() => onRemove(item)}>Delete</button>
                                <div className='spaceXsmall'></div>
                                <div className='side-bar-product-item-currency'>{item.price}{item.currency}</div>
                            </Grid>
                        </Grid>
                        <div className='spaceLarge'></div>
                        <div className='spaceLarge'></div>
                    </div>
                    ))}


                <button className='shop-single-checkout-button' onClick={ goToShoppingBagFromSideBar}>CHECKOUT</button>
            </div>
        );
    }

    return (
        <div style={{width:"100%"}} className="product-details-container">
            {!showNav && <div className="product-details">

                <div className='product-type'>{product.name}</div>
                <div className='spaceSmall'></div>
                <div className='product-name'> {product.product_type}</div>
                <div className='spaceSmall'></div>
                <div className="product-explanation">{product.explanation}</div>
                <div className='spaceSmall'></div>


                <Divider></Divider>

                <div className='spaceSmall'></div>
                <div className="circle-color-button">
                    <CircleButton color={product.color} width="15px" ></CircleButton>
                </div>

                <div className='spaceSmall'></div>

                <Divider></Divider>

                <div className='spaceSmall'></div>
                <div className='one-size-fits-all'>One Size Fits All</div>
                <div className='spaceSmall'></div>

                <Divider></Divider>

                <div className='spaceSmall'></div>
                <div className="product-price-currency">{product.price} {product.currency}</div>


                <div className='spaceSmall'></div>
                <button onClick={width>960 ? toggleSideBarAndAdd : goToShoppingBagDirectly} className="shop-single-add-to-cart-button">ADD TO CART</button>
                <div className='spaceLarge'></div>

                <div className='product-material-title'>MATERIAL</div>
                <div className='spaceSmall'></div>
                <div className='product-material'>{product.material}</div>
                <div className='spaceSmall'></div>

                <div className='product-care-instructions-title'>CARE INSTRUCTIONS</div>
                <div className='spaceSmall'></div>
                <div className='product-care-instructions'>{product.care_instructions}</div>
                        
            </div>}
            {showNav && SideBar()}
        </div>
    )
}
