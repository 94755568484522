import React, { useState, useEffect }  from 'react'
import Products from "../components/Products/Products";
import  './shop.css'
import Grid from '@mui/material/Grid';
import {API,Amplify} from 'aws-amplify';
import {useLocation } from 'react-router-dom'
import CircleButton from './CircleButton';

import { HeaderHome } from '../components/headerHome/HeaderHome';

import Navbar from '../components/Navbar';


export default function Shop() {

    const [showFilter, setShowFilter] = useState(false);
    const [showSort, setShowSort] = useState(false);

    const [colorsArray, setColorsArray] = useState([]);
    const [colorNamesArray, setColorNamesArray] = useState([]);
    const [materialArray, setMaterialArray] = useState([]);
    const [productTypeArray, setProductTypeArray] = useState([]);

    const [materialFilter, setMaterialfilter] = useState("");
    const [colorFilter, setColorFilter] = useState("");
    const [productTypeFilter, setProductTypeFilter] = useState("");
    const [colorHexFilter, setColorHexFilter] = useState("");


    const [nameAscSort, setNameAscSort] = useState(false);
    const [nameDesSort, setNameDesSort] = useState(false);
    const [priceAscSort, setPriceAscSort] = useState(false);
    const [priceDesSort, setPriceDesSort] = useState(false);

    const [productsArray, setProductsArray] = useState([]);


  const [isProductsFetched, setProductsFetched] = useState(false);

    const location = useLocation();
    const searchKey = location.state === null ? "" : location.state.name;

    Amplify.configure({
        Auth: {
            identityPoolId: 'us-east-1:8aef2c7f-ac1a-407f-ba73-793b61861e8d', //REQUIRED - Amazon Cognito Identity Pool ID
            region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        },
        Storage: {
            AWSS3: {
                bucket: 'almarituals143254-dev', //REQUIRED -  Amazon S3 bucket name      
                region: 'us-east-1', // REQUIRED - Amazon Cognito Region
            }
        }
    });

    useEffect(() => {

        API.get("almaAppApi", "/api/products-color-combination", {})
        .then((result) => {
            console.log(result);
            setColorsArray(result);
        })

        API.get("almaAppApi", "/api/products-color-name", {})
        .then((result) => {
            setColorNamesArray(result);
        })

        API.get("almaAppApi", "/api/products-material", {})
        .then((result) => {
            setMaterialArray(result);
        })

        API.get("almaAppApi", "/api/products-product-type", {})
        .then((result) => {
            setProductTypeArray(result);
        })
      
    }, [isProductsFetched]);

    useEffect(() => {
        console.log(nameAscSort);
        if (materialFilter) {
            const encodedMaterialFilter = encodeURIComponent(materialFilter);

            API.get("almaAppApi", `/api/images/${materialFilter ? encodedMaterialFilter : ':'}/:/:/:`, {})
            .then((result) => {
                console.log("material");
                console.log(result);

                setProductsArray(result);

            })
        } else if (colorFilter) {

            API.get("almaAppApi", `/api/images/:/${colorFilter ? colorFilter : ':'}/:/:`, {})
            .then((result) => {
                console.log("color");
                console.log(result);


                setProductsArray(result);
            })
        } else if (productTypeFilter) {
            console.log(productTypeFilter);


            API.get("almaAppApi", `/api/images/:/:/${productTypeFilter ? productTypeFilter : ':'}/:`, {})
            .then((result) => {

            console.log("producttype");
            console.log(result);
                setProductsArray(result);
            })
        } else if (colorHexFilter) {
            console.log(colorHexFilter);
            const encodedColorHexFilter = encodeURIComponent(colorHexFilter);

            API.get("almaAppApi", `/api/images/:/:/:/${encodedColorHexFilter ? encodedColorHexFilter : ':'}`, {})
            .then((result) => {

            console.log("producttype");
            console.log(result);
                setProductsArray(result);
            })
        }  else if (nameAscSort) {
            console.log("name ascending sort");

            API.get("almaAppApi", `/api/images/sortByNameAsc`, {})
            .then((result) => {
                setProductsArray(result);
            })
        } else if (nameDesSort) {

            API.get("almaAppApi", `/api/images/sortByNameDes`, {})
            .then((result) => {
                setProductsArray(result);
            })
        } else if (priceAscSort) {
            API.get("almaAppApi", `/api/images/sortByPriceAsc`, {})
            .then((result) => {
                setProductsArray(result);
            })
        } else if (priceDesSort) {
            API.get("almaAppApi", `/api/images/sortByPriceDes`, {})
            .then((result) => {
                setProductsArray(result);
            })
        }else if (searchKey) {

            console.log("search keyyyyy isss" + searchKey);
            API.get("almaAppApi", `/api/images/findByName/${searchKey.toUpperCase()}`, {})
            .then((result) => {
                console.log(result);
                setProductsArray(result);
            })
        } else {
            fetchProductsArray();
            // API.get("almaAppApi", "/api/images/:/:/:/:", {})
            // .then((result) => {
            //     setProductsArray(result);
            //     console.log(result);
            // })
        }
        
    }, [colorFilter,colorHexFilter,materialFilter,productTypeFilter,searchKey, nameAscSort, nameDesSort,priceAscSort,priceDesSort]);

    const fetchProductsArray = () => {
        // console.log("fetch products api called");

        // API.get("almaAppApi", "/api/images/:/:/:/:", {}).then((result) => {
        // setProductsArray(result);
        // console.log(result);
        // console.log("shop retrieved on the fetchProductsArray");
        // });

         // First API call to fetch the first 8 images
         API.get("almaAppApi", "/api/images-test/:/:/:/:", { 
            queryStringParameters: { 
                limit: 4
            } 
        }) 
         .then((result) => {
           setProductsArray(result);
           console.log('First 8 images: ', result);
           
           // After the first 8 images are fetched, fetch the remaining images
           fetchRemainingImages();
         })
         .catch(error => {
           console.error('Error fetching the first 8 images: ', error);
         });
    };
    
    const fetchRemainingImages = () => {
        API.get("almaAppApi", "/api/images/:/:/:/:", {}) 
        .then((result) => {
            setProductsArray(prevProducts => {
            const newUniqueProducts = result.filter(
                newProduct => !prevProducts.some(
                    prevProduct => prevProduct._id === newProduct._id
                )
            );
            return [...prevProducts, ...newUniqueProducts];
        });
            setProductsFetched(true);
            console.log('Remaining images: ', result);
        })
        .catch(error => {
            console.error('Error fetching the remaining images: ', error);
        });
    };

    const filterByMaterial = (material) => {
        setMaterialfilter(material);
        setColorFilter(null);
        setProductTypeFilter(null);
        setColorHexFilter(null);
        setShowFilter(false);

        setNameAscSort(false);
        setNameDesSort(false);
        setPriceAscSort(false);
        setPriceDesSort(false);
    }

    const filterByColor = (color) => {
        console.log(nameAscSort);
        setMaterialfilter(null);
        setColorFilter(color);
        setProductTypeFilter(null);
        setColorHexFilter(null);
        setShowFilter(false);

        setNameAscSort(false);
        setNameDesSort(false);
        setPriceAscSort(false);
        setPriceDesSort(false);
    }

    const filterByColorHex = (colorName) => {
        setMaterialfilter(null);
        setColorFilter(null);
        setProductTypeFilter(null);
        setColorHexFilter(colorName);
        setShowFilter(false);

        setNameAscSort(false);
        setNameDesSort(false);
        setPriceAscSort(false);
        setPriceDesSort(false);
    }

    const filterByProductType = (productType) => {
        setProductTypeFilter(productType);
        setMaterialfilter(null);
        setColorFilter(null);
        setColorHexFilter(null);

        setShowFilter(false);

        setNameAscSort(false);
        setNameDesSort(false);
        setPriceAscSort(false);
        setPriceDesSort(false);
    }

    const sortByNameAsc = () => {
        // console.log("name ascending sort");
        console.log(nameAscSort);

        setNameAscSort(true);
        setNameDesSort(false);
        setPriceAscSort(false);
        setPriceDesSort(false);
        setShowSort(false);


        setMaterialfilter(null);
        setColorFilter(null);
        setProductTypeFilter(null);
        setColorHexFilter(null);
    }

    const sortByNameDes = () => {
        setNameAscSort(false);
        setNameDesSort(true);
        setPriceAscSort(false);
        setPriceDesSort(false);
        setShowSort(false);

        setMaterialfilter(null);
        setColorFilter(null);
        setProductTypeFilter(null);
        setColorHexFilter(null);
    }

    const sortByPriceAsc = () => {
        setNameAscSort(false);
        setNameDesSort(false);
        setPriceAscSort(true);
        setPriceDesSort(false);
        setShowSort(false);

        setMaterialfilter(null);
        setColorFilter(null);
        setProductTypeFilter(null);
        setColorHexFilter(null);
    }

    const sortByPriceDes = () => {
        setNameAscSort(false);
        setNameDesSort(false);
        setPriceAscSort(false);
        setPriceDesSort(true);
        setShowSort(false);

        setMaterialfilter(null);
        setColorFilter(null);
        setProductTypeFilter(null);
        setColorHexFilter(null);
    }

    const Sort = () => {
        return(
        <div className='shop-sort-container'>
            <Grid container xl={12} lg={11} md={11.2} sm={11} xs={11} margin="auto"  >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} > 
                    <div className='shop-filter-small-title'>Sort By Category</div>
                
                </Grid>
                <Grid item container direction="column" xl={12} lg={12} md={12} sm={12} xs={12} > 
                    <Grid item >
                        <button className='sort-filter-button' onClick={() => sortByNameAsc()}>Alphabetically, A to Z</button>
                    </Grid>
                    <Grid item >
                        <button className='sort-filter-button' onClick={() => sortByNameDes()}>Alphabetically, Z to A</button>
                    </Grid>
                    <Grid item>
                        <button className='sort-filter-button' onClick={() => sortByPriceAsc()}>Price, low to high</button>
                    </Grid>
                    <Grid item >
                        <button className='sort-filter-button' onClick={() => sortByPriceDes()}>Price, high to low</button>
                    </Grid>
                
                </Grid>

            </Grid>
        </div>
        );
    }

    const Filter = () => {
        return(
        <div className='shop-filter-container'>
                <Grid container xl={11} lg={11} md={11.3} sm={11.12}  margin="auto">


                    <Grid item xl={4}  lg={4} md={4} sm={4}>
                        <div className='shop-filter-small-title'>Material</div>
                    </Grid>

                    <Grid item xl={4}  lg={3.9} md={4} sm={4}>
                        <div className='shop-filter-small-title'>Color</div>
                    </Grid>

                    <Grid item xl={4}  lg={4} md={4} sm={4}>
                        <div className='shop-filter-small-title'>Type of Product</div>
                    </Grid>

                    <Grid item xl={4}  lg={4} md={4} sm={4}  > 
                        {materialArray.map((item) => {
                            return(
                                <button className='material-filter-names' onClick={() => filterByMaterial(item)}>{item}</button>
                            );

                        })}
                    </Grid>

                    
                    <Grid item xl={4}  lg={4} md={4} sm={4} >
                        {colorsArray.map((item) => {
                            return(
                                <div>
                                <CircleButton color={item.color} height="13px" width="13px" onClick={() =>filterByColorHex(item.color)}>a</CircleButton>
                                <button className='color-filter-names' onClick={() =>filterByColor(item.color_name)} >{item.color_name}</button>
                                <br/>

                                </div>
                            );
                        })}
                    </Grid>

                    <Grid item xl={4} lg={4} md={4} sm={4} > 
                        {productTypeArray.map((item)=>{
                            return (
                                <div className='color-filter-names' onClick={() =>filterByProductType(item)}>{item}</div>
                            );
                        })}
                    
                    </Grid>

                </Grid>
            </div>
        );
    }

    const MobileFilter = () => {
        return(
        <div className='mobile-shop-filter-container'>
                <Grid container sm={11} xs={11}  margin="auto">

                    <Grid item  sm={12} xs={12} >
                        <div className='shop-filter-small-title'>Material</div>
                    </Grid>

                    <Grid item  sm={12} xs={12}  > 
                        {materialArray.map((item) => {
                            return(
                                <button className='material-filter-names' onClick={() => filterByMaterial(item)}>{item}</button>
                            );
                        })}
                    </Grid>

                    <Grid item  sm={12} xs={12} >
                        <div className='shop-filter-small-title'>Color</div>
                    </Grid>
                    <Grid item  sm={11.4} xs={11.9} >
                        {colorsArray.map((item) => {
                            return(
                                <div>
                                    <CircleButton color ={item.color} height="12px" width="12px" onClick={() =>filterByColorHex(item.color)}>a</CircleButton>
                                    <button className='color-filter-names' onClick={() =>filterByColor(item.color_name)} >{item.color_name}</button>
                                    <br/>
                                </div>
                            );
                        })}
                    </Grid>

                    <Grid item xl={3} lg={3} md={5} sm={12} xs={12} > 
                        <div className='shop-filter-small-title'>Type of Product</div>
                        {productTypeArray.map((item)=>{
                            return (
                                <div className='color-filter-names' onClick={() =>filterByProductType(item)}>{item}</div>
                            );
                        })}
                    
                    </Grid>

                </Grid>
            </div>
        );
    }

    if (!productsArray || productsArray.length === 0) {
        return  <div>
            <Navbar ></Navbar>
    
          <div style={{height:"1000px"}}></div>
        </div>
      }

    return (
        <div>

            <Navbar ></Navbar>
            <HeaderHome></HeaderHome>
            <Grid container xl={11.27} lg={11.27} md={11.27} sm={11.25} xs={10.9} display="flex" justifyContent="center" margin="auto" >

                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <button className="shop-title-filter" onClick={() => {
                            setShowFilter(!showFilter);
                            setShowSort(false);
                            }}>FILTER
                        </button>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} display="flex" justifyContent="flex-end">
                        <button className="shop-title-sort" onClick={() => {
                            setShowSort(!showSort);
                            setShowFilter(false);
                            }}>SORT BY
                        </button>
                    </Grid>
                </Grid>
                
            </Grid>

            {showFilter && <div><MobileFilter /> <Filter /></div> }

            {showSort && <Sort></Sort>}
            {/* below comment out: fetchProducts array methoduna ihtiyaç yok gibi duruyor, çünkü her seferinde productsArray useEffectte dolu geliyor ve refreshleniyor*/} 
            <Products productsArray={productsArray ? productsArray : fetchProductsArray()}></Products> 
            {/* <Products productsArray={productsArray}></Products> */}

   
        </div>
        
    )
}