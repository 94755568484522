import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Label } from "@mui/icons-material";
import { ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import "./forgotPassword.css";
import Navbar from "../components/Navbar";
import { HeaderHome } from "../components/headerHome/HeaderHome";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const generateNewPasswordAndSendEmail = (values) => {
    console.log("clicked");
    const userEmailInfo = {
      body: {
        email: values.email,
      },
    };
    API.put(
      "almaAppApi",
      "/api/send-generated-reset-link",
      userEmailInfo
    )
      .then((result) => {
        console.log(result);
        navigate("../");

      })
      .catch((err) => {
        //user does not exist
        //     console.log(err);
        setErrorMessage("MAIL DOES NOT EXIST, PLEASE REGISTER");
      });
  };

  const checkErrors = (emailError) => {
    if (emailError) {
      setErrorMessage(emailError);
      return;
    } else {
      setErrorMessage("");
    }
  };
  return (
    <div>

    <Navbar></Navbar>
    <HeaderHome></HeaderHome>
    <div className="forgot-password-form">
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("INVALID EMAIL ADDRESS")
            .required("EMAIL IS REQUIRED"),
        })}
        onSubmit={generateNewPasswordAndSendEmail}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <div className="forgot-password-form-title">
              PLEASE ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD
            </div>
            <div className="forgot-password-form-small-space"></div>
            <Field
              className="forgot-password-form-input"
              name="email"
              type="email"
              placeholder="EMAIL ADDRESS"
            />

            <div>{errorMessage}</div>
            <ErrorMessage name="email">
              {(msg) => <div>{msg}</div>}
            </ErrorMessage>

            <button
              className="forgot-password-form-button"
              type="submit"
              disabled={!values.email || errors.email}
              onClick={() => {
                checkErrors(errors.email);
              }}
            >
              SEND
            </button>
          </Form>
        )}
      </Formik>
    </div>


    </div>
  );
}
