import { useState } from "react";

import "./Account.css";

import Grid from "@mui/material/Grid";

import useLocalStorage from "../components/Products/useLocalStorage";

import { API } from "aws-amplify";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import jwt from "jwt-decode";
import Navbar from "../components/Navbar";
import { HeaderHome } from "../components/headerHome/HeaderHome";

export default function SignUp() {
  const navigate = useNavigate();

  const [token, setToken] = useLocalStorage("token", []);

  const [errorMessage, setErrorMessage] = useState("");

  const signUp = (
    firstNameError,
    lastNameError,
    phoneNumberError,
    countryError,
    cityError,
    postalCodeError,
    addressError,
    emailError,
    passwordError
  ) => {
    
    console.log("sign Uppppp");
    if (firstNameError) {
      setErrorMessage(firstNameError);
      return;
    } else if (lastNameError) {
      setErrorMessage(lastNameError);
      return;
    } else if (phoneNumberError) {
        setErrorMessage(phoneNumberError);
        return;
    } else if (countryError) {
        setErrorMessage(countryError);
        return;
    } else if (cityError) {
        setErrorMessage(cityError);
        return;
    } else if (postalCodeError) {
        setErrorMessage(postalCodeError);
        return;
    } else if (addressError) {
        setErrorMessage(addressError);
        return;
    } else if (emailError) {
        setErrorMessage(emailError);
        return;
    } else if (passwordError) {
        setErrorMessage(passwordError);
        return;
    } else{
      setErrorMessage("");
    }
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("FIRST NAME IS REQUIRED"),
    lastName: Yup.string().required("LAST NAME IS REQUIRED"),
    phoneNumber: Yup.string().required("PHONE NUMBER IS REQUIRED").matches(phoneRegExp, 'PHONE NUMBER IS NOT VALID'),
    country: Yup.string().required("COUNTRY IS REQUİRED"),
    city: Yup.string().required("CITY IS REQUIRED"),
    postalCode: Yup.string().required("POSTAL CODE IS REQUIRED")
    .min(5, 'POSTAL CODE SHOULD BE 5 DIGITS')
    .max(5, 'POSTAL CODE SHOULD BE 5 DIGITS'),
    address: Yup.string().required("ADDRESS IS REQUIRED"),
    email: Yup.string().email("INVALID EMAIL").required("EMAIL IS REQUIRED"),
    password: Yup.string().required("PASSWORD IS REQUIRED"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const registerInfo = {
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          country: values.country,
          city: values.city,
          postalCode: values.postalCode,
          apartmentSuite:values.apartmentSuite,
          address:values.address,
          email: values.email,
          password: values.password,
          isRegistered: true}, 
      };

      
      API.post("almaAppApi", "/api/register", registerInfo)
          .then(response => {
            console.log(response);
        setToken(response.token);
            console.log(response.token);

            const emailInfo = {
              body: {
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName
            }
          }
            
            API.post("almaAppApi","/api/send-account-created-mail", emailInfo );
            navigate("../");
            
            setErrorMessage(null);
          })
          .catch(function (error) {
            setErrorMessage(error.response.data.message);
          })
      
    setSubmitting(false);
  };

  return (
    <div>
      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
      <div className="form-container">
        <Formik
          initialValues={{ 
            firstName: "", 
            lastName: "" , 
            phoneNumber:"",
            country: "",
            city: "",
            postalCode: "",
            apartmentSuite: "",
            address: "",
            email: "",
            password: ""
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="sign-up-form">
              <button
                className="login-dont-you-have-an-account"
                onClick={() => {
                  navigate("../signIn");
                }}
              >
                ALREADY HAVE AN ACCOUNT? <u className="underline-styling">SIGN IN HERE</u>
              </button>

              <Field name="firstName" type="name" placeholder="FIRST NAME" />

              <Field name="lastName" type="name" placeholder="LAST NAME" />

              <Field name="phoneNumber" type="tel" placeholder="PHONE NUMBER" />

              <Field name="country" type="text" placeholder="COUNTRY" />
              <Field name="city" type="text" placeholder="CITY" />
              <Field name="postalCode" type="number" placeholder="POSTAL CODE" />
              <Field name="address" type="text" placeholder="ADDRESS" />
              <Field name="apartmentSuite" type="text" placeholder="APARTMENT / SUITE ETC." />
              <Field name="email" type="email" placeholder="EMAIL ADDRESS" />
              <Field name="password" type="password" placeholder="PASSWORD" />

              <div className="account-error">{errorMessage}</div>

              <div>
                <Grid
                  container
                  xl={12}
                  marginTop={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xl={4.5} xs={4.5}>
                    <button
                      className="account-black-button"
                      onClick={(e) => {
                        signUp(
                            errors.firstName,
                            errors.lastName,
                            errors.phoneNumber,
                            errors.country,
                            errors.city,
                            errors.postalCode,
                            errors.address,
                            errors.email,
                            errors.password);
                      }}
                    >
                      SIGN UP
                    </button>
                  </Grid>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
