import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Label } from "@mui/icons-material";
import { ErrorMessage } from "formik";
import "./forgotPassword.css";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import Navbar from "../components/Navbar";
import { HeaderHome } from "../components/headerHome/HeaderHome";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
   const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const compareTokensAndUpdateUsersPassword = (values) => {
    console.log("clicked");
    console.log("token is:" +token);
    console.log("newPassword is:" +values.newPassword);

    const resetPassInfo = {
      body: {
        resetPasswordToken: token,
        newPassword:values.newPassword
      },
    };
    API.put(
      "almaAppApi",
      "/api/compare-tokens-and-update-password",
      resetPassInfo
    )
      .then((result) => {
        console.log(result);
        navigate("../");
      })
      .catch((err) => {
        //user does not exist
        console.log(err);
        setErrorMessage("TOKEN EXPIRED OR INVALID");
      });
  };

  const checkErrors = (newPasswordError) => {
    if (newPasswordError) {
      setErrorMessage(newPasswordError);
      return;
    } else {
      setErrorMessage("");
    }
  };
  return (
    <div>

      <Navbar></Navbar>
      <HeaderHome></HeaderHome>
    <div className="forgot-password-form">
      <Formik
        initialValues={{ newPassword: "" }}
        validationSchema={Yup.object({
           newPassword: Yup.string()
            .required("PASSWORD IS REQUIRED"),
        })}
        onSubmit={compareTokensAndUpdateUsersPassword}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <div className="forgot-password-form-title">
              PLEASE ENTER YOUR NEW PASSWORD
            </div>
            <div className="forgot-password-form-small-space"></div>
            <Field
              className="forgot-password-form-input"
              name="newPassword"
              type="password"
              placeholder="PASSWORD"
            />

            <div>{errorMessage}</div>
            <ErrorMessage name="newPassword">
              {(msg) => <div>{msg}</div>}
            </ErrorMessage>

            <button
              className="forgot-password-form-button"
              type="submit"
              disabled={!values.newPassword || errors.newPassword}
              onClick={() => {
                checkErrors(errors.newPassword);
              }}
            >
              UPDATE PASSWORD
            </button>
          </Form>
        )}
      </Formik>
    </div>
    </div>
  );
}
