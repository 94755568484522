import React ,{useState, useEffect} from 'react'


import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {API} from 'aws-amplify';
import useLocalStorage from '../Products/useLocalStorage';
import { Popper } from '@mui/material';

export default function ShoppingBagSum(props) {
  const {navigateTo, cartItems, setCartItems} = props;

  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingPriceTRY, setShippingPriceTRY] = useLocalStorage("shippingPriceTry", []);
  const [shippingPriceEUR, setShippingPriceEUR] = useState("");
  const [discountCode, setDiscountCode] = useLocalStorage("discountCode", []);
  const [discountCodeInput, setDiscountCodeInput] = useState("");


  const applyDiscountCode = async() =>{

    console.log("lelelellel");

    if (discountCodeInput !== '') {

      try {
        const result = await API.get(
          "almaAppApi",
          `/api/financial-values`,
          {}
        );
        console.log(discountCode);
        if (discountCodeInput === result.discount_code && discountCode !== null) {
            console.log("no dc yet");

        //   setDiscountCode(discountCodeInput);
          localStorage.setItem("discountCode", discountCodeInput);
          setCartItems(cartItems.map((x) => ({
            ...x,
            price: x.price * 0.9,
            originalPrice : x.originalPrice * 0.9
          })));

          console.log("cartItems which is applied DC" + cartItems);

          let sum =  cartItems.reduce(function(prev, current) {
            return(prev + +current.price);
          }, 0);

          
          setSubTotal(sum);

          
          setTotal(sum + shippingPriceTRY);


        //   if(discountCode.length !== 0) {
        //     console.log("yess");
        //     setDiscountCodeInput(discountCode);
        // }

        //   setDiscountCodeApplied(true);
          
        }

        


      } catch (error) {
        console.log(error);
      }
      console.log("y");
      

    }
  } 
 

  useEffect(() => {
    let sum =  cartItems.reduce(function(prev, current) {
        return(prev + +current.price);
      }, 0);
  
      setSubTotal(sum);
  
      if (sum >= 3000) {
        setTotal(sum);
      } else {
        setTotal((sum) + shippingPriceTRY);
  
      }


    //   console.log(discountCodeApplied);
  }, [cartItems]);

  return (
    <div className='shopping-bag-sum-container'>
        <Grid container xl={12} xs={12}>
            <Grid item xl={8} xs={8}>
                <div>SUBTOTAL</div>
                <div>SHIPPING</div>
                <div>TOTAL</div>
            </Grid>
            <Grid item xl={4} xs={4} textAlign="right">
                <div>{subTotal} TRY</div>
                <div> {subTotal >= 3000 ? "FREE" : shippingPriceTRY + " TRY" } </div>
                <div>{total} TRY</div>
            </Grid>
            <Grid item xl={12} xs={12}>
                <Divider></Divider>
            </Grid>
            <Grid item xl={12} xs={12}>
                <div className='shopping-bag-sum-small-space'></div>
            </Grid>
            <Grid item xl={8} xs={8}>
                <input className='shopping-bag-sum-grey-button' placeholder={localStorage.getItem("discountCode") === null ? "DISCOUNT CODE": localStorage.getItem("discountCode")} value={discountCodeInput} disabled={localStorage.getItem("discountCode") !== null || localStorage.getItem("discountCodeIsUsedBefore") !== null}  onChange={(event) => {
                        const value = event.target.value;
                        setDiscountCodeInput(value);
                      }}></input>
            </Grid>
            <Grid item xl={0.3} xs={0.3}>
            </Grid>
            <Grid item xl={3.7} xs={3.7}>
                <button className='shopping-bag-sum-black-button' onClick={applyDiscountCode} disabled={localStorage.getItem("discountCode")!== null || localStorage.getItem("discountCodeIsUsedBefore") !== null}>{localStorage.getItem("discountCode") === null ? "APPLY" :  "APPLIED"}</button>
            </Grid>

            <Grid item xl={12} xs={12}>
                <div className='shopping-bag-sum-small-space'></div>
            </Grid>
        </Grid>

        
        

        
        
    </div>
  )
}
