import React, {useState, useEffect} from "react";
import { API, button } from "aws-amplify";

import Spinner from "../pages/checkout/Spinner";

import "./send-order-info-mail.css";

const SendOrderInfoMail = () => {


  const [orderId, setOrderId] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [buttonText, setButtonText] = useState('SEND ORDER IS ON ITS WAY MAIL');


  useEffect(() => {}, [isInProgress, buttonText]);


  const handleInputChange = (event) => {
    setOrderId(event.target.value);
  };

  const sendOnTheWayMail = async (event) => {
    console.log("Order ID: ", orderId);
    setIsInProgress(true);

    const data = {
      body: {
        orderId: orderId,
      },
    };

    API.post("almaAppApi", `/api/threeds/payments/send-order-is-on-its-way-mail`, data).then(
      (result) => {
        console.log("email sent");
        setIsInProgress(false);
        setButtonText("SENT !");

        setTimeout(function(){
               window.location.reload();
            }, 3000);
      }
    );
  };


  return (
    <div className="send-order-info-mail">
      <input placeholder="ORDER ID" 
        value={orderId}
        onChange={handleInputChange}></input>
      {/* <button onClick={sendOnTheWayMail}>SEND ORDER IS ON ITS WAY MAIL</button> */}
      <button onClick={sendOnTheWayMail}>
                {isInProgress ? <Spinner></Spinner> : buttonText}</button>

    </div>
  );
};

export default SendOrderInfoMail;
