import Grid from "@mui/material/Grid";
import React, { useEffect, useState }  from 'react'

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {API,Amplify} from 'aws-amplify';






import "./notebook.css"

import xl_homepage from "./fullPage/1920px-notebook.png"
import l_homepage from "./fullPage/1200px-notebook.png"
import m_homepage from "./fullPage/992px-notebook.png"
import sm_homepage from "./fullPage/768px-notebook.png"
import xs_homepage from "./fullPage/600px-notebook.png"

import { HeaderHome } from "../../components/headerHome/HeaderHome";

import Navbar from "../../components/Navbar";

 export default function Notebook() {

  const [igImageArray, setIgImageArray] = useState([]);

  useEffect(() => {
    let arr = [];
    console.log("ig fet");
    API.get("almaAppApi", "/api/get-ig-images", {})
    .then((result) => {
      console.log("instagram image linkleri");
      console.log(result.data.data);
      result.data.data.forEach((item) => {
        console.log(item.media_url);
        if (item.media_type === 'IMAGE' && arr.length !== 8) {
          arr.push(item.media_url);
        }
      });

     setIgImageArray(arr);
});
   }, []);

  return (
    <div >

<Navbar ></Navbar>
            <HeaderHome></HeaderHome>
        <div>
          <img className='xl-notebook' src={xl_homepage}></img>
          <img className='l-notebook' src={l_homepage}></img>
          <img className='m-notebook' src={m_homepage}></img>
          <img className='s-notebook' src={sm_homepage} ></img>
          <img className='xs-notebook' src={xs_homepage} ></img>
        </div>


      <div >
        <Grid container 
        xl = {11.35}
        lg={11.4}
        md={11.4}
        sm={11.4}
        xs={11.4}
            rowSpacing={1}
            columnSpacing={1}
            margin="auto"
            >
          {igImageArray.map((item) => (
                <Grid key={item._id} item xs={3} sm={3} md={3} lg={3}  xl={3} >
                  
                  <div style={{ width: '100%', paddingBottom: '100%',  backgroundImage: `url(${item})`, backgroundSize:'cover'}}
                  >
              </div>
                </Grid>
            ))}
        </Grid>
      </div>

    </div>
  );
}

