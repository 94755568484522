import React, {useEffect, useState, useContext, createContext} from 'react';
import './Footer.css';

import alma_menu_icon from './alma_logo.png'
import { Link as LinkR } from 'react-router-dom';
import  Grid from "@mui/material/Grid";
import { Link as Link } from 'react-router-dom';
import logo_band from './logo_band_colored@2x.png';


import {
  Nav,
  NavbarContainer,
  NavMenu,
  NavItem,
  NavLinks,
  NavLogo
} from "./FooterElements";

const Footer = () => {
  return (
    <div>
        <div className='footer-wrapper'> 

            <Grid container xl={3.7} lg={3.7} md={5} xs={12} className="contact-us-newsletter-instagram-link-footer">
                <Grid item xl={4} lg={4} md={4} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link className = "footer-link" to='/contactUs'>CONTACT US</Link>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link className = "footer-link" to='/newsletter'>NEWSLETTER</Link>
                </Grid>
                <Grid item xl={4} lg={4} md={4} xs={12}display="flex" justifyContent="center" alignItems="center">
                    <a  className = "footer-link" target="_blank" href="https://www.instagram.com/almarituals_/">INSTAGRAM</a>
                </Grid>
            </Grid>   

            <Grid container xl={6.2} lg={6.2} md={8} className="contact-us-newsletter-instagram-link-footer">
                <Grid item xl={3} lg={3} md={3} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link className = "footer-link" to='/termsOfService'>TERMS OF SERVICE</Link>
                </Grid>
                <Grid item xl={3} lg={3} md={3} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link className = "footer-link">REFUND POLICY</Link>
                </Grid>
                <Grid item xl={3} lg={3} md={3} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link className = "footer-link" to='/shippingPolicy'>SHIPPING POLICY</Link>
                </Grid>
                <Grid item xl={3} lg={3} md={3} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Link className = "footer-link" to='/privacyPolicy'>PRIVACY POLICY</Link>
                </Grid>
            </Grid>   

        </div>

        <div className='small-size-footer'>
            <div className='footer-wrapper-small'>
                <Link className = "footer-link" to='/contactUs'>CONTACT US</Link>
            </div>
            <div className='footer-wrapper-small'>
                <Link className = "footer-link" to='/newsletter'>NEWSLETTER</Link>
            </div>
            <div className='footer-wrapper-small'>                    
                <a className = "footer-link" target="_blank" href="https://www.instagram.com/almarituals_/">INSTAGRAM</a>
            </div>
            <div className='footer-wrapper-small'>                    
                <Link className = "footer-link" to='/termsOfService'>TERMS OF SERVICE</Link>
            </div>
            <div className='footer-wrapper-small'>
                <Link className = "footer-link" to='/refundPolicy'>REFUND POLICY</Link>
            </div>
            <div className='footer-wrapper-small'>
                <Link className = "footer-link" to='/shippingPolicy'>SHIPPING POLICY</Link>
            </div>
            <div className='footer-wrapper-small'>
                <Link className = "footer-link" to='/privacyPolicy'>PRIVACY POLICY</Link>
            </div>

        </div>


        <Grid container xl={5.5} lg={5.5} xs={7}  margin="auto">
            <LinkR  to= '/' className='footer-logo-wrapper'>
                <img className="footer-logo" src={require('./alma_logo.png')}></img>
            </LinkR>
        </Grid>

    </div>

        
  )
}

export default Footer
