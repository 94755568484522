import React, {useEffect, useState} from 'react';

import { Link as LinkR } from 'react-router-dom';
import alma_menu_icon from './alma_menu_icon.png'
import alma_search_icon from './alma_search_icon.png'
import alma_shoppingBag_icon from './alma_shoppingBag_icon.png'
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import './navbarGrid.css'
import useLocalStorage from '../Products/useLocalStorage';
import jwt from "jwt-decode";
import { useNavigate, useLocation } from 'react-router-dom';

export default function Navbar (props)  {
    const [width, setWidth] = useState(window.innerWidth);
    const [showSmallNavbar,setShowSmallNavbar] = useState(false);
    const [userName, setUserName] = useState("ACCOUNT");
    const [token, setToken] = useLocalStorage("token", []);

    const {showSearchFromHome, setShowSearchFromHome} = props;
    const [showSearchFromAny, setShowSearchFromAny] = useState(false);

    const location = useLocation();


    const navigate = useNavigate();

    useEffect(() => {
        if (token !== null && token.length!==0) {
            const user = jwt(token);
            setUserName(user.name.toUpperCase());
        }
        
    }, [token]);

    useEffect(() => {
        if (showSearchFromAny) {
            navigate("/");
        }
    }, [showSearchFromAny]);


    //if page location is other than home page: we should go to home page with true storage show search value
    const goHomeAndSearch = () => {
        if (location.pathname !== '/') {
            setShowSearchFromAny(true);
            sessionStorage.setItem("showSearch", "true");
        } else {
            setShowSearchFromHome(!showSearchFromHome);
        }
    } 

    const BigSizeNavbar = () => {
        

        return (
            <div className='big-size-navbar-wrapper'>
            <Grid className='big_size_navbar_container' xl={12} lg={12} md={12} sm={12} container display="flex" justifyContent="center" alignItems="center"  >
                <Grid item xl={.7} lg={.7} md={.7} sm={0.7}   className='navElements'>
                    <LinkR className="navLink" to='/shop'>SHOP</LinkR>
                </Grid>
                <Grid item xl={1.16} lg={1.16} md={1.16} sm={1.32}  className='navElements collections'>               
                    <LinkR className="navLink" to='/collections'>COLLECTIONS</LinkR>
                </Grid>
                <Grid item xl={1.1} lg={1.1} md={1.1} sm={1.1} className='navElements'>
                    <LinkR  className="navLink" to='/notebook'>NOTEBOOK</LinkR>
                </Grid>
                <Grid item xl={5.2} lg={5.2} md={5.2} sm={4.8} display="flex" justifyContent="center" alignItems="center"   > 
                    <LinkR  to= '/' className="logo-container">
                        <img className="logo" src={require('./alma_logo.png')}></img>
                    </LinkR>
                </Grid>

                
                <Grid item xl={.85} lg={.85} md={.85} sm={.95} display="flex" justifyContent="flex-end" className='navElements ' >
                    <LinkR className="navLink right"  onClick={() => goHomeAndSearch()}>SEARCH</LinkR>
                    {/* <button className="search-link" onClick={() => setShowSearch(!showSearch)}>SEARCH</button> */}
                </Grid>
                <Grid item xl={.98} lg={.98} md={.98} sm={.98} display="flex" justifyContent="flex-end" className='navElements '>               
                    <LinkR className="navLink right" to='/account'>{userName}</LinkR>
                </Grid>
                <Grid item xl={1.26} lg={1.26} md={1.26} sm={1.36} display="flex" justifyContent="flex-end" className='navElements shopping-bag '>
                    <LinkR  className="navLink right" to='/shoppingBag'>SHOPPING BAG</LinkR>
                </Grid>
            </Grid>
            </div>
        );
    }
    const SmallNavbar = () => {
        return (
            <div className='small-size-navbar-grid-wrapper'>
            <Grid className="small-size-mavbar-grid" xs={12} container  justifyContent="center" alignItems="center" >
                <Grid xs={1}  item  onClick={() => setShowSmallNavbar(!showSmallNavbar)}>
                    <img src = {alma_menu_icon}></img>
                </Grid>

                <Grid item xs={1.5} > 
                </Grid>

                <Grid item xs={6}> 
                    <LinkR to= '/' >
                        <img  className="logo-small" src={require('./alma_logo.png')}></img>
                    </LinkR>
                </Grid>

                <Grid item xs={0.8}> 
                </Grid>

                <Grid xs={1.1} item >
                    <LinkR onClick={() => goHomeAndSearch()}>
                        <img src={alma_search_icon}></img>
                    </LinkR>
                </Grid>

                <Grid xs={1.4} item >
                    <LinkR to='/shoppingBag'>
                        <img  src={alma_shoppingBag_icon}></img>
                    </LinkR>
                </Grid>
            </Grid>
            </div>
        );
    }
    const BurgerMenu = () => {
        return(
            <Grid container className='burger-menu' xs={12} display="flex" justifyContent="center">
                <Grid item xs={11} className="burger-menu-item-wrapper" onClick={() => setShowSmallNavbar(!showSmallNavbar)} display="flex" justifyContent="center">
                    <LinkR className='burger-menu-item' to='/shop'>SHOP</LinkR>
                </Grid>

                <Grid item xs={11} onClick={() => setShowSmallNavbar(!showSmallNavbar)}>
                    <Divider></Divider>
                </Grid>

                <Grid item xs={11} className="burger-menu-item-wrapper" onClick={() => setShowSmallNavbar(!showSmallNavbar)} display="flex" justifyContent="center">
                <LinkR className='burger-menu-item' to='/collections'>COLLECTIONS</LinkR>
                </Grid>

                <Grid item xs={11}>
                    <Divider></Divider>
                </Grid>

                <Grid item  xs={11} className="burger-menu-item-wrapper" onClick={() => setShowSmallNavbar(!showSmallNavbar)} display="flex" justifyContent="center">
                <LinkR className='burger-menu-item' to='/notebook'>NOTEBOOK</LinkR>
                </Grid>

                <Grid item xs={11}>
                    <Divider></Divider>
                </Grid>

                <Grid item xs={11} className="burger-menu-item-wrapper" onClick={() => setShowSmallNavbar(!showSmallNavbar)} display="flex" justifyContent="center">
                <LinkR className='burger-menu-item' to='/account'>ACCOUNT</LinkR>
                </Grid>

                
            </Grid>
        );
    }
  
    return (
        <div>
                {width>600 ? <BigSizeNavbar></BigSizeNavbar> : <SmallNavbar></SmallNavbar>}
                {showSmallNavbar && BurgerMenu() }

        </div>
    );
}

