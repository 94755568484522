import React from 'react';

const CircleButton = ({ color = "white", width }) => {
   return (
      <svg
          width={width}
          viewBox={`0 0 8 8`}

      >
          <circle cx={4} cy={4} r={3.5} fill={color} stroke="black" strokeWidth="0.2"/>
      </svg>
    );
};

export default CircleButton;
